<script>
/**
 * Dashboard component
 */
export default {
  data() {
    return {

      transactionData: [
     
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "orderid", sortable: true, label: "Order ID" },
        // { key: "date", sortable: true },
        { key: "billingname", sortable: true, label: "Billing Name" },
        { key: "amounta", sortable: true, label: "Total Amount" },
        { key: "paymentstatus", sortable: true, label: "Payment Status" },
        { key: "action" },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.transactionData.length;
    },
  },
  mounted() {
    this.getTransactionData();
    // Set the initial number of items
    this.totalRows = this.transactionData.length;
  },
  methods: {
       getTransactionData() {
      // let loader = this.$loading.show({
      //   loader: "dots",
      // });
      this.$store.getters.razorPay.get("/payments/").then((response) => {
        this.trainingData = response.data.results;
        // loader.hide();
      });
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body pt-0">
          <b-tabs nav-class="nav-tabs-custom">
            <b-tab title-link-class="p-3">
              <template v-slot:title>
                <a class="font-weight-bold active">Payments</a>
              </template>
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                  >
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                  class="table-centered"
                  :items="ordersData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                >
                  <template v-slot:cell(paymentstatus)="row">
                    <div
                      class="badge font-size-12"
                      :class="{
                        'badge-soft-danger': `${row.value}` === 'Chargeback',
                        'badge-soft-success': `${row.value}` === 'Paid',
                        'badge-soft-warning': `${row.value}` === 'Unpaid',
                      }"
                    >
                      {{ row.value }}
                    </div>
                  </template>
                  <template v-slot:cell(invoice)>
                    <button class="btn btn-light btn-rounded">
                      Invoice
                      <i class="mdi mdi-download ml-2"></i>
                    </button>
                  </template>
                  <template v-slot:cell(action)>
                    <a
                      href="javascript:void(0);"
                      class="mr-3 text-primary"
                      v-b-tooltip.hover
                      title="Edit"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a
                      href="javascript:void(0);"
                      class="text-danger"
                      v-b-tooltip.hover
                      title="Delete"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab title-link-class="p-3">
              <template v-slot:title>
                <a class="font-weight-bold active">Refunds</a>
              </template>
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                  >
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                  class="table-centered"
                  :items="ordersData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                >
                  <template v-slot:cell(paymentstatus)="row">
                    <div
                      class="badge font-size-12"
                      :class="{
                        'badge-soft-danger': `${row.value}` === 'Chargeback',
                        'badge-soft-success': `${row.value}` === 'Paid',
                        'badge-soft-warning': `${row.value}` === 'Unpaid',
                      }"
                    >
                      {{ row.value }}
                    </div>
                  </template>
                  <template v-slot:cell(invoice)>
                    <button class="btn btn-light btn-rounded">
                      Invoice
                      <i class="mdi mdi-download ml-2"></i>
                    </button>
                  </template>
                  <template v-slot:cell(action)>
                    <a
                      href="javascript:void(0);"
                      class="mr-3 text-primary"
                      v-b-tooltip.hover
                      title="Edit"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a
                      href="javascript:void(0);"
                      class="text-danger"
                      v-b-tooltip.hover
                      title="Delete"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title-link-class="p-3">
              <template v-slot:title>
                <a class="font-weight-bold active">Orders</a>
              </template>
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                  >
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                  class="table-centered"
                  :items="ordersData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                >
                  <template v-slot:cell(paymentstatus)="row">
                    <div
                      class="badge font-size-12"
                      :class="{
                        'badge-soft-danger': `${row.value}` === 'Chargeback',
                        'badge-soft-success': `${row.value}` === 'Paid',
                        'badge-soft-warning': `${row.value}` === 'Unpaid',
                      }"
                    >
                      {{ row.value }}
                    </div>
                  </template>
                  <template v-slot:cell(invoice)>
                    <button class="btn btn-light btn-rounded">
                      Invoice
                      <i class="mdi mdi-download ml-2"></i>
                    </button>
                  </template>
                  <template v-slot:cell(action)>
                    <a
                      href="javascript:void(0);"
                      class="mr-3 text-primary"
                      v-b-tooltip.hover
                      title="Edit"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a
                      href="javascript:void(0);"
                      class="text-danger"
                      v-b-tooltip.hover
                      title="Delete"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab title-link-class="p-3">
              <template v-slot:title>
                <a class="font-weight-bold active">Disputes</a>
              </template>
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;entries
                    </label>
                    <b-form-input for="text"></b-form-input>
                  </div>
                </div>

                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                  >
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                  class="table-centered"
                  :items="ordersData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                >
                  <template v-slot:cell(paymentstatus)="row">
                    <div
                      class="badge font-size-12"
                      :class="{
                        'badge-soft-danger': `${row.value}` === 'Chargeback',
                        'badge-soft-success': `${row.value}` === 'Paid',
                        'badge-soft-warning': `${row.value}` === 'Unpaid',
                      }"
                    >
                      {{ row.value }}
                    </div>
                  </template>
                  <template v-slot:cell(invoice)>
                    <button class="btn btn-light btn-rounded">
                      Invoice
                      <i class="mdi mdi-download ml-2"></i>
                    </button>
                  </template>
                  <template v-slot:cell(action)>
                    <a
                      href="javascript:void(0);"
                      class="mr-3 text-primary"
                      v-b-tooltip.hover
                      title="Edit"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a
                      href="javascript:void(0);"
                      class="text-danger"
                      v-b-tooltip.hover
                      title="Delete"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>

</template>
