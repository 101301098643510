<script>
import Multiselect from "vue-multiselect";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components: {
    ckeditor: CKEditor.component,
    Multiselect,
  },
  data() {
    return {
      categoryTotalPage: null,
      categoryCurrentPage: 0,
      categoryData: [],
      isEdit: false,
      categorySelected: null,
      formTitle: "",
      id: null,
      FILE: null,
      MOBILE_BANNER: null,
      BANNER: null,
      data: {
        name: "",
        description: "",
        is_active: "",
        parent: null,
        slug: "",
        category_title:'',
        about_category: '',
        header_image: "",
        category_image: "",
        header_description: "",
        header_text: "",
        is_featured: false,
        show_on_home_page: false,
        category_mobile_banner: '',
        lowest_price: '',
        short_name: '',
        parent_category: [],
        meta_keywords: '',
        url_handle: '',
        engine_description: '',
        page_title: ''
      },
      editor: ClassicEditor,
      editorConfig: {
        heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
            ]
        }
      },
    };
  },
  mounted() {
    this.getCategory();
    if (this.isEdit == true) {
      this.getCategoryById();
    }
  },
  methods: {
    handleImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.ImageToWebP(file);
      }
    },
    handleMobileBannerChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.mobileBannerToWebP(file);
      }
    },
    handleBannerChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.bannerToWebP(file);
      }
    },
    ImageToWebP(file) {
      const FILE_NAME = file.name.split(".")
      const UPDATED_NAME = FILE_NAME[0]
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            const convertedFile = new File([blob], UPDATED_NAME + '.webp', { type: 'image/webp' });
            this.FILE = convertedFile;
          }, 'image/webp');
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    },
    bannerToWebP(file) {
      const FILE_NAME = file.name.split(".")
      const UPDATED_NAME = FILE_NAME[0]
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            const convertedFile = new File([blob], UPDATED_NAME + '.webp', { type: 'image/webp' });
            this.BANNER = convertedFile;
          }, 'image/webp');
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    },
    mobileBannerToWebP(file) {
      const FILE_NAME = file.name.split(".")
      const UPDATED_NAME = FILE_NAME[0]
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            const convertedFile = new File([blob], UPDATED_NAME + '.webp', { type: 'image/webp' });
            this.MOBILE_BANNER = convertedFile;
          }, 'image/webp');
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    },
    getCategory() {
      this.$store.getters.client.get(`/categories/all/?page=${this.categoryCurrentPage + 1}`).then((response) => {
        let resultData = response.data;
        this.categoryData = this.categoryData.concat(resultData.results);
        this.categoryTotalPage = resultData.total_pages;
        this.categoryCurrentPage = resultData.current_page;
      });
    },
    reachedEndOfCategory(reached) {
      if (reached && this.categoryCurrentPage < this.categoryTotalPage) {
        this.getCategory();
      }
    },
    getCategoryById() {
      this.$store.getters.client
        .get(`/categories/${this.id}`)
        .then((response) => {
          this.data = response.data;
          let selectedCategoryItem = this.categoryData.find(
            (x) => x.id == this.data.parent
          );
          this.categorySelected = selectedCategoryItem;
        });
    },
    uploadImage(event) {
      this.FILE = event.target.files[0];
    },
    uploadBanner(event) {
      this.BANNER = event.target.files[0];
    },
    uploadMobBanner(event) {
      this.MOBILE_BANNER = event.target.files[0]
    },
    createCategory() {
      let formData = new FormData();
      if (this.FILE) {
        formData.append("category_image", this.FILE, this.FILE.name);
      }
      if (this.BANNER) {
        formData.append("header_image", this.BANNER, this.BANNER.name);
      }
      if (this.MOBILE_BANNER) {
        formData.append("category_mobile_banner", this.MOBILE_BANNER, this.MOBILE_BANNER.name);
      }
      formData.append("is_active", this.data.is_active);
      if (this.categorySelected) {
        formData.append("parent", this.categorySelected.id);
      }
      formData.append("description", this.data.description);
      formData.append("name", this.data.name);
      formData.append("is_featured", this.data.is_featured);
      formData.append("slug", this.data.slug);
      formData.append("header_text", this.data.header_text);
      formData.append("header_description", this.data.header_description);
      formData.append("show_on_home_page", this.data.show_on_home_page);
      formData.append("lowest_price", this.data.lowest_price);
      formData.append("short_name", this.data.short_name);
      formData.append("page_title", this.data.page_title);
      formData.append("meta_keywords", this.data.meta_keywords);
      formData.append("url_handle", this.data.url_handle);
      formData.append("category_title", this.data.category_title);
      formData.append("about_category", this.data.about_category);
      formData.append("engine_description", this.data.engine_description);
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store.getters.client
            .post("/categories/", formData)
            .then(() => {
              this.$swal({
                position: "top-end",
                icon: "success",
                title: "Category created successfully",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.push({ name: "Categories" });
            });
        }
      });
    },

    updateCategory() {
      let formData = new FormData();
      if (this.FILE) {
        formData.append("category_image", this.FILE, this.FILE.name);
      } else if (this.BANNER) {
        formData.append("header_image", this.BANNER, this.BANNER.name);
      }
      if (this.MOBILE_BANNER) {
        formData.append("category_mobile_banner", this.MOBILE_BANNER, this.MOBILE_BANNER.name);
      }
      formData.append("is_active", this.data.is_active);
      if (this.categorySelected) {
        formData.append("parent", this.categorySelected.id);
      }
      formData.append("description", this.data.description);
      formData.append("name", this.data.name);
      formData.append("is_featured", this.data.is_featured);
      formData.append("slug", this.data.slug);
      formData.append("header_text", this.data.header_text);
      formData.append("header_description", this.data.header_description);
      formData.append("show_on_home_page", this.data.show_on_home_page);
      formData.append("short_name", this.data.short_name);
      formData.append("lowest_price", this.data.lowest_price);
      formData.append("page_title", this.data.page_title);
      formData.append("meta_keywords", this.data.meta_keywords);
      formData.append("url_handle", this.data.url_handle);
      formData.append("category_title", this.data.category_title);
      formData.append("about_category", this.data.about_category);
      formData.append("engine_description", this.data.engine_description);
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store.getters.client
            .patch("/categories/" + this.$route.params.id + "/", formData)
            .then(() => {
              this.$swal({
                position: "top-end",
                icon: "success",
                title: "Category updated successfully",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.push({ name: "Categories" });
            });
        }
      });
    },
  },
  created() {
    if ("id" in this.$route.params) {
      this.isEdit = true;
      this.id = this.$route.params.id;
      this.formTitle = "Edit Category";
    } else {
      this.formTitle = "Create Category";
    }
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <b-card title="Media">
        <b-row>
          <b-col cols="4">
            <div class="border-img">
              <label for="">Image</label> <br />
              <input type="file" v-on:change="handleImageChange($event)" />
            </div>
            <div v-if="data.category_image" class="mt-3 d-inline-block position-relative">
              <img :src="data.category_image" alt="" height="65px" />
            </div>
          </b-col>
          <b-col cols="4">
            <div class="border-img">
              <label for="">Banner</label> <br />
              <input type="file" v-on:change="handleBannerChange($event)" />
            </div>
            <div v-if="data.header_image" class="mt-3">
              <img :src="data.header_image" alt="" height="65" />
            </div>
          </b-col>
          <b-col cols="4">
            <div class="border-img">
              <label for="">Mobile Banner</label> <br />
              <input type="file" v-on:change="handleMobileBannerChange($event)" />
            </div>
            <div v-if="data.category_mobile_banner" class="mt-3">
              <img :src="data.category_mobile_banner" alt="" height="65" />
            </div>
          </b-col>
        </b-row>
      </b-card>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <form class="form-horizontal" role="form">
                <b-form-group label-cols-sm="2" label-cols-lg="2" label="Category Name">
                  <b-form-input for="text" name="category name" v-model="data.name"></b-form-input>
                </b-form-group>
                <b-form-group label-cols-sm="2" label-cols-lg="2" label="Short Name">
                  <b-form-input for="text" name="Short name" v-model="data.short_name"></b-form-input>
                </b-form-group>
                <b-form-group label-cols-sm="2" label-cols-lg="2" id="header_description" label="Short Description">
                  <b-form-input for="header_description" name="header_description" v-model="data.header_description"></b-form-input>
                </b-form-group>
                <!-- <b-form-group label-cols-sm="2" label-cols-lg="2" id="category_title" label="Category Title (H2)">
                  <b-form-input for="category_title" name="category_title" v-model="data.category_title"></b-form-input>
                </b-form-group> -->
                <b-form-group id="header_text" label-cols-sm="2" label-cols-lg="2" label="List Description"
                  label-for="header_text">
                  <div class="custom-file">
                    <ckeditor class="border rounded" v-model="data.header_text" name="header_text" :config="editorConfig" :editor="editor">
                    </ckeditor>
                  </div>
                </b-form-group>
                <b-form-group id="category_title" label-cols-sm="2" label-cols-lg="2" label="Banner Content"
                  label-for="category_title">
                  <ckeditor class="border rounded" v-model="data.category_title" v-validate="'required'" name="category_title"
                  :config="editorConfig" :editor="editor"></ckeditor>
                </b-form-group>
                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="H2 & Description" label-for="text">
                  <div class="custom-file">
                    <ckeditor class="border rounded" :config="editorConfig" v-model="data.description" v-validate="'required'" name="description"
                      :editor="editor"></ckeditor>
                  </div>
                  <p class="text-danger text-sm" v-show="errors.has('description')">
                    * {{ errors.first("description") }}
                  </p>
                </b-form-group>
                <b-form-group id="about_category" label-cols-sm="2" label-cols-lg="2" label="About Category"
                  label-for="about_category">
                  <div class="custom-file">
                    <ckeditor class="border rounded" v-model="data.about_category" name="about_category" :config="editorConfig" :editor="editor">
                    </ckeditor>
                  </div>
                </b-form-group>
                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Slug" label-for="text">
                  <b-form-input for="text" name="slug" v-model="data.slug"></b-form-input>
                </b-form-group>
                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Lowest price" label-for="text">
                  <b-form-input disabled for="text" name="slug" type="number" v-model="data.lowest_price"></b-form-input>
                </b-form-group>
                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Category" label-for="text">
                  <p class="card-title-desc">
                    <multiselect name="category" disabled v-model="categorySelected" :options="categoryData" label="name"
                      track-by="id">
                      <template slot="afterList">
                        <div v-observe-visibility="reachedEndOfCategory" />
                      </template>
                    </multiselect>
                  </p>
                </b-form-group>
                <div class="form-check mb-3">
                  <input class="form-check-input" disabled type="checkbox" value v-model="data.is_active" id="defaultCheck1" />
                  <label class="form-check-label" for="defaultCheck1">Active</label>
                </div>
                <div class="form-check mb-3">
                  <input class="form-check-input" disabled type="checkbox" value v-model="data.is_featured" id="is_featured" />
                  <label class="form-check-label" for="is_featured">Featured</label>
                </div>
                <div class="form-check mb-3">
                  <input class="form-check-input" disabled type="checkbox" value v-model="data.show_on_home_page"
                    id="show_on_home_page" />
                  <label class="form-check-label" for="show_on_home_page">Add to Home Page</label>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Search Engine Optimization</h4>
          <div class="row">
            <div class="col-12">
              <form class="form-horizontal" role="form">
                <div class="form-group">
                  <label>Page Title</label>
                  <input type="text" class="form-control" id="" for="text" name="name" v-model="data.page_title" />
                </div>
                <div class="mb-3">
                  <label>Description</label>
                  <textarea class="w-100 form-control" rows="5" v-model="data.engine_description"></textarea>
                </div>
                <div class="form-group">
                  <label>Url and handle</label>
                  <input type="text" class="form-control" id="" for="text" name="name" v-model="data.url_handle" />
                </div>
                <div class="form-group">
                  <label>Meta keywords</label>
                  <input type="text" class="form-control" id="exampleInputEmail1" for="text" name="name"
                    v-model="data.meta_keywords" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right mb-3">
        <button v-if="!isEdit" @click="createCategory" type="button" class="btn btn-primary">
          Submit
        </button>
        <button v-if="isEdit" @click="updateCategory" type="button" class="btn btn-primary">
          Update
        </button>
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
</template>
