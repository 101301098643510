<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment';

export default {

  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data: function () {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        initialView: 'dayGridMonth',
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        datesSet: this.handleDatesSet,
        eventsSet: function (events) {
          console.log('Events Set:', events);
        },
        events: []
        // Don't set events here
      },
      currentEvents: [
        { title: 'Test Event', start: new Date().toISOString() }  // Use today's date
      ],
      selectedEvent: {},
      isModalVisible: false,
      form: {
        title: '',
        description: '',
        schedule_time: null,
        created_for: null,


      },
      filters: {
        month: "04",
        year: "2024",
        staff: "",
        status: "",
      },
      filtersUpdates: {
        status: "",
      },
      options: [
        { value: 1, text: 'Rohan' },
        { value: 2, text: 'Harpreet' },
        { value: 3, text: 'Gagan' },
        { value: 4, text: 'Mukesh' },
      ],

    }
  },
  watch: {
    currentEvents: {
      handler(newEvents) {
        this.calendarOptions.events = newEvents;
      },
      deep: true,
      immediate: true
    }
  },
  // computed: {
  //   eventsData() {
  //     return this.currentEvents;
  //   }
  // },

  mounted() {
    this.getstudioData();
    this.fetchEvents();
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
    },
    onReset(event) {
      event.preventDefault()
      this.form.title = ''
      this.form.description = ''
      this.form.schedule_time = null
      this.form.created_for = ''
    },
    handleEventClick(clickInfo) {
      this.selectedEvent = clickInfo.event;
      this.isModalVisible = true;
    },
    changeCalendarView() {
      const year = this.filters.year;
      const month = this.filters.month;

      const calendarApi = this.$refs.fullCalendar.getApi();
      //console.log(calendarApi);
      calendarApi.gotoDate(`${year}-${month}-01`);
    },

    onMonthOrYearChange() {
      this.changeCalendarView();
      //console.log(this.changeCalendarView());
      this.fetchEvents();
    },

    handleDatesSet({ start, end, view }) {
      //console.log("handleDatesset");
      console.log("start", start);
      // console.log(view);
      end;
      view;
      let currentMoment = moment(start);
      let incrementedMonthMoment = currentMoment.add(1, 'months');
      let newMonth = incrementedMonthMoment.format('MM');
      let newYear = incrementedMonthMoment.format('YYYY');
      console.log("Current Month:", newMonth, "Current Year:", newYear);
      if (newMonth !== this.filters.month || newYear !== this.filters.year) {
        this.filters.month = newMonth;
        this.filters.year = newYear;
        // console.log(newMonth);
        // console.log(newYear);
        this.fetchEvents();
      }
    },
    async fetchEvents() {
      const { month, year, staff, status } = this.filters;
      try {

        const resp = await this.$store.getters.client.get(`/variant/event/?staff=${staff}&status=${status}&month=${month}&year=${year}`);
        console.log(resp.data.title);
        // this.calendarOptions.initialEvents = resp.data.results;
        this.calendarOptions.events = resp.data.results.map(event => ({
          title: event.title,
          start: moment(event.schedule_time).toISOString(),
          name: event.created_for.first_name,
          description: event.description,
          status: event.status,
          id: event.id,
          // start: moment(event.created_at).format('YYYY-MM-DD') 
        }));


      } catch (error) {
        console.error('Error fetching events:', error);
      }
    },
    async getstudioData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        const resp = await this.$store.getters.client.get("/studio/studio/")
        this.options = resp.data.results.map(item => ({ value: item.id, text: item.first_name }))
      } catch (error) {
        alert(error)
      } finally {
        loader.hide()
      }
    },
    async createEvent() {
      try {
        await this.$store.getters.client
          .post("/variant/event/", this.form)
        this.$swal({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        this.form.title = '';
        this.form.description = '';
        this.form.schedule_time = null;
        this.form.created_for = null;

      } catch (error) {
        this.$swal({
          position: "top-end",
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      } finally {
        //aa
      }
    },
    async statusUpdate(id) {
      try {
        await this.$store.getters.client
          .patch(`/variant/event/${id}/`, this.filtersUpdates)
        this.$swal({
          position: "top-end",
          icon: "success",
          title: "Status Updated",
          showConfirmButton: false,
          timer: 1500,
        });
        this.filtersUpdates.title = '';


      } catch (error) {
        this.$swal({
          position: "top-end",
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      } finally {
        //aa
      }
    },
  }
}
</script>

<template>
  <div>
    <div>
      <div class="row mt-2">
        <div class="col-md-2">
          <b-form-select @change="onMonthOrYearChange" v-model="filters.month" size="sm">
            <option value="01">January</option>
            <option value="02">February</option>
            <option value="03">March</option>
            <option value="04">April</option>
            <option value="05">May</option>
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select @change="onMonthOrYearChange" v-model="filters.year" size="sm">
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </b-form-select>
        </div>
        <div class="col-md-2">
          <b-form-select @change="onMonthOrYearChange" v-model="filters.status" size="sm">
            <option value="" selected>Status</option>
            <option value="done">Done</option>
            <option value="created">Created</option>
            <option value="in-progress">In-progress</option>
            <option value="verified">Verified</option>
          </b-form-select>
        </div>
        <div class="col-md-2"></div>

        <div class="col-md-2"> <b-button v-b-modal.modal-1>Add Event</b-button>
        </div>
      </div>


    </div>
    <!-- <div class='demo-app-sidebar-section'>
      <h2>All Events ({{ currentEvents }})</h2>
      <ul>
        <li v-for='(event, Index) in calendarOptions.events' :key='Index'>
          <b>{{ event.start }}</b>
          <i>{{ event.title }}</i>
        </li>
      </ul>
    </div> -->
    <FullCalendar ref="fullCalendar" class='demo-app-calendar' :options='calendarOptions'>
      <template v-slot:eventContent='arg'>

        <div class="text-left">
          <b style="color:white;">{{ arg.event.title }}</b> <br />
          <b style="color:white;"><b>User:</b> {{ arg.event?.extendedProps?.name }}</b> <br />
        </div>


      </template>
    </FullCalendar>
    <b-modal id="modal-1" title="Add Event">
      <div>
        <b-form>
          <b-form-group id="input-group-2" label="Title:" label-for="input-2">
            <b-form-input id="input-2" v-model="form.title" placeholder="Enter title" required></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Description:" label-for="input-2">
            <b-form-textarea id="input-2" v-model="form.description" placeholder="Enter title"
              required></b-form-textarea>
          </b-form-group>

          <b-form-group id="input-group-3" label="Food:" label-for="input-3">
            <b-form-datepicker id="example-datepicker" v-model="form.schedule_time" class="mb-2"></b-form-datepicker>
          </b-form-group>

          <b-form-group id="input-group-3" label="Food:" label-for="input-3">
            <b-form-select v-model="form.created_for" :options="options" size="sm" class="mt-3"></b-form-select>
          </b-form-group>

          <b-button type="button" @click="createEvent" variant="primary">Submit</b-button>

        </b-form>
        <!-- <b-card class="mt-3" header="Form Data Result">
          <pre class="m-0">{{ form }}</pre>
        </b-card> -->
      </div>
    </b-modal>

    <b-modal id="event-details-modal" title="Event Details" :visible="isModalVisible" @hide="isModalVisible = false">
      <div>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row"><b>Title:</b></th>
              <td>
                <h5>{{ selectedEvent.title }}</h5>
              </td>
            </tr>
            <tr>
              <th scope="row"><b>User:</b></th>
              <td>
                <p> {{ selectedEvent?.extendedProps?.name }}</p>
              </td>
            </tr>
            <tr>
              <th scope="row"><b>Start:</b></th>
              <td>
                <p> {{ $moment(selectedEvent.startStr).format('YYYY-MM-DD HH:mm') }}</p>
              </td>
            </tr>
            <tr>
              <th scope="row"><b>Status:</b></th>
              <td>
                <p> {{ selectedEvent.extendedProps?.status }}</p>
                
              </td>
            </tr>
            <tr>
              <th scope="row"><b>Description:</b></th>
              <td>
                <p> {{ selectedEvent.extendedProps?.description }}</p>
              </td>
            </tr>
            <tr>
              <th scope="row"><b>Status Update:</b></th>
              <td><b-form-select @change="statusUpdate(selectedEvent?.id)" v-model="filtersUpdates.status" size="sm">
                  <option value="done">Done</option>
                  <option value="created">Created</option>
                  <option value="in-progress">In-progress</option>
                  <option value="verified">Verified</option>
                </b-form-select></td>
            </tr>

          </tbody>
        </table>



      </div>
    </b-modal>

  </div>

</template>
<style scoped>
.fc-event-time,
.fc-event-title {
  padding: 0 1px;
  white-space: nowrap;
}
</style>