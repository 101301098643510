<script>
export default {
  data() {
    return {
      supplierData: [],
      isEdit: false,
      formTitle: "",
      id: null,
      data: {
        "email": "",
        "first_name": "",
        "password": "",
        "type": "studio"
      },
    };
  },
  mounted() {
    if (this.isEdit) {
      this.getSupplier();
    }
  },
  methods: {
    async getSupplier() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        const resp = await this.$store.getters.client.get(`/studio/studio/${this.id}/`)
        this.data = resp.data
      } catch (error) {
        console.log(error)
      } finally {
        loader.hide();
      }
    },

    async createStudio() {
      try {
        if (this.isEdit) {
          await this.$store.getters.client
            .patch(`/studio/studio/${this.id}/`, this.data)
        } else {
          await this.$store.getters.client
            .post("/studio/studio/", this.data)
        }
        this.$swal({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      } catch (error) {
        this.$swal({
          position: "top-end",
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: false,
          timer: 1500,
        });
      } finally {
        this.$router.push({ name: "Studio" });
      }
    },
  },
  created() {
    if ("id" in this.$route.params) {
      this.isEdit = true;
      this.id = this.$route.params.id;
    }
  },
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">{{ formTitle }}</h4> -->
          <div class="row">
            <div class="col-12">
              <form class="form-horizontal" role="form">
                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Full Name" label-for="text">
                  <b-form-input for="text" v-model="data.first_name" name="first_name"></b-form-input>
                </b-form-group>
                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Email" label-for="text">
                  <b-form-input for="text" v-model="data.email" name="email" type="email"></b-form-input>
                </b-form-group>
                <b-form-group id="password" label-cols-sm="2" label-cols-lg="2" label="Password" label-for="password">
                  <b-form-input for="password" type="password" v-model="data.password" name="password"></b-form-input>
                </b-form-group>
                <div class="text-right mt-3">
                  <button @click="createStudio" type="button" class="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
</template>
