<script>
import Swal from "sweetalert2";
import ViewQuotation from './components/ViewQuotation.vue';
import DownloadQuote from './components/DownloadQuote.vue';
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BillingAddress from './components/BillingAddress.vue';
import ShippingAddress from './components/ShippingAddress.vue';

export default {
  components: { ViewQuotation, DownloadQuote, ckeditor: CKEditor.component, BillingAddress, ShippingAddress, },
  // components: {DownloadQuote  },
  data() {
    return {
      editorConfig: {
        toolbar: [
          'paragraph', 'heading', 'bold', 'italic', '|', 'bulletedList', 'numberedList' // Customize with your preferred buttons
        ],
        heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
            ]
        }
      },
      isEdit: false,
      fullDetails: false,
      searchOptions: 'products',
      editor: ClassicEditor,
      showSearchResult: false,
      formTitle: "",
      id: null,
      attribute_id: null,
      supplierData: [],
      selected: null,
      value1: [],
      multiple: true,
      expected_lead_time: '',
      quotationModal: false,
      quoteData: {
        products: [],
        artwork_service: 'Just Print Free',
        artwork_charges: 0,
        delivery_service: 'Free Delivery',
        shipping_charges: 0,
        discount_service: 'Not Available',
        discount_amount: 0,
        additional_service: 'No additional Service',
        additional_charges: 0,
        vat: 0,
        total: 0,
        expected_lead_days: null,
        customer_type: 'b2b',
      },
      FILE: null,
      customProduct: {
        image: null,
        name: "",
        description: "",
        files: null,
        is_vat_applicable: true,
        supplier: null,
      },
      orderData: {
        order: {},
        Currency: {
          currency_abbr: "£",
          currency_name: "Pound",
        },
        orderItems: [],
      },
      supplierList: null,
      productModal: false,
      addCostumProduct: false,
      search: '',
      productList: [],
      selectedProducts: [],
      searchCustomProcuctValue: '',
      quotaDetail: false,
      sentMailApplicable: false,
    };
  },
  
  computed: {

    productSubTotal: function () {
      return this.quoteData.products.reduce((total, product) => {
        return total + product.quotation_product_price.reduce((sum, item) => {
          return sum + Number(item.base_price);
        }, 0);
      }, 0);
    },
    productVat: function () {
      return this.quoteData.products?.reduce((total, product) => {
        let productPrice = product.quotation_product_price.reduce((sum, item) => {
          return sum + Number(item.base_price);
        }, 0);
        if (product.is_vat_applicable) {
          productPrice = productPrice * 0.2; // add 20% tax
        } else {
          productPrice = 0;
        }
        return total + productPrice;
      }, 0);
    },
    totalVat: function () {
      return (this.productVat + (Number(this.quoteData.artwork_charges) + Number(this.quoteData.shipping_charges) + Number(this.quoteData.additional_charges) - Number(this.quoteData.discount_amount)) / 5).toFixed(2)
    },
    calculateSubTotal: function () {
      return (this.productSubTotal + Number(this.quoteData.artwork_charges) + Number(this.quoteData.shipping_charges) + Number(this.quoteData.additional_charges) - Number(this.quoteData.discount_amount)).toFixed(2)
    },
    calculateTotal: function () {
      return (this.productSubTotal + Number(this.totalVat) + Number(this.quoteData.artwork_charges) + Number(this.quoteData.shipping_charges) + Number(this.quoteData.additional_charges) - Number(this.quoteData.discount_amount)).toFixed(2)
    }
  },

  mounted() {
    this.getData();
    this.getSupplier();
  },
  methods: {
    calculateExpectedDate(startDate, daysToAdd) {
      let targetDate = new Date(startDate);
      let daysAdded = 0;
      while (daysAdded < daysToAdd) {
        targetDate.setDate(targetDate.getDate() + 1);
        if (targetDate.getDay() !== 0 && targetDate.getDay() !== 6) {
          daysAdded++;
        }
      }
      this.expected_lead_time = targetDate
    },
    async getData() {
      this.quote_id = this.$route.params.id;
      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        const result = await this.$store.getters.client.get(`/order/quotation/${this.quote_id}`)
        this.quoteData = result.data;
        this.calculateExpectedDate(new Date(), this.quoteData.expected_lead_days)
        this.quoteData.products = result.data.products_pricing
        if (this.quoteData.products?.length > 0) {
          this.sentMailApplicable = true;
        }
        this.quoteData['acceptAble'] = this.allOrdersHaveSingleQuantity(this.quoteData.products)
        this.quoteData.created_by = this.quoteData.created_by?.id
      } catch (error) {
        alert(error)
      } finally {
        loader.hide();
      }
    },

    getSupplier() {
      this.$store.getters.client.get(`/supplier/supplier/`).then((response) => {
        this.supplierList = response.data.results;
      });
    },

    totalPrice() {
      if (this.product_vat !== null) {
        this.form.product_total_price = this.form.product_base_price + ((this.form.product_vat / 100) * this.form.product_base_price)
      } else {
        this.form.product_total_price = this.form.product_base_price
      }
    },

    async addNewProduct(event) {
      event.preventDefault()
      let formData = new FormData();
      if (this.FILE) {
        formData.append("image", this.FILE, this.FILE.name);
      }
      formData.append("name", this.customProduct.name);
      formData.append("description", this.customProduct.description);
      formData.append("is_vat_applicable", this.customProduct.is_vat_applicable);
      formData.append("supplier", this.customProduct.supplier);
      try {
        await this.$store.getters.client.post(`/order/customer-product/`, formData)
        this.productModal = false
        this.resetProductForm()
      } catch (error) {
        return error
      }
    },

    resetProductForm() {
      this.customProduct.image = null,
        this.customProduct.name = "",
        this.customProduct.description = "",
        this.customProduct.files = null,
        this.customProduct.is_vat_applicable = true,
        this.customProduct.supplier = null
    },

    async searchProcuct() {
      if (this.search.length > 2) {
        try {
          // this.loading = true;
          this.focus = true;
          if (this.searchOptions === 'products') {
            const response = await this.$store.getters.client.get(`/home-search/?search=${this.search}`)
            this.productList = response.data.products;
          } else if (this.searchOptions === 'custom-products') {
            const response = await this.$store.getters.client.get(`/order/customer-product/?search=${this.search}`)
            this.productList = response.data.results;
          }
        } catch (error) {
          console.error(error)
        } finally {
          // this.loading = false
        }
      }
    },

    uploadImage(event) {
      const FILE = event.target.files[0];
      if (FILE) {
        this.ImageToWebP(FILE);
      }
    },

    ImageToWebP(file) {
      const FILE_NAME = file.name.split(".")
      const UPDATED_NAME = FILE_NAME[0]
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            const convertedFile = new File([blob], UPDATED_NAME + '.webp', { type: 'image/webp' });
            this.FILE = convertedFile;
          }, 'image/webp');
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    },
    resetForm() {
      this.resetProductForm()
      this.productModal = false;
    },
    addRow(item) {
      item.quotation_product_price.push({ quantity: 1, total_price: 0, base_price: 0, supplier_price: 0, })
    },

    removeRow(item, index) {
      item.quotation_product_price.splice(index, 1)
    },

    addToSelectedProduct(product) {
      this.quoteData.products.push({
        image: product.product_images ? product.product_images[0].medium_image : product.image,
        name: product.name,
        product: product.product_images ? product.product_images[0].product_id : null,
        custom_product: this.searchOptions === 'custom-products' ? product.id : null,
        description: product.short_description || product.description,
        quotation_product_price: [
          { quantity: 1, total_price: 1, base_price: 0, supplier_price: 0, },
        ],
        is_vat_applicable: product.is_vat_applicable,
        // margin: 40,
      })
    },
    deleteProductItem(index) {
      this.quoteData.products.splice(index, 1)
      Swal.fire({
        title: "Deleted",
        text: "The product is deleted successfully",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Close",
      })
    },

    async updateQuote(value) {
      try {
        this.loading = true;
        await this.$store.getters.client.patch(`/order/quotation/${this.$route.params.id}/`, value)
      } catch (error) {
        alert("something went wrong", error)
      } finally {
        this.getData();
        this.loading = true;
      }
    },
    sendEmail(value) {
      Swal.fire({
        title: "Send Email?",
        text: "The user will receve a mail with current Quotation data.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Send!",
        // eslint-disable-next-line no-unused-vars
      }).then((result) => {
        console.log(result, value)
        if (result.isConfirmed) {
          this.$store.getters.client.get(`/order/quotation-email/${this.$route.params.id}/`)
          this.updateQuote(value)
        }
      });
    },

    acceptOrder() {
      Swal.fire({
        title: "Place order",
        text: "The order will be placed without artwork and payment",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Order now!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.placeOrder()
        }
      });
    },

    getDropdownVariant(status) {
      switch (status) {
        case 'in_pipeline':
          return 'warning';
        case 'accepted':
          return 'success';
        case 'rejected':
          return 'danger';
        case 'enquiry':
          return 'primary';
        case 'approved':
          return 'info';
        case 'net30':
          return 'warning';
        case 'paid':
          return 'success';
        default:
          return 'secondary';
      }
    },
    allOrdersHaveSingleQuantity(products) {
      // Use the `every` method to check if all orders have a length of 1
      return products.every(function (pricing) {
        return pricing.quotation_product_price.length === 1;
      });
    },

    async createQuote() {
      this.quoteData.vat = this.totalVat,
        this.quoteData.total = this.calculateTotal,
        this.quoteData.subtotal = this.calculateSubTotal,
        this.quoteData.users = this.quoteData.users.id,
        this.quoteData.pdf_file = null
      this.quoteData.products_pricing = this.quoteData.products,
        this.quoteData.expected_lead_time = this.$moment(this.expected_lead_time).format("YYYY-MM-DD")
      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        await this.$store.getters.client.patch(`/order/quotation/${this.$route.params.id}/`, this.quoteData)
      } catch (error) {
        alert(error)
      } finally {
        loader.hide()
        this.getData()
      }
    },

    async placeOrder() {
      this.manageOrderItems()
      try {
        const orderData = {
          shipping_method: this.quoteData.delivery_service,
          shipping_amount: Number(this.quoteData.shipping_charges),
          vat_amount: Number(this.quoteData.vat),
          design_service_price: Number(this.quoteData.artwork_charges),
          price_amount: this.quoteData.total,
          ...this.quoteData.billing_address[0],
          ...this.quoteData.shipping_address[0],
          delivery_upgrade: '',
          payment_method: 'unpaid',
          order_subtotal: this.quoteData.subtotal,
          quotation: this.$route.params.id,
          customer: this.quoteData.users.id
        };
        this.orderData.order = orderData;
        await this.$store.getters.client.post(`/order/create-order/`, this.orderData)
          .then((orderResponse) => {
            if (orderResponse.status == 200) {
              this.updateQuote({ status: 'accepted' })
            }
          });

      } catch (error) {
        console.log(error)
      }
    },
    manageOrderItems() {
      for (let i = 0; i < this.quoteData.products.length; i++) {
        console.log(this.quoteData.products)
        this.orderData.orderItems.push({
          quotation_product: this.quoteData.products[i].id,
          qty_ordered: this.quoteData.products[i].quotation_product_price[0].quantity,
          design_service_price: Number(this.quoteData.artwork_charges),
          item_total: this.quoteData.products[i].quotation_product_price[0].base_price,
          delivery_meathod: this.quoteData.delivery_service,
          delivery_days: this.quoteData.expected_lead_days,
          delivery_date: this.$moment(this.expected_lead_time).format("YYYY-MM-DD"),
          vat_amount: (Number(this.quoteData.products[i].quotation_product_price[0].base_price) / 5).toFixed(2),
          item_price: this.quoteData.products[i].quotation_product_price[0].base_price,
        });
      }
    },
  },

};
</script>

<template>
  <div class="row" v-if="quoteData" id="quoteData">
    <b-col cols="6">
      <b-card title="Billing address">
        <BillingAddress :quoteData="quoteData"/>
        <!-- <div>{{ quoteData?.users?.first_name }} {{ quoteData?.users?.last_name }}</div>
        <div>{{ quoteData?.company_details }}</div>
        <div>{{ quoteData?.users?.address1 }} {{ quoteData?.users?.address2 }}</div>
        <div>{{ quoteData?.users?.city }} {{ quoteData?.users?.county }} {{ quoteData?.users?.zip }}</div>
        <div>{{ quoteData?.users?.email }}</div>
        <div>{{ quoteData?.users?.phone }}</div> -->
      </b-card>
    </b-col>
    <b-col cols="6">
      <b-card title="Shipping address">
        <ShippingAddress :quoteData="quoteData"/>
        <!-- <div>{{ quoteData?.users?.first_name }} {{ quoteData?.users?.last_name }}</div>
        <div>{{ quoteData?.company_details }}</div>
        <div>{{ quoteData?.users?.address1 }} {{ quoteData?.users?.address2 }}</div>
        <div>{{ quoteData?.users?.city }} {{ quoteData?.users?.county }} {{ quoteData?.users?.zip }}</div>
        <div>{{ quoteData?.users?.email }}</div>
        <div>{{ quoteData?.users?.phone }}</div> -->
      </b-card>
    </b-col>
    <b-col cols="12">
      <b-card class="d-flex justify-content-end">
        <b-button variant="success" size="sm" @click="quotaDetail = !quotaDetail">View Quotation</b-button>
      </b-card>
    </b-col>
    <b-modal title="Quotation detail" v-model="quotaDetail" size="lg" hide-footer>
      <ViewQuotation :quoteData="quoteData" />
      <template #modal-header="{ close }">
        <h5>Quotation detail</h5>
        <div class="d-flex">
          <DownloadQuote :quoteData="quoteData" />
          <b-button size="sm" class="ml-2" variant="outline-danger" @click="close()">
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-col cols="12">
      <b-card title="Quotation Details">
        <table class="table table-centered mb-0 table-nowrap">
          <tbody>
            <tr>
              <th style="width: 20%;">NUMBER</th>
              <td>{{ quoteData?.number }}</td>
            </tr>
            <tr>
              <th style="width: 20%;">STATUS</th>
              <td>
                <!-- {{ quoteData?.status }} -->
                <b-dropdown size="sm" class="m-2" :variant="getDropdownVariant(quoteData?.status)">
                  <template v-slot:button-content>
                    <span v-if="quoteData?.status === 'in_pipeline'">IN PROGRESS</span>
                    <span v-if="quoteData?.status === 'accepted'">ACCEPTED</span>
                    <span v-if="quoteData?.status === 'rejected'">REJECTED</span>
                    <span v-if="quoteData?.status === 'enquiry'">PENDING</span>
                    <span style="background-color: blueviolet;" v-if="quoteData?.status === 'approved'">IN APPROVAL</span>
                  </template>
                  <b-dropdown-item-button :disabled="!quoteData?.acceptAble"
                    @click="acceptOrder">ACCEPT</b-dropdown-item-button>
                  <b-dropdown-item-button @click="updateQuote({ status: 'rejected' })">REJECTED</b-dropdown-item-button>
                </b-dropdown>
              </td>
            </tr>
            <tr>
              <th style="width: 20%;">CONTACT TYPE</th>
              <td>{{ quoteData?.quotation_type }}</td>
            </tr>
            <tr>
              <th style="width: 20%;">CUSTOMER TYPE</th>
              <td>{{ quoteData?.users?.customer_type }}</td>
            </tr>
            <tr>
              <th style="width: 20%;">REQUIREMENTS</th>
              <td style="white-space: break-spaces;">
                <span v-if="quoteData?.message?.length > 250 && !fullDetails"
                  v-html="quoteData?.message.substring(0, 250)"></span>
                <span v-else v-html="quoteData?.message"></span>
                <span v-if="quoteData?.message?.length > 250 && !fullDetails">... <small class="pointer text-primary"
                    @click="fullDetails = true">Read more</small></span>
                <span v-if="quoteData?.message?.length > 250 && fullDetails"><small class="pointer text-primary"
                    @click="fullDetails = false">Read Less</small></span>
              </td>
            </tr>
            <tr v-if="quoteData?.file_attachment">
              <th style="width: 20%;">ATTACHMENT</th>
              <td><a :href="quoteData?.file_attachment" target="_blank">Download</a></td>
            </tr>
            <tr>
              <th style="width: 20%;">CREATED BY</th>
              <td>{{ quoteData?.created_by?.first_name + ' ' + quoteData?.created_by?.last_name }}</td>
            </tr>
            <tr>
              <th style="width: 20%;">EXPECTED LEAD TIME</th>
              <td>
                <b-input-group class="w-50">
                  <b-form-datepicker disabled id="expected_lead_time" style="width: 200px;" v-model="expected_lead_time"
                    :min="new Date()"></b-form-datepicker>
                  <b-form-input v-model="quoteData.expected_lead_days"
                    @blur="updateQuote({ expected_lead_days: quoteData.expected_lead_days })"></b-form-input>
                </b-input-group>
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </b-col>
    <b-col cols="12" class="mb-3">
      <b-row>
        <b-col cols="6" class="product-search-container position-relative">
          <b-input-group>
            <b-form-input type="search" placeholder="Search Products" @focus="showSearchResult = true" v-model="search"
              @input="searchProcuct"></b-form-input>
            <template #prepend>
              <b-form-select v-model="searchOptions" :options="['products', 'custom-products']"></b-form-select>
            </template>
          </b-input-group>
          <div class="bg-white rounded list-unstyled position-absolute w-100 product-list-container" style="z-index: 9;"
            v-if="search?.length > 1 && productList?.length > 0 && showSearchResult">
            <b-dropdown-item v-for="product in productList" :key="product.id"
              @click="addToSelectedProduct(product); showSearchResult = false">
              <img :src="product.product_images ? product.product_images[0].medium_image : product.image" width="60"
                class="rounded h-auto" alt="image" />
              {{ product.name }}
            </b-dropdown-item>
          </div>
        </b-col>
        <b-col cols="6">
          <b-row>
            <b-col cols="6"></b-col>
            <b-col cols="6">
              <b-button variant="outline-success" class="w-100" @click="productModal = !productModal">Create custom
                product</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12">
      <b-card title="Products" v-if="quoteData.products?.length > 0">
        <table class="table mb-0 table-nowrap">
          <thead class="bg-light">
            <tr>
              <th style="width: 120px">Image</th>
              <th style="width: 30%">Product Details</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, j) in quoteData.products" :key="j" class="align-top position-relative">
              <td style="max-width: 120px; vertical-align: top;" class="align-top">
                <span class="product-delete-icon position-absolute" @click="deleteProductItem(j)"><i
                    class="mdi mdi-close text-danger"></i></span>
                <div><img :src="item.image" alt="image" width="100" class="rounded w-100"></div>
              </td>
              <td style="width: 30%; white-space: break-spaces;">
                <strong>{{ item.name }}</strong>
                <ckeditor class="border rounded" :config="editorConfig" v-model="item.description" :editor="editor">
                </ckeditor>
              </td>
              <td>
                <table class="table table-centered mb-0 table-nowrap">
                  <thead class="bg-light">
                    <tr>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Markup</th>
                      <th>Supplier</th>
                      <th>Unit price</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(price, index) in item.quotation_product_price" :key="index">
                      <td><b-form-input v-model="price.quantity"></b-form-input></td>
                      <td><b-form-input v-model="price.base_price"></b-form-input></td>
                      <td><b-form-input disabled
                          :value="(((Number(price.base_price) - Number(price.supplier_price)) * 100) / Number(price.supplier_price)).toFixed(2)"></b-form-input>
                      </td>
                      <td><b-form-input v-model="price.supplier_price"></b-form-input></td>
                      <td><b-form-input :value="(Number(price.base_price) / Number(price.quantity)).toFixed(2)"
                          readonly></b-form-input></td>
                      <td> {{ (Number(price.base_price)).toFixed(2) }}</td>
                      <td>
                        <b-button-group size="sm">
                          <b-button variant="outline-danger" :disabled="item.quotation_product_price.length < 2"
                            @click="removeRow(item, index)">Remove</b-button>
                          <b-button variant="outline-success" @click="addRow(item)">Add</b-button>
                        </b-button-group>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <b-form-group label="Artwork Charges" label-cols="4" class="mb-3">
                  <b-form-select v-model="quoteData.artwork_service">
                    <b-form-select-option value="Just Print Free">Just Print Free</b-form-select-option>
                    <b-form-select-option value="Examine My File">Examine My File</b-form-select-option>
                    <b-form-select-option value="File Check With Proofing">File Check With Proofing</b-form-select-option>
                    <b-form-select-option value="File Support">File Support</b-form-select-option>
                    <b-form-select-option value="Design For Me">Design For Me</b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group label="Additional Cost" label-cols="4" class="mb-3">
                  <b-form-select v-model="quoteData.additional_service">
                    <b-form-select-option value="No additional Service">No additional Service</b-form-select-option>
                    <b-form-select-option value="Screen Charges">Screen Charges</b-form-select-option>
                    <b-form-select-option value="Labour Charges">Labour Charges</b-form-select-option>
                    <b-form-select-option value="Special Finishing">Special Finishing</b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group label="Discount" label-cols="4" class="mb-3">
                  <b-form-select v-model="quoteData.discount_service">
                    <b-form-select-option value="Not Available">Not Available</b-form-select-option>
                    <b-form-select-option value="5% Discount">5% Discount</b-form-select-option>
                    <b-form-select-option value="5% Discount">10% Discount</b-form-select-option>
                    <b-form-select-option value="Discount">Set Price Discount</b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group label="Delivery Charges" label-cols="4" class="mb-3">
                  <b-form-select v-model="quoteData.delivery_service">
                    <b-form-select-option value="Free Delivery">Free Delivery</b-form-select-option>
                    <b-form-select-option value="Excluding Delivery" selected>Excluding Delivery
                      Charges</b-form-select-option>
                    <b-form-select-option value="Standard Next Day">Standard Next Day Service</b-form-select-option>
                    <b-form-select-option value="Pre12 Next Day">Pre12 Next Day Service</b-form-select-option>
                    <b-form-select-option value="Pallet">Pallet Service</b-form-select-option>
                    <b-form-select-option value="Dedicated Vehicle">Dedicated Vehicle Service</b-form-select-option>
                    <b-form-select-option value="Split Delivery">Split Delivery Service</b-form-select-option>
                    <b-form-select-option value="Collection">Collection</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </td>
              <td>
                <b-form-group label-cols="8" class="mb-3">
                  <b-form-input type="number" placeholder="0.00" v-model="quoteData.artwork_charges"></b-form-input>
                </b-form-group>
                <b-form-group label-cols="8" class="mb-3">
                  <b-form-input type="number" placeholder="0.00" v-model="quoteData.additional_charges"></b-form-input>
                </b-form-group>
                <b-form-group label-cols="8" class="mb-3">
                  <b-form-input type="number" placeholder="0.00" v-model="quoteData.discount_amount"></b-form-input>
                </b-form-group>
                <b-form-group label-cols="8" class="mb-3">
                  <b-form-input type="number" placeholder="0.00" v-model="quoteData.shipping_charges"></b-form-input>
                </b-form-group>
                <b-form-group label-cols="8" label-align="right" label="Subtotal" class="mb-3">
                  <b-form-input type="number" disabled placeholder="0.00" v-model="calculateSubTotal"></b-form-input>
                </b-form-group>
                <b-form-group label-cols="8" label-align="right" label="VAT" class="mb-3">
                  <b-form-input type="number" disabled placeholder="0.00" v-model="totalVat"></b-form-input>
                </b-form-group>
                <b-form-group label-cols="8" label-align="right" label="Total" class="mb-3">
                  <b-form-input type="number" disabled placeholder="0.00" v-model="calculateTotal"></b-form-input>
                </b-form-group>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-between">
          <div class="d-flex" style="gap: 1rem;">
            <b-button variant="primary" :disabled="!sentMailApplicable" @click="sendEmail({ status: 'in_pipeline' })">Send
              Quotation Email</b-button>
          </div>
          <b-button variant="success" @click="createQuote">Save quotation</b-button>
        </div>
      </b-card>
    </b-col>

    <b-modal title="Create new product" v-model="productModal" hide-footer>
      <b-row>
        <b-col>
          <b-form @submit="addNewProduct" ref="productForm">
            <div class="border-img mb-2">
              <label for="">Product Image</label> <br />
              <input type="file" v-on:change="uploadImage($event)" />
            </div>
            <b-form-group label="Image URL">
              <b-form-input size="sm" type="text" v-model="customProduct.files"></b-form-input>
            </b-form-group>
            <b-form-group label="Product Name">
              <b-form-input size="sm" type="text" v-model="customProduct.name" required></b-form-input>
            </b-form-group>
            <b-form-group label="Description">
              <b-form-textarea v-model="customProduct.description" required></b-form-textarea>
            </b-form-group>
            <b-form-group label="Supplier">
              <b-form-select size="sm" type="text" :options="supplierList" text-field="company_name" value-field="id"
                v-model="customProduct.supplier"></b-form-select>
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="customProduct.is_vat_applicable" :unchecked-value="false" switch class="mb-1">
                <label>
                  VAT
                </label>
              </b-form-checkbox>
            </b-form-group>
            <hr>
            <b-row>
              <b-col><b-button variant="secondary" class="w-100" type="button"
                  @click="resetForm">Cancel</b-button></b-col>
              <b-col><b-button variant="success" class="w-100" type="submit">Add Product</b-button></b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<style >
.btn-decrease,
.btn-increase {
  position: absolute;
  width: 30px;
  height: 20px;
  right: 0;
  display: grid;
  place-content: center;
  font-size: 1.5rem;
  color: #FFF;
}

.btn-decrease {
  bottom: 0;
}

.btn-increase {
  top: 0;
}

textarea {
  padding: 2px;
  resize: none;
  overflow: hidden;
}

textarea:focus {
  outline: none;
}

.product-delete-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 61, 96, 0.2);
  cursor: pointer;
  z-index: 9999;
  transition: all 0.3s ease;
}

.product-search-container {}

.product-list-container {
  bottom: 100%;
  max-height: 300px;
  left: 0;
  overflow: auto;
}
</style>