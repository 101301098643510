const img = new Image();
exports.convertToWebp = (file) => {
  return img.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    canvas.toBlob((blob) => {
      const convertedFile = new File([blob], file.name + '.webp', { type: 'image/webp' });
      this.FILE = convertedFile;
    }, 'image/webp');
  }
}
