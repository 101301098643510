<script>
/**
 * Dashboard component
 */
export default {
  data() {
    return {
      quotationData:[],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      fields: [
        { key: "number", label: "ID" },
        { key: "created_at", label: "Date/Time" },
        { key: "name", label: "Name" },
        { key: "company_details", label: "Company" },
        { key: "email", label: "Email" },
        { key: "phone", label: "Phone" },
        { key: "total", label: "Amount" },
        { key: "status", label: "Status" },
        { key: "message", sortable: true, label: "Message", thStyle: { width: "40%" } },
      ],
    };
  },
  mounted() {
    this.getQuotationData(1)
  },
  methods: {
    myRowClickHandler(event) {
      this.$router.push({ name: "QuotationDetails", params: { id: event.id } });
    },
    goTousersDetail() {
      this.$router.push({ name: "usersDetails" });
    },
    addQuotation(){
      this.$router.push(`/add-new-quotation`)
    },
    getDropdownVariant(status) {
      switch (status) {
        case 'in_pipeline':
          return 'warning'; // You can change this to the desired variant
        case 'accepted':
          return 'success'; // Change this to the appropriate variant
        case 'rejected':
          return 'danger'; // Change this to the appropriate variant
        case 'enquiry':
          return 'primary'; // Change this to the appropriate variant
        case 'approved':
          return 'info';
        case 'net30':
          return 'warning';
        case 'paid':
          return 'success'; // Change this to the appropriate variant
        default:
          return 'secondary'; // Default variant
      }
    },
    async updateQuote(value, change) {
      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        await this.$store.getters.client.patch(`/order/quotation/${value.id}/`, change)
      } catch (error) {
        alert("something went wrong", error)
      } finally {
        this.getQuotationData(1)
        loader.hide()
      }
    },
    async getQuotationData(value) {
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client.get(`/order/quotation/?page=${value}`).then((response) => {
        this.quotationData = response.data.results;
        this.totalRows = response.data.total_count
        this.perPage = response.data.per_page;
        this.currentPage = response.data.current_page;
        loader.hide();
      }).catch(err => {
        console.log(err)
        loader.hide()
      });
    },
  },
};
</script>

<template>
  <div>
    <div class="add-button">
      <a href="javascript:void(0);" class="btn btn-primary" @click="addQuotation()">
        + Add new quotation
      </a>
    </div>
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
          </b-dropdown>

          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
            </div>
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input type="search" class="form-control form-control-sm ml-2"></b-form-input>
                </label>
              </div>
            </div>
          </div>
          <div class="table-responsive" style="white-space: nowrap;">
            <b-table :items="quotationData" :fields="fields" responsive="sm" @row-clicked="myRowClickHandler">
              <template v-slot:cell(created_at)="row">
                <span ><i class="ri-article-line"></i>{{ $moment(row.item.created_at).tz('Europe/London').format('DD MMM, YYYY - HH:mm') }}</span> <br>
                <span v-if="row.item.email_sent"><i class="ri-mail-send-line"></i>{{ $moment(row.item.email_sent).tz('Europe/London').format('DD MMM, YYYY - HH:mm') }}</span>
              </template>
              <template v-slot:cell(file_attachment)="row">
                <a :href="row.item.file_attachment" target="_blank">{{row.item.file_attachment ? 'Download File' : 'Not available'}}</a>
              </template>
              <template v-slot:cell(name)="row">
                <span>{{row.item.users?.first_name}} {{row.item.users?.last_name}}</span>
              </template>
              <template v-slot:cell(message)="row">
                <div class="one-line" style="white-space: normal;">{{row.item.message}}</div>
              </template>
              <template v-slot:cell(email)="row">
                <span>{{row.item.users?.email}}</span>
              </template>
              <template v-slot:cell(phone)="row">
                <span>{{row.item.users?.phone}}</span>
              </template>
              <template v-slot:cell(total)="row">
                <span>&#163;{{row.item?.total || '0.00'}}</span>
              </template>
              
              <template v-slot:cell(status)="row">
                <b-dropdown size="sm" class="m-2" disabled :variant="getDropdownVariant(row.item.status)">
                  <template v-slot:button-content>
                    <span v-if="row.item.status === 'in_pipeline'">IN PROGRESS</span>
                    <span v-if="row.item.status === 'accepted'">ACCEPTED</span>
                    <span v-if="row.item.status === 'rejected'">REJECTED</span>
                    <span v-if="row.item.status === 'enquiry'">PENDING</span>
                    <span style="background-color: blueviolet;" v-if="row.item.status === 'approved'">IN APPROVAL</span>
                  </template>
                </b-dropdown>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" @change="getQuotationData" :total-rows="totalRows" :per-page="perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.add-button {
  margin-bottom: 27px;
  margin-left: 14px;
}
</style>
