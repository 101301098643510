<script>
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      categoryData: [],
      categoryOptionList: [],
      categoryTotalPage: null,
      categoryCurrentPage: null,
      trashData: [],
      totalRows: 1,
      current_page: 1,
      currentPage: null,
      per_page: null,
      perPage: 15,
      trash_total_rows: 1,
      trash_per_page: 15,
      trash_current_page: 1,
      pageOptions: [5, 10, 15, 25, 50],
      filter: null,
      filterOn: [],
      search: '',
      sortBy: "orderid",
      sortDesc: false,
      allFields: [
        { key: "name", sortable: true, label: "Name" },
        { key: "short_name", label: "Short Name" },
        { key: "slug", label: "Slug" },
        { key: "parent_category", label: "Parent Category" },
        // { key: "show_a_to_z_category", label: "A2Z" },
        // { key: "most_popular_category", label: "Most Popular" },
        // { key: "discounted_category", label: "Discounted" },
        // { key: "recycled_category", label: "Recycled" },
        // { key: "promotional_category", label: "Promotional" },
        { key: "action" },
      ],
      trashFields: [
        { key: "name", sortable: true, label: "Name" },
        { key: "slug", label: "Slug" },
        { key: "parent", label: "Parent Category" },
        { key: "action" },
      ],
    };
  },
  computed: {
    rows() {
      return this.categoryData.length;
    },
  },
  mounted() {
    this.getCategoryData(1);
    this.getTrashData();
    this.getCategory()
    this.totalRows = this.categoryData.length;
  },
  methods: {
    searchCategory() {
      if (this.search) {
        let loader = this.$loading.show({
          loader: "dots",
        });
        this.$store.getters.client
          .get(`/categories/all/?search=${this.search}`)
          .then((response) => {
            this.categoryData = response.data.results;
            this.totalRows = response.data.total_count;
            this.per_page = response.data.per_page;
            loader.hide();
          });
      } else {
        this.getCategoryData(1);
      }
    },
    toggleA2Z(item) {
      this.$store.getters.client.patch(
        `/categories/${item.slug}/`,
        { show_a_to_z_category: item.show_a_to_z_category }
      );
    },
    promotionalA2Z(item) {
      this.$store.getters.client.patch(
        `/categories/${item.slug}/`,
        { promotional_category: item.promotional_category }
      );
    },
    recycledA2Z(item) {
      this.$store.getters.client.patch(
        `/categories/${item.slug}/`,
        { recycled_category: item.recycled_category }
      );
    },
    discountedA2Z(item) {
      this.$store.getters.client.patch(
        `/categories/${item.slug}/`,
        { discounted_category: item.discounted_category }
      );
    },
    mostPopular(item) {
      this.$store.getters.client.patch(
        `/categories/${item.slug}/`,
        { most_popular_category: item.most_popular_category }
      );
    },
    reachedEndOfCategory(reached) {
      if (reached && this.categoryCurrentPage < this.categoryTotalPage) {
        this.getCategory();
      }
    },
    getCategory() {
      this.$store.getters.client.get(`/categories/all/?page=${this.categoryCurrentPage + 1}`).then((response) => {
        let resultData = response.data;
        this.categoryOptionList = this.categoryOptionList.concat(resultData.results);
        this.categoryTotalPage = resultData.total_pages;
        this.categoryCurrentPage = resultData.current_page;
      });
    },
    updateParent(item) {
      let data = []
      item.parent_category.forEach((element) => {
        data.push(element.id)
      })
      this.$store.getters.client.patch(
        `/categories/${item.slug}/`,
        { parent_category: '' }
      );
    },
    goToCreateCategories() {
      this.$router.push({ name: "CreateCategories" });
    },
    getCategoryData(value) {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client.get(`/categories/all/?page=${value}`).then((response) => {
        this.categoryData = response.data.results;
        this.totalRows = response.data.total_count;
        this.per_page = response.data.per_page;
        loader.hide();
      });
    },
    getTrashData() {
      this.$store.getters.client
        .get(`/categories-trash/`)
        .then((response) => {
          this.trashData = response.data.results;
        });
    },
    editCategory(categories) {
      this.$router.push({ path: `edit-categories/${categories.item.slug}` });
    },
    CategoryFaq(categories) {
      this.$router.push({ path: `/category/${categories.item.slug}/faq` });
    },
    restore(row) {
      Swal.fire({
        title: "Restore the Product",
        text: "It will store to all products",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Restore",
        // eslint-disable-next-line no-unused-vars
      }).then((result) => {
        this.$store.getters.client.patch(`/categories-trash/${row.id}/`, {
          is_trash: false,
        });
        this.trashData = this.trashData.filter((x) => {
          return x.id != row.id;
        });
      });
    },
    deleteCategory(row) {
      Swal.fire({
        title: "Delete product permanently?",
        text: "You won't be able to restore the product again",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        // eslint-disable-next-line no-unused-vars
      }).then((result) => {
        this.$store.getters.client.delete(`/categories-trash/${row}/`);
        this.trashData = this.trashData.filter((x) => {
          return x.id != row;
        });
      });
    },
    confirm(categories) {
      Swal.fire({
        title: "Move to trash",
        text: "You can delete it from trash",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        // eslint-disable-next-line no-unused-vars
      }).then((result) => {
        this.$store.getters.client.patch(`/categories/${categories.item.slug}/`, { 'is_trash': true })
        this.categoryData = this.categoryData.filter((x) => {
          return x.id != categories.item.id
        })
      });
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<template>
  <div>
    <div class="add-button">
      <a href="javascript:void(0);" class="btn btn-primary" @click="goToCreateCategories">
        + Add New Category
      </a>
    </div>
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body pt-0">
          <b-tabs nav-class="nav-tabs-custom">
            <b-tab title-link-class="p-3">
              <template v-slot:title>
                <a class="font-weight-bold active">All</a>
              </template>
              <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
              </b-dropdown>

              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input v-model="search" type="search" class="form-control form-control-sm ml-2"
                        @keypress.enter="searchCategory()"></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table :items="categoryData" :fields="allFields" responsive="sm" :per-page="perPage"
                  :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                  :filter-included-fields="filterOn" @filtered="onFiltered">
                  <!-- <template v-slot:cell(show_a_to_z_category)="row">
                    <div class="d-flex align-items-center">
                      <b-form-checkbox v-model="row.item.show_a_to_z_category" switch class="mb-1"
                        @change="toggleA2Z(row.item)">
                      </b-form-checkbox>
                    </div>
                  </template>
                  <template v-slot:cell(promotional_category)="row">
                    <div class="d-flex align-items-center">
                      <b-form-checkbox v-model="row.item.promotional_category" switch class="mb-1"
                        @change="promotionalA2Z(row.item)">
                      </b-form-checkbox>
                    </div>
                  </template> 
                  <template v-slot:cell(recycled_category)="row">
                    <div class="d-flex align-items-center">
                      <b-form-checkbox v-model="row.item.recycled_category" switch class="mb-1"
                        @change="recycledA2Z(row.item)">
                      </b-form-checkbox>
                    </div>
                  </template>
                  <template v-slot:cell(discounted_category)="row">
                    <div class="d-flex align-items-center">
                      <b-form-checkbox v-model="row.item.discounted_category" switch class="mb-1"
                        @change="discountedA2Z(row.item)">
                      </b-form-checkbox>
                    </div>
                  </template>
                  <template v-slot:cell(most_popular_category)="row">
                    <div class="d-flex align-items-center">
                      <b-form-checkbox v-model="row.item.most_popular_category" switch class="mb-1"
                        @change="mostPopular(row.item)">
                      </b-form-checkbox>
                    </div>
                  </template> -->
                  <template v-slot:cell(parent_category)="row">
                    <div class="d-flex align-items-center">
                      <multiselect disabled name="category" v-model="row.item.parent_category" :options="categoryOptionList"
                        :multiple="true" label="name" track-by="id" :hide-selected="true" :searchable="true"
                        @input="updateParent(row.item)">
                        <template slot="afterList">
                          <div v-observe-visibility="reachedEndOfCategory" />
                        </template>
                      </multiselect>
                    </div>
                  </template> 
                  <template v-slot:cell(action)="row">
                    <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover data-toggle="tooltip"
                      title="Edit" @click="editCategory(row)">
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a href="javascript:void(0);" class="text-success" v-b-tooltip.hover title="Faq">
                      <i @click="CategoryFaq(row)"  class="mdi mdi-help-circle font-size-18"></i>
                    </a>
                    <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">
                      <i @click="confirm(row)" class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="current_page" :total-rows="totalRows" :per-page="per_page"
                        @change="getCategoryData"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title-link-class="p-3">
              <template v-slot:title>
                <a class="font-weight-bold active">Trash</a>
              </template>
              <div class="table-responsive">
                <b-table :items="trashData" :fields="trashFields" responsive="sm" :per-page="trash_per_page"
                  :current-page="trash_current_page">
                  <template v-slot:cell(action)="row">
                    <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover data-toggle="tooltip"
                      title="Restore">
                      <i @click="restore(row.item)" class="mdi mdi-refresh font-size-18"></i>
                    </a>
                    <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">
                      <i @click="deleteCategory(row.item.slug)" class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="trash_current_page" :total-rows="trashData.length"
                        :per-page="trash_per_page"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.add-button {
  margin-bottom: 27px;
  margin-left: 14px;
}
</style>
