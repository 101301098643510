<template>
  <b-card title="Reviews">
    <b-button variant="primary" @click="addReview">Add Review</b-button>
    <b-row class="mt-3">
        <b-col cols="12" md="6" v-for="item in reviewList" :key="item.id">
            <b-card @click="updateReview(item.id)">
                <p class="lead">{{item.description}}</p>
                    <blockquote class="blockquote">
                        <footer class="blockquote-footer">{{item.customer_name}}</footer>
                        <p class="mb-0">{{item.company_name}}</p>
                    </blockquote>
            </b-card>
        </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
    data(){
        return{
            reviewList:[]
        }
    },
    mounted(){
        this.getReviewsList()
    },
    methods:{
        addReview(){
            this.$router.push('/add-review')
        },
        updateReview(id){
            this.$router.push(`/update-review/${id}`)
        },
        async getReviewsList(){
            await this.$store.getters.client.get('/reviews').then((res)=>{
                this.reviewList = res.data.data
            })
        }
    }
}
</script>

<style>

</style>