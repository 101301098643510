<template>
  <b-row>
    <b-col cols="12" class="mb-3">
        <b-button variant="success" class="float-right" @click="addSize()">Add paper size</b-button>
    </b-col>
    <b-col cols="6" md="3" v-for="item in sizeList" :key="item.id">
        <b-card :title="item.title" @click="editSize(item.id)">
            <img :src="item.image ? MEDIA_URL+item.image : ''" alt="image" class="w-100">
        </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { MEDIA } from "@/api/global.env.js"
export default {
    data(){
        return{
            MEDIA_URL: MEDIA,
            sizeList:[]
        }
    },
    mounted(){
        this.getTeamData()
    },
    methods:{
        async getTeamData(){
            await this.$store.getters.client.get('/paper-size').then((res)=>{
                this.sizeList = res.data.data
            })
        },
        addSize(){
            this.$router.push('/add-paper-size')
        },
        editSize(id){
            this.$router.push(`/update-paper-size/${id}/`)
        }
    }
}
</script>

<style>

</style>