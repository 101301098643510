<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button variant="outline-success" @click="$router.push('/marketing-emails')" class="mb-3">Promotional mails</b-button>
    </div>
    <b-card title="User Emails">
      <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
      </b-dropdown>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_length" class="dataTables_length">
            <label class="d-inline-flex align-items-center">
              Show&nbsp;
              <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
            </label>
          </div>
        </div>
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
          <div id="tickets-table_filter" class="dataTables_filter text-md-right">
            <label class="d-inline-flex align-items-center">
              Search:
              <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2"></b-form-input>
            </label>
          </div>
        </div>
        <!-- End search -->
      </div>
      <div class="table-responsive">
        <b-table :items="emails" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
          :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
          @filtered="onFiltered">
          <template v-slot:cell(created_at)="row">
            {{  $moment(row.item.created_at).tz('Europe/London').format("DD MMM, YYYY") }}
          </template>
        </b-table>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mailModal: false,
      loading: false,
      FILE: null,
      emails: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      sortDesc: false,
      fields: [
        { key: "created_at", sortable: true, label: "Date" },
        { key: "email", label: "Email" },
        { key: "is_active", label: "Active" },
      ],
    };
  },
  mounted() {
    this.getEmails();
  },
  methods: {
    getEmails() {
      this.$store.getters.client.get("/newsletter/").then((response) => {
        this.emails = response.data.results;
      });
    },
  },
};
</script>
