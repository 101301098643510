import Categories from "./index";
import CreateCategories from "./create-categories";
import CategoryFaq from "./category-faq"
import PromotionalCategories from "./promotional"
import AddPromotionalCategories from "./add-promotional.vue"
import EditPromotionalCategories from "./edit-promotional.vue"
// import middlewares
export default [
  {
    path: "/categories",
    component: Categories,
    name: "Categories",
  },
  {
    path: "/create-categories",
    component: CreateCategories,
    name: "CreateCategories",
  },
  {
    path: "/edit-categories/:id",
    component: CreateCategories,
    name: "CreateCategories",
  },
  {
    path: "/category/:id/faq",
    component: CategoryFaq,
    name: "Category Faq",
  },
  {
    path: "/promotional-categories",
    component: PromotionalCategories,
    name: "Promotional Categories",
  },
  {
    path: "/add-promotional-category",
    component: AddPromotionalCategories,
    name: "Add Promotional Category",
  },
  {
    path: "/update-promotional-category/:id",
    component: EditPromotionalCategories,
    name: "Update Promotional Category",
  }, 
];
