<template>
    <div>
        <b-card title="Most Recent">
            <b-alert show :variant="notification.mark_as_read ? 'secondary' : 'success'" class="p-0"
                v-for="notification in notificationList" :key="notification.id">
                <div href class="text-reset notification-item">
                    <div class="media">
                        <div class="avatar-xs mr-3">
                            <span class="avatar-title bg-success rounded-circle font-size-16">
                                <i class="mdi mdi-alert-circle-outline"></i>
                            </span>
                        </div>
                        <div class="media-body">
                            <h6 class="mt-0 mb-1">{{ notification.title }}</h6>
                            <div class="font-size-12 text-muted">
                                <p class="mb-0">{{ notification.message }}</p>
                                <div v-if="notification.mark_as_read === false" role="button" class="text-success pointer" @click="markRead(notification.id)">Mark as read</div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-alert>
        </b-card>
        <b-card title="Earlier"></b-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            notificationList: [],
        };
    },
    mounted() {
        this.getNotification();
    },
    methods: {
        getNotification() {
            this.$store.getters.client.get("/order/notification/").then((response) => {
                this.notificationList = response.data.results
            });
        },
        markRead(id) {
            this.$store.getters.client.patch(`/order/notification/${id}/`, { mark_as_read: true }).then(() => {
                this.getNotification()
            })
        },
    },
}
</script>

<style></style>