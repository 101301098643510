<script>
export default {
  data() {
    return {
      isEdit: false,
      title: "",
      id: null,
      FILE: null,
      data: {
        title: "",
        image: "",
      },
    };
  },
  mounted() {
    if (this.isEdit == true) {
      this.getCategoryById();
    }
  },
  methods: {
    handleImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.ImageToWebP(file);
      }
    },
    ImageToWebP(file) {
      const FILE_NAME = file.name.split(".")
      const UPDATED_NAME = FILE_NAME[0]
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            const convertedFile = new File([blob], UPDATED_NAME + '.webp', { type: 'image/webp' });
            this.FILE = convertedFile;
          }, 'image/webp');
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    },
    getCategoryById() {
      this.$store.getters.client
        .get(`/paper-size/${this.id}`)
        .then((response) => {
          this.data = response.data;
        });
    },
    createCategory() {
      let formData = new FormData();
      if (this.FILE) {
        formData.append("image", this.FILE, this.FILE.name);
      }
      formData.append("title", this.data.title);
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store.getters.client
            .post("/paper-size/", formData)
            .then(() => {
              this.$swal({
                position: "top-end",
                icon: "success",
                title: "Category created successfully",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.push('/paper-size');
            });
        }
      });
    },

    updateCategory() {
      let formData = new FormData();
      if (this.FILE) {
        formData.append("image", this.FILE, this.FILE.name);
      }
      formData.append("title", this.data.title);
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store.getters.client
            .put("/paper-size/" + this.$route.params.id + "/", formData)
            .then(() => {
              this.$swal({
                position: "top-end",
                icon: "success",
                title: "Category updated successfully",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.push('/paper-size');
            });
        }
      });
    },
  },
  created() {
    if ("id" in this.$route.params) {
      this.isEdit = true;
      this.id = this.$route.params.id;
      this.title = "Edit Paper Size";
    } else {
      this.title = "Add Paper Size";
    }
  },
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <b-card title="Media">
        <b-row>
          <b-col cols="4">
            <div class="border-img">
              <label for="">Image</label> <br />
              <input type="file" v-on:change="handleImageChange($event)" />
            </div>
            <div v-if="data.image" class="mt-3 d-inline-block position-relative">
              <img :src="data.image" alt="" height="65px" />
            </div>
          </b-col>
        </b-row>
      </b-card>
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">{{ formTitle }}</h4> -->
          <div class="row">
            <div class="col-12">
              <form class="form-horizontal" role="form">
                <b-form-group
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label="Paper Name"
                >
                  <b-form-input
                    for="text"
                    v-model="data.title"
                  ></b-form-input>
                </b-form-group>
                <div class="text-right mt-3">
                  <button
                    v-if="!isEdit"
                    @click="createCategory"
                    type="button"
                    class="btn btn-primary"
                  >
                    Submit
                  </button>
                  <button
                    v-if="isEdit"
                    @click="updateCategory"
                    type="button"
                    class="btn btn-primary"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
</template>
