<script>
import Multiselect from "vue-multiselect";
export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            studioData: null,
            allCategories: [],
            categoryTotalPage: 1,
            categoryCurrentPage: 0,
            selectedCategories: null,
        };
    },
    mounted() {
        this.getResellerData();
        this.getCategory();
    },
    methods: {
        getResellerData() {
            let loader = this.$loading.show({
                loader: "dots",
            });
            this.$store.getters.client.get(`/user/print-reseller/${this.$route.params.id}`).then((response) => {
                this.studioData = response.data.results;
                loader.hide();
            });
        },
        getCategory() {
            this.$store.getters.client.get(`/categories/all/?page=${this.categoryCurrentPage + 1}`).then((response) => {
                let resultData = response.data;
                let newData = resultData.results.map(category => {
                    return { id: category.id, name: category.name, discount: 0 }
                })
                this.allCategories = this.allCategories.concat(newData);
                this.categoryTotalPage = resultData.total_pages;
                this.categoryCurrentPage = resultData.current_page;
            });
        },
        reachedEndOfCategory(reached) {
            if (reached && this.categoryCurrentPage < this.categoryTotalPage) {
                this.getCategory();
            }
        },
        ApplyDiscount() {
            const data = this.selectedCategories.map((item) => {
                return {
                    categories: item.id,
                    discount: item.discount
                }
            })
            this.$store.getters.client.patch(`/user/print-reseller/${this.$route.params.id}/`, {discount_details: data}).then((response) => {
                console.log(response)
                this.getResellerData();
            });
        }
    },
};
</script>

<template>
    <b-card>
        <b-row>
            <b-col cols="6">
                <b-form-group id="category" label="Select Category" label-for="category">
                    <multiselect track-by="id" :multiple="true" :hide-selected="true" label="name"
                        name="category" v-validate="'required'" :options="allCategories"
                        v-model="selectedCategories">
                        <template slot="afterList">
                            <div v-observe-visibility="reachedEndOfCategory" />
                        </template>
                    </multiselect>
                </b-form-group>
            </b-col>
            <b-col>
                <b-card title="Selected Category">
                    <div v-for="item in selectedCategories" :key="item.id" class="row g-4 align-items-center mt-3">
                        <div class="col">{{ item.name }}</div>
                        <b-form-input class="col" type="number" v-model="item.discount"></b-form-input>
                    </div>
                </b-card>
                <b-button @click="ApplyDiscount()" variant="success">Add Discount</b-button>
            </b-col>
        </b-row>
    </b-card>
</template>
