<script>
// import CKEditor from "@ckeditor/ckeditor5-vue"; 
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";
export default {
    data() {
        return {
            isEdit: false,
            edit: false,
            id: null,
            faqModal: false,
            data: {
                is_active: true,
                question: "",
                answer: "",
                category_id: null,
            },
            fields: [
                { key: "question", label: "Question", thStyle: { width: "35%" } },
                { key: "answer", label: "Answer", thStyle: { width: "35%" } },
                { key: "is_active", label: "Status" },
                { key: "action" },
            ],
            faqs: [],
            selectedFaq: {},
        };
    },
    mounted() {
        this.getCategoryDetails();
    },
    methods: {
        getCategoryDetails() {
            this.$store.getters.client
                .get(`categories/tree/${this.id}`)
                .then((res) => {
                    let productData = res.data.results;
                    this.faqs = productData.faq;
                    this.data.category_id = productData.id
                });
        },
        async AddFaq() {
            await this.$validator.validateAll().then((result) => {
                if (result) {
                    if (this.edit == false) {
                        this.$store.getters.client
                            .post("/catalog-product/faq/", this.data)
                            .then((response) => {
                                this.faqs.push(response.data);
                            });
                    } else {
                        this.$store.getters.client
                            .patch(`/catalog-product/faq/${this.selectedFaq.id}/`, this.data)
                            .then(() => {
                                this.getCategoryDetails();
                            });
                    }
                    this.data.is_active = true;
                    this.data.question = "";
                    this.data.answer = "";
                }
            });
        },
        changeStatus(item) {
            this.$store.getters.client.patch(`/catalog-product/faq/${item.id}/`, {
                is_active: item.is_active,
            });
        },
        editFaq(item) {
            this.edit = true;
            this.faqModal = true;
            this.selectedFaq = item;
            this.data = item;
        },
        async confirm(row) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    this.$store.getters.client
                        .delete(`/catalog-product/faq//${row}`)
                        .then(() => {
                            this.faqs = this.faqs.filter((x) => {
                                return x.id != row;
                            });
                        });
                }
            });
        },
    },
    created() {
        if ("id" in this.$route.params) {
            this.id = this.$route.params.id;
        }
    },
};
</script>
<template>
    <div class="row">
        <b-modal @ok="AddFaq()" v-model="faqModal" size="lg" :title="edit ? 'Edit FAQ' : 'Add FAQ'"
            title-class="font-18">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <form class="form-horizontal" role="form">
                                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Question"
                                    label-for="text">
                                    <b-form-textarea rows="5" v-model="data.question" v-validate="'required'"
                                            name="question"></b-form-textarea>
                                    <p class="text-danger text-sm" v-show="errors.has('question')">
                                        * {{ errors.first("question") }}
                                    </p>
                                </b-form-group>
                                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Answer"
                                    label-for="text">
                                    <b-form-textarea rows="10" v-model="data.answer" v-validate="'required'"
                                            name="answer"></b-form-textarea>
                                    <p class="text-danger text-sm" v-show="errors.has('answer')">
                                        * {{ errors.first("answer") }}
                                    </p>
                                </b-form-group>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title">FAQs</h4>
                    <div class="text-center mt-4">
                        <button @click="faqModal = true" type="button" class="btn btn-primary float-right mb-3">
                            Add FAQ
                        </button>
                    </div>
                    <div class="table-responsive">
                        <b-table class="table-centered product-table" :items="faqs" :fields="fields" responsive="sm">
                            <template v-slot:cell(answer)="row">
                                <p v-html="row.item.answer"></p>
                            </template>
                            <template v-slot:cell(question)="row">
                                <p v-html="row.item.question"></p>
                            </template>
                            <template v-slot:cell(is_active)="row">
                                <div class="d-flex align-items-center">
                                    <b-form-checkbox v-model="row.item.is_active" switch
                                        @change="changeStatus(row.item)" class="mb-1">
                                        <label><span v-if="row.item.is_active">Active</span>
                                            <span v-else>Draft</span></label>
                                    </b-form-checkbox>
                                </div>
                            </template>

                            <template v-slot:cell(action)="row">
                                <div class="d-flex align-items-center">
                                    <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover
                                        title="Edit">
                                        <i @click="editFaq(row.item)" class="mdi mdi-pencil font-size-18"></i>
                                    </a>
                                    <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">
                                        <i @click="confirm(row.item.id)" class="mdi mdi-trash-can font-size-18"></i>
                                    </a>
                                </div>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
            <!-- end card -->
        </div>
        <!-- end col -->
    </div>
</template>
