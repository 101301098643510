<template>
  <section class="position-relative">
    <div class="control-menu">
      <div class="d-flex justify-content-end align-items-end bg-white gap-3 p-2 mb-3" style="gap:1rem">
        <div><b-form-input type="number" placeholder="Express Margin" v-model="expressMargin"></b-form-input></div>
        <div><b-form-input type="number" placeholder="Standard Margin" v-model="standardMargin"></b-form-input></div>
        <div><b-button variant="success" @click="$router.go(-1)">Save & Back</b-button></div>
      </div>
    </div>
    <b-card v-for="(item, index) in varients" :key="index">
      <div class="row g-4">
        <div class="col-4 bg-light p-3">
          <table>
            <tbody>
              <tr v-for="option in item.option_values" :key="option.id">
                <th class="pr-3">{{ option.option.name }}:</th>
                <td>{{ option.value.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-8">
          <table>
            <thead>
              <tr>
                <th>Quantity</th>
                <th>Express</th>
                <th>Standard</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(price, i) in item.prices" :key="i">
                <td><b-form-input type="number" @blur="updatePrice(price)" v-model="price.quantity"></b-form-input></td>
                <td><b-form-input type="number" v-model="price.express" @blur="updatePrice(price)"></b-form-input></td>
                <td><b-form-input type="number" v-model="price.standard" @blur="updatePrice(price)"></b-form-input></td>
                <td><b-form-input type="number" @blur="updatePrice(price)" v-model="price.value"></b-form-input></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-card>
  </section>
</template>

<script>
export default {
  data() {
    return {
      varients: null,
      expressMargin: null,
      standardMargin: null,
    };
  },
  mounted() {
    this.getvariant();
  },
  methods: {
    getvariant() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .post(`variant/get-variant/`, { product_id: this.id })
        .then((response) => {
          this.varients = response.data.data.variants;
          loader.hide();
        })
        .catch((error) => {
          console.log(error);
          loader.hide();
        });
    },
    updatePrice(priceItem) {
      const data = {
        // express_price: (Number(priceItem.value)*Number(this.expressMargin)/100)+Number(priceItem.value),
        // standard_price: (Number(priceItem.value)*Number(this.standardMargin)/100)+Number(priceItem.value),
        express_price: Number(priceItem.express),
        standard_price: Number(priceItem.standard),
        value_price: Number(priceItem.value),
        quantity: Number(priceItem.quantity),
      };
      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        this.$store.getters.client.put(
          `variant/update-variant/${priceItem.id}`,
          data
        );
      } catch (error) {
        alert("price not updated");
      } finally {
        loader.hide();
      }
    },
    calculatePrice(index, i, amount){
      this.varients[index].prices[i].express = (Number(amount)*Number(this.expressMargin)/100)+Number(amount)
      this.varients[index].prices[i].standard = (Number(amount)*Number(this.standardMargin)/100)+Number(amount)
    }
  },
  created() {
    this.id = this.$route.params.id;
  },
};
</script>

<style>
.control-menu{
  position: sticky;
  top: 100px;
}
</style>