<template>
  <div>
    <b-card title="Select category">
      <b-form-select v-model="selected" :options="categories" class="mb-3" value-field="slug" text-field="name"
        @change="getProducts(selected)"></b-form-select>
    </b-card>
    <b-card title="Category List" v-if="childrenCategory !== null">
      <b-row>
        <b-col cols="3" v-for="item in childrenCategory" :key="item.id">
          <b-card :title="item.name">
            <img v-if="item.category_image"
              :src="item.category_image ? `https://api.digitalpress.co.uk/media/${item.category_image}` : ''"
              :alt="item.name" class="img-fluid" />
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Products List" v-if="products !== null">
      <b-row>
        <b-col cols="3" v-for="item in products" :key="item.id">
          <b-card>
            <h4 class="card-title d-flex align-items-center justify-content-between">
              {{ item.name }}
              <span @click="showVideoModal(item)"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                  height="24">
                  <path
                    d="M17 9.2L22.2133 5.55071C22.4395 5.39235 22.7513 5.44737 22.9096 5.6736C22.9684 5.75764 23 5.85774 23 5.96033V18.0397C23 18.3158 22.7761 18.5397 22.5 18.5397C22.3974 18.5397 22.2973 18.5081 22.2133 18.4493L17 14.8V19C17 19.5523 16.5523 20 16 20H2C1.44772 20 1 19.5523 1 19V5C1 4.44772 1.44772 4 2 4H16C16.5523 4 17 4.44772 17 5V9.2ZM5 8V10H7V8H5Z"
                    fill="rgba(225,63,110,1)"></path>
                </svg></span>
            </h4>
            <img v-if="item.product_images.length > 0"
              :src="item.product_images ? `https://api.digitalpress.co.uk${item.product_images[0].image}` : ''"
              :alt="item.name" class="img-fluid" />
            <b-input-group class="mt-3">
              <b-input-group-prepend>
                <b-button variant="danger" disabled>{{ item.display_order }}</b-button>
              </b-input-group-prepend>

              <b-form-input type="number" min="0.00" @change="changeOrder(item)"
                v-model="item.display_order"></b-form-input>
            </b-input-group>
            <div class="form-group">
              <label>Category<span class="text-danger">*</span></label>
              <multiselect name="category" v-model="item.category" :options="categories" label="name" track-by="id">
              </multiselect>
              <b-button @click="changeCategory(item)" variant="primary" size="sm" class="w-100">update</b-button>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

    <h4 v-else class="text-center my-5">Please select a Category</h4>
    <b-modal id="modal-prevent-closing" ref="modal" title="Add video" v-model="videoModal" @ok="addVideo('item')">
      <form>
        <b-form-group label="Video title" label-for="title">
          <b-form-input id="title" v-model="formData.video_title" required></b-form-input>
        </b-form-group>
        <b-form-group label="Video URL" label-for="url">
          <b-form-textarea id="url" placeholder="Add video URL" v-model="formData.video_url" rows="3"
            no-resize></b-form-textarea>
        </b-form-group>
        <b-form-group label="Description" label-for="text">
          <div class="custom-file">
            <ckeditor class="border rounded" v-model="formData.video_description" name="description" :editor="editor">
            </ckeditor>
          </div>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";
export default {
  components: { Multiselect, ckeditor: CKEditor.component, },
  data() {
    return {
      id: null,
      orderNumber: null,
      selected: null,
      categories: [],
      products: null,
      childrenCategory: null,
      formData: {
        video_url: '',
        video_description: '',
        video_title: ''
      },
      videoModal: false,
      editor: ClassicEditor,
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    getCategories() {
      this.$store.getters.client.get(`/categories/home`).then((response) => {
        this.categories = response.data.a_to_z_category;
      });
    },
    getProducts(item) {
      this.$store.getters.client
        .get(`categories/tree/${item}/`)
        .then((response) => {
          this.childrenCategory = response.data.results.children
          this.products = response.data.results.product_data;
          this.products.sort((a, b) => a.display_order - b.display_order)
        })
        .catch(() => { });
    },
    changeOrder(item) {
      this.$store.getters.client.patch(`/catalog-product/products/${item.id}/`, { display_order: item.display_order })
    },
    async changeCategory(item) {
      this.$store.getters.client.patch(`/catalog-product/products/${item.id}/`, { category: item.category.id })
    },
    showVideoModal(item) {
      this.videoModal = true;
      this.id = item.id;
      this.formData.video_url = item.video_url;
      this.formData.video_description = item.video_description;
      this.formData.video_title = item.video_title;
    },
    addVideo() {
      this.$store.getters.client.patch(`/catalog-product/products/${this.id}/`, this.formData)
    }
  },
};
</script>

<style></style>
