<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      allCategories: [],
      categoryTotalPage: 1,
      categoryCurrentPage: 0,
      selectedCategories: null,
      allProducts: [],
      selectedProducts: [],
      allUsers:[],
      selectedUsers:[],
      data: {
        code: "",
        discount_percentage: 5,
        valid_from: '',
        valid_to: "",
        users:null,
      },
    };
  },
  mounted() {
    this.getCategory();
    this.getUSers()
  },
  methods: {
    async getUSers(){
      this.$store.getters.client.get(`/order/quotation-customer/`).then((response) => {
        this.allUsers = response.data.results
      });
    },
    getCategory() {
      this.$store.getters.client.get(`/categories/all/?page=${this.categoryCurrentPage + 1}`).then((response) => {
        let resultData = response.data;
        let newData = resultData.results.map(category => {
          return { id: category.id, name: category.name, slug: category.slug }
        })
        this.allCategories = this.allCategories.concat(newData);
        this.categoryTotalPage = resultData.total_pages;
        this.categoryCurrentPage = resultData.current_page;
      });
    },
    reachedEndOfCategory(reached) {
      if (reached && this.categoryCurrentPage < this.categoryTotalPage) {
        this.getCategory();
      }
    },
    fetchProducts() {
      let categoryIds = this.selectedCategories.map(id => id.slug)
      this.allProducts = []
      for (let index = 0; index < categoryIds.length; index++) {
        // const element = array[index];
        this.$store.getters.client
          .get(`categories/tree/${categoryIds[index]}/`)
          .then((response) => {
            let products = response.data.results.product_data
            products.map(item => {
              return { id: item.id, name: item.name }
            })
            this.allProducts = this.allProducts.concat(products);
          })
      }
    },

    createCoupon() {
      if(this.selectedProducts !== null && this.selectedProducts.length > 0){
        this.data['products'] = this.selectedProducts.map(id => id.id)
      }
      this.data.users = this.selectedUsers.id
      this.$store.getters.client
        .post("/order/coupon/", this.data)
        .then(() => {
          this.$swal({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push({ name: "Discount" });
        });
    },
  },
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">{{ formTitle }}</h4> -->
          <div class="row">
            <div class="col-12">
              <form class="form-horizontal" role="form">
                <b-row>
                  <b-col cols="6">
                    <b-form-group id="code" label="Coupon Code" label-for="code">
                      <b-form-input for="code" v-model="data.code" name="code"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group id="discount_percentage" label="Discount(in %)" label-for="discount_percentage">
                      <b-form-input type="number" min="1" max="20" for="discount_percentage"
                        v-model="data.discount_percentage" name="discount_percentage"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Valid From">
                      <b-form-datepicker today-button reset-button close-button id="valid_from" v-model="data.valid_from"
                        class="mb-2" :min="new Date()"></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Valid To">
                      <b-form-datepicker today-button reset-button close-button id="valid_to" v-model="data.valid_to"
                        class="mb-2" :min="data.valid_from"></b-form-datepicker>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group id="product" label="Select Products" label-for="product">
                      <multiselect track-by="id" :hide-selected="true" label="name" value="id"
                        name="product" v-validate="'required'" :options="allUsers" v-model="selectedUsers">
                      </multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <!-- {{ selectedCategories }} -->
                    <b-row align-content="center" align-v="center">
                      <b-col cols="9">
                        <b-form-group id="category" label="Select Category" label-for="category">
                          <multiselect track-by="id" :multiple="true" :hide-selected="true" label="name" name="category"
                            v-validate="'required'" :options="allCategories" v-model="selectedCategories">
                            <template slot="afterList">
                              <div v-observe-visibility="reachedEndOfCategory" />
                            </template>
                          </multiselect>
                        </b-form-group>
                      </b-col>
                      <b-col cols="3"><b-button @click="fetchProducts()" variant="success"
                          :disabled="selectedCategories == null || selectedCategories.length == 0">Get
                          Products</b-button></b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group id="product" label="Select Products" label-for="product">
                      <multiselect track-by="id" :multiple="true" :hide-selected="true" label="name" value="id"
                        name="product" v-validate="'required'" :options="allProducts" v-model="selectedProducts">
                      </multiselect>
                    </b-form-group>
                  </b-col>
                </b-row>
                <div class="text-right mt-3">
                  <button @click="createCoupon" type="button" class="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
</template>
