<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      allCategories: [],
      categoryTotalPage: 1,
      categoryCurrentPage: 0,
      selectedCategories: null,
      allProducts: [],
      selectedProducts: [],
      data: {
        product: null,
        company_name: "",
        first_name: "",
        last_name: "",
        primary_email: "",
        secondry_email: '',
        password: "",
        phone: "",
        approved: true,
        gender: "",
        date_of_birth: "",
        type: "supplier",
        descriptions: null,
        address_details: {
          address1: "",
          address2: "",
          zip: "",
          city: "",
          country: "",
          province: "",
          province_code: "test code",
          country_code: "+44"
        }
      },
    };
  },
  mounted() {
    this.getCategory()
  },
  methods: {
    getCategory() {
      this.$store.getters.client.get(`/categories/all/?page=${this.categoryCurrentPage + 1}`).then((response) => {
        let resultData = response.data;
        let newData = resultData.results.map(category => {
          return { id: category.id, name: category.name, slug: category.slug }
        })
        this.allCategories = this.allCategories.concat(newData);
        this.categoryTotalPage = resultData.total_pages;
        this.categoryCurrentPage = resultData.current_page;
        // loader.hide()
      }).catch(error => {
        // loader.hide()
        return error
      });
    },
    reachedEndOfCategory(reached) {
      if (reached && this.categoryCurrentPage < this.categoryTotalPage) {
        this.getCategory();
      }
    },
    fetchProducts() {
      let categoryIds = this.selectedCategories.map(id => id.slug)
      this.allProducts = []
      let loader = this.$loading.show({
        loader: "dots",
      });
      for (let index = 0; index < categoryIds.length; index++) {
        this.$store.getters.client
          .get(`categories/tree/${categoryIds[index]}/`)
          .then((response) => {
            let products = response.data.results.product_data
            products.map(item => {
              return { id: item.id, name: item.name }
            })
            this.allProducts = this.allProducts.concat(products);
            loader.hide()
          }).catch(error => {
            loader.hide()
            return error
          });
      }
    },

    createSupplier() {
      this.data.product = this.selectedProducts.map(x => x.id)
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client.post("/supplier/supplier/", this.data).then(() => {
        this.$swal({
          position: "top-end",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        loader.hide()
        this.$router.push({ name: "Supplier" });
      }).catch(error => {
        loader.hide()
        return error
      });
    },
  },
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <b-form @submit="createSupplier">
                <b-row>
                  <b-col cols="12">
                    <b-form-group id="example text" label="Company Name*" label-for="text">
                      <b-form-input for="text" v-model="data.company_name" name="company_name"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group id="example text" label="Description" label-for="descriptions">
                      <b-form-input for="descriptions" v-model="data.descriptions" name="descriptions"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group id="example text" label="First Name*" label-for="text">
                      <b-form-input for="text" v-model="data.first_name" name="first_name"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group id="example text" label="Last Name*" label-for="text">
                      <b-form-input for="text" v-model="data.last_name" name="last_name"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group id="example text" label="Email*" label-for="text">
                      <b-form-input for="text" type="email" v-model="data.primary_email"
                        name="primary_email"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group id="example text" label="Password*" label-for="text">
                      <b-form-input for="text" type="password" v-model="data.password" name="password"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group id="example text" label="Secondary Email" label-for="text">
                      <b-form-input for="text" type="email" v-model="data.secondry_email"
                        name="secondry_email"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group id="example text" label="Phone number*" label-for="text">
                      <b-form-input for="text" type="number" v-model="data.phone" name="phone"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group id="example text" label="Gender*" label-for="text">
                      <div class="custom-control custom-radio mb-3 mr-4 d-inline">
                        <input type="radio" id="customRadio1" name="customRadio" value="male" v-model="data.gender"
                          class="custom-control-input" />
                        <label @click="data.gender = 'Male'" class="custom-control-label" for="customRadio1">Male</label>
                      </div>
                      <div class="custom-control custom-radio d-inline">
                        <input type="radio" id="customRadio2" name="customRadio" value="female"
                          class="custom-control-input" v-model="data.gender" checked />
                        <label @click="data.gender = 'Female'" class="custom-control-label"
                          for="customRadio2">Female</label>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <div>
                      <label for="date_of_birth">Join since</label>
                      <b-form-datepicker today-button reset-button close-button id="date_of_birth"
                        v-model="data.date_of_birth" class="mb-2"></b-form-datepicker>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <b-row align-content="center" align-v="center">
                      <b-col cols="9">
                        <b-form-group id="category" label="Select Category" label-for="category">
                          <multiselect track-by="id" :multiple="true" :hide-selected="true" label="name" name="category"
                            v-validate="'required'" :options="allCategories" v-model="selectedCategories">
                            <template slot="afterList">
                              <div v-observe-visibility="reachedEndOfCategory" />
                            </template>
                          </multiselect>
                        </b-form-group>
                      </b-col>
                      <b-col cols="3">
                        <b-button class="w-100" variant="success" @click="fetchProducts"
                          :disabled="selectedCategories == null || selectedCategories.length == 0">GET PRODUCTS</b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group id="product" label="Select Products" label-for="product">
                      <multiselect track-by="id" :multiple="true" :hide-selected="true" label="name" value="id"
                        name="product" v-validate="'required'" :options="allProducts" v-model="selectedProducts">
                      </multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <div>
                      <label for="date_of_birth">Address*</label>
                      <b-row>
                        <b-col cols="12" md="4" class="mb-3">
                          <b-form-input for="text" placeholder="Line 1" v-model="data.address_details.address1"
                            name="first_name"></b-form-input>
                        </b-col>
                        <b-col cols="12" md="4" class="mb-3">
                          <b-form-input for="text" placeholder="Line 2" v-model="data.address_details.address2"
                            name="first_name"></b-form-input>
                        </b-col>
                        <b-col cols="12" md="4" class="mb-3">
                          <b-form-input for="text" placeholder="City" v-model="data.address_details.city"
                            name="first_name"></b-form-input>
                        </b-col>
                        <b-col cols="12" md="4" class="mb-3">
                          <b-form-input for="text" placeholder="Provinance" v-model="data.address_details.province"
                            name="first_name"></b-form-input>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-input for="text" placeholder="Country" v-model="data.address_details.country"
                            name="first_name"></b-form-input>
                        </b-col>
                        <b-col cols="12" md="4">
                          <b-form-input for="text" placeholder="Pincode" v-model="data.address_details.zip"
                            name="first_name"></b-form-input>
                        </b-col>
                        <b-col cols="12">
                          <div class="form-check mb-3">
                            <input class="form-check-input" type="checkbox" value id="defaultCheck1"
                              v-model="data.approved" />
                            <label class="form-check-label" for="defaultCheck1">Approved</label>
                          </div>
                        </b-col>
                      </b-row>
                      <div class="d-flex justify-content-end" style="gap: 1rem;">
                        <b-button type="button" @click="createSupplier" variant="primary">Submit</b-button>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
</template>
