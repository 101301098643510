import Analytics from "./index";


// import middlewares
export default [
  {
    path: "/analytics",
    component: Analytics,
    name: "Analytics",
  },

];
