const Quotation = {
  state: () => ({
    quotation: ['first', 'second', 'third']
  }),
  mutations: {
  },
  getters: {
    
  },
  actions: {
  },
};
export default Quotation;
