import Reviews from "./index";
import CreateReviews from "./add-review";

// import middlewares
export default [
  {
    path: "/reviews",
    component: Reviews,
    name: "Reviews",
  },
  {
    path: "/add-review",
    component: CreateReviews,
    name: "CreateReviews",
  },
  {
    path: "/update-review/:id",
    component: CreateReviews,
    name: "UpdateReviews",
  },
];
