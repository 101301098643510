<script>
export default {
    data() {
        return {
            supplierData: [],
            isEdit: false,
            formTitle: "",
            id: null,
            data: {
                first_name: "",
                last_name: "",
                phone: "",
                email: "",
                password: ""
            },
        };
    },
    methods: {
        async createSeller() {
            await this.$store.getters.client
                .post("/supplier/sales/", this.data)
                .then(() => {
                    this.$swal({
                        position: "top-end",
                        icon: "success",
                        title: "Your work has been saved",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    this.$router.push({ name: "Seller" });
                });
        },
    },
    created() {
        if ("id" in this.$route.params) {
            this.isEdit = true;
            this.id = this.$route.params.id;
        }
    },
};
</script>
<template>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <form class="form-horizontal" role="form">
                                <b-row>
                                    <b-col cols="6">
                                        <b-form-group id="example text" label="First Name" label-for="text">
                                            <b-form-input for="text" v-model="data.first_name"
                                                name="first_name"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-group id="example text" label="Last Name" label-for="text">
                                            <b-form-input for="text" v-model="data.last_name"
                                                name="last_name"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-group id="example text" label="Email" label-for="text">
                                            <b-form-input for="text" v-model="data.email" name="email"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-group id="example text" label="Phone number" label-for="text">
                                            <b-form-input for="text" v-model="data.phone" name="phone"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-form-group id="example text" label="Password" label-for="text">
                                            <b-form-input type="password" for="password" v-model="data.password"
                                                name="password"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <div class="text-right mt-3">
                                    <button @click="createSeller" type="button" class="btn btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end card -->
        </div>
        <!-- end col -->
    </div>
</template>
