import Seller from "./index";
import ManageSeller from "./add-seller.vue";

// import middlewares
export default [
  {
    path: "/seller",
    component: Seller,
    name: "Seller",
  },
  {
    path: "/add-seller/",
    component: ManageSeller,
    name: "Create sales Rep",
  },
  {
    path: "/edit-seller/:id",
    component: ManageSeller,
    name: "editSeller",
  }
];
