import Discount from "./index";
import CreateDiscount from "./add-discount";
import UpdateCoupon from './update-coupon'
// import middlewares
export default [
  {
    path: "/discount",
    component: Discount,
    name: "Discount",
  },
  {
    path: "/add-discount",
    component: CreateDiscount,
    name: "Create Coupon",
  },
  {
    path: "/edit-discount/:id",
    component: UpdateCoupon,
    name: "Update Coupon",
  },
];
