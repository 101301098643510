import Customers from "./index";
import NewsLetter from "./newsletter.vue";
import MarketingEmails from "./marketing-emails/index.vue";
import MarketingEmailsDetails from "./marketing-emails/_id.vue";

export default [
  {
    path: "/customers",
    component: Customers,
    name: "Customers",
  },
  {
    path: "/news-letter",
    component: NewsLetter,
    name: "NewsLetter",
  },
  {
    path: "/marketing-emails",
    component: MarketingEmails,
    name: "Marketing emails",
  },
  {
    path: "/marketing-emails/:id",
    component: MarketingEmailsDetails,
    name: "Marketing email details",
  },
];
