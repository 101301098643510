import Notifications from "./index";

// import middlewares
export default [
  {
    path: "/notifications",
    component: Notifications,
    name: "Notifications",
  },
];
