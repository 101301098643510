<script>
import Multiselect from "vue-multiselect";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import Swal from "sweetalert2";
export default {
  components: {
    ckeditor: CKEditor.component,
    Multiselect,
  },
  data() {
    return {
      editorConfig: {
        heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
            ]
        }
      },
      productTypeTotalPage: null,
      productTypeCurrentPage: 0,
      categoryTotalPage: null,
      categoryCurrentPage: 0,
      categoryData: [],
      isEdit: false,
      id: null,
      data: {
        name: "",
        customise_title: "",
        description: "",
        short_name: "",
        sku: "",
        short_description: "",
        product_specs: "",
        submission_specs: "",
        feature_image: "",
        feature_img_thumb: "",
        slug: "",
        print_area: "",
        is_active: false,
        is_premium: false,
        is_featured: false,
        product_type: null,
        category: null,
        handle: "",
        pageTitle: "",
        careDetail: "",
        searchEngineDescription: "",
        is_vat_applicable: false,
        weight: null,
        lowest_price: null,
        is_most_popular: false,
        page_title: '',
        meta_keywords: '',
        engine_description: '',
        url_handle: '',
      },
      editor: ClassicEditor,
      product_id: "",
    };
  },
  mounted() {
    if (this.isEdit == true) {
      this.getProductDetail();
    }
  },
  methods: {

    getCategory() {
      this.$store.getters.client.get(`/categories/all/?page=${this.categoryCurrentPage + 1}`).then((response) => {
        let resultData = response.data;
        this.categoryData = this.categoryData.concat(resultData.results);
        this.categoryTotalPage = resultData.total_pages;
        this.categoryCurrentPage = resultData.current_page;
      });
    },

    reachedEndOfCategory(reached) {
      if (reached && this.categoryCurrentPage < this.categoryTotalPage) {
        this.getCategory();
      }
    },

    async submitDetails() {
      await this.$validator.validateAll().then((result) => {
        if (result) {
          let loader = this.$loading.show({
            loader: "dots",
          });
          let categoryID = []
          this.data.category.forEach((category) => {
            categoryID.push(category.id)
          })
          // let categoryId = this.data.category.id;
          this.data.category = categoryID;
          this.data.product_type = null;
          if (this.isEdit == true) {
            this.$store.getters.client
              .patch("/catalog-product/products/" + this.id + "/", this.data)
              .then(() => {
                this.$emit("updatePage", 1);
                loader.hide();
              }).catch((err) => {
                loader.hide();
                alert(err)
              });
          } else {
            this.$store.getters.client
              .post("/catalog-product/products/", this.data)
              .then((response) => {
                let product_id = response.data.id;
                localStorage.setItem("product_id", product_id);
                this.$emit("updatePage", 1);
                // this.$router.push('/products');
                loader.hide();
              }).catch((err) => {
                loader.hide();
                alert(err)
              });
          }
        }
      });
    },
    getProductDetail() {
      let loader = this.$loading.show({
        loader: "dots",
      });

      this.$store.getters.client
        .get("/catalog-product/products/" + this.id)
        .then((response) => {
          this.data = response.data;
          loader.hide();
        });
    },
  },

  created() {
    this.getCategory();
    if ("id" in this.$route.params) {
      this.isEdit = true;
      this.id = this.$route.params.id;
    }
  },
};
</script>
<template>
  <b-card>
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Product Details</h4>
          <div class="row">
            <div class="col-12">
              <form class="form-horizontal" role="form">
                <div class="form-group">
                  <label>Product Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" id="" for="pname" name="product_name" v-validate="'required'"
                    v-model="data.name" />
                  <p class="text-danger text-sm" v-show="errors.has('product_name')">
                    * {{ errors.first("product_name") }}
                  </p>
                </div>
                <div class="form-group">
                  <label>Customise title</label>
                  <input type="text" class="form-control" id="" for="customise_title" name="customise_title"
                    v-model="data.customise_title" />
                </div>
                <div class="form-group">
                  <label>Short Name<span class="text-danger">*</span></label>
                  <input type="text" disabled class="form-control" id="exampleInputEmail1" for="sname" name="short_name"
                    v-validate="'required'" v-model="data.short_name" />
                  <p class="text-danger text-sm" v-show="errors.has('short_name')">
                    * {{ errors.first("short_name") }}
                  </p>
                </div>
                <div class="form-group">
                  <label>Slug</label>
                  <input type="text" class="form-control" id="" for="text" name="slug" v-model="data.slug" />
                </div>

                <div class="form-group">
                  <label>SKU Number<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" id="" for="snum" name="sku" v-validate="'required'"
                    v-model="data.sku" />
                  <p class="text-danger text-sm" v-show="errors.has('sku')">
                    * {{ errors.first("sku") }}
                  </p>
                </div>
                <div class="form-group">
                  <label>Category<span class="text-danger">*</span></label>

                  <multiselect name="category" disabled v-model="data.category" :options="categoryData" :multiple="true"
                    label="name" track-by="id" :hide-selected="true" v-validate="'required'">
                    <template slot="afterList">
                      <div v-observe-visibility="reachedEndOfCategory" />
                    </template>
                  </multiselect>
                </div>
                <b-row>
                  <b-col cols="6">
                    <div class="form-group">
                      <label>Product weight (IN KG)</label>
                      <input type="number" disabled class="form-control" placeholder="0.00" v-model="data.weight" />
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div class="form-group">
                      <label>Lowest Price</label>
                      <input type="number" disabled class="form-control" placeholder="0.00" v-model="data.lowest_price" />
                    </div>
                  </b-col>
                </b-row>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Featured Product</h4>
          <div class="form-group form-check">
            <input disabled type="checkbox" class="form-check-input" id="exampleCheck1" v-model="data.is_active" />
            <label class="form-check-label" for="exampleCheck1">Is Active</label>
          </div>
          <div class="form-group form-check">
            <input disabled type="checkbox" class="form-check-input" id="exampleCheck2" v-model="data.is_featured" />
            <label class="form-check-label" for="exampleCheck2">Is Featured</label>
          </div>
          <div class="form-group form-check">
            <input disabled type="checkbox" class="form-check-input" id="exampleCheck2" v-model="data.is_most_popular" />
            <label class="form-check-label" for="exampleCheck2">Most Popular</label>
          </div>
          <div class="form-group form-check">
            <input disabled type="checkbox" class="form-check-input" id="exampleCheck3" v-model="data.is_premium" />
            <label class="form-check-label" for="exampleCheck3">Is Premium</label>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">VAT</h4>

          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="vatcheck" disabled v-model="data.is_vat_applicable" />
            <label class="form-check-label" for="vatcheck">Is VAT APPLICABLE</label>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">
            Product Description | Overview<span class="text-danger">*</span>
          </h4>
          <div class="row">
            <div class="col-12">
              <form class="form-horizontal" role="form">
                <ckeditor class="border rounded" name="pdescription" :config="editorConfig" :editor="editor" v-validate="'required'"
                  v-model="data.description"></ckeditor>
                <p class="text-danger text-sm" v-show="errors.has('pdescription')">
                  * {{ errors.first("pdescription") }}
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">
            Product Short Description<span class="text-danger">*</span>
          </h4>
          <div class="row">
            <div class="col-12">
              <form class="form-horizontal" role="form">
                <div class="custom-file">
                  <ckeditor class="border rounded" name="psdescription" :config="editorConfig" :editor="editor" v-validate="'required'"
                    v-model="data.short_description"></ckeditor>
                  <p class="text-danger text-sm" v-show="errors.has('psdescription')">
                    * {{ errors.first("psdescription") }}
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">
            Product Specs | Description<span class="text-danger">*</span>
          </h4>
          <div class="row">
            <div class="col-12">
              <form class="form-horizontal" role="form">
                <ckeditor class="border rounded" name="pdescription" :config="editorConfig" :editor="editor" v-validate="'required'"
                  v-model="data.product_specs"></ckeditor>
                <p class="text-danger text-sm" v-show="errors.has('pdescription')">
                  * {{ errors.first("pdescription") }}
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">
            Technical specs<span class="text-danger">*</span>
          </h4>
          <div class="row">
            <div class="col-12">
              <form class="form-horizontal" role="form">
                <ckeditor class="border rounded" name="pdescription" :config="editorConfig" :editor="editor" v-validate="'required'"
                  v-model="data.submission_specs"></ckeditor>
                <p class="text-danger text-sm" v-show="errors.has('pdescription')">
                  * {{ errors.first("pdescription") }}
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Search Engine Optimization</h4>
          <div class="row">
            <div class="col-12">
              <form class="form-horizontal" role="form">
                <div class="form-group">
                  <label>Page Title</label>
                  <input type="text" class="form-control" id="" for="text" name="name" v-model="data.page_title" />
                </div>
                <div class="mb-3">
                  <label>Description</label>
                  <textarea class="w-100 form-control" rows="5" v-model="data.engine_description"></textarea>
                </div>

                <div class="form-group">
                  <label>Url and handle</label>
                  <input type="text" class="form-control" id="" for="text" name="name" v-model="data.url_handle" />
                </div>
                <div class="form-group">
                  <label>Meta keywords</label>
                  <input type="text" class="form-control" id="exampleInputEmail1" for="text" name="name"
                    v-model="data.meta_keywords" />
                </div>
                <div class="text-center mt-4">
                  <button @click="submitDetails" type="button" class="btn btn-primary float-right">
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>
