<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      this_is_final: {},
      combinations: [],
      attributeData: [],
      attributeValues: [],
      isEdit: false,
      attribute_value: null,
      attribute_id: null,
      associatedvarients: null,
      id: null,
      selectedAttributeId: null,
      productAttributeData: [],
    };
  },

  mounted() {
    this.getvariant();
  },

  methods: {
    async arrOrder() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      const OrderData = await this.productAttributeData.map((item) => {
        return { attribute_id: item.attribute_id, sequence: item.sequence };
      });
      const data = [{ product_id: this.id }, ...OrderData];
      this.$store.getters.client
        .post(`variant/attribute-sequence/`, data)
        .then(() => {
          this.$router.push('/products')
          loader.hide();
        })
        .catch((error) => {
          console.log(error);
            loader.hide();
        });
    },
    getvariant() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .post(`variant/get-variant-sorted/`, { product_id: this.id })
        .then((response) => {
          this.this_is_final = response.data.data;
          this.productAttributeData = this.this_is_final.AttributeValueSorted;
          this.productAttributeData = this.productAttributeData.map((x) => {
            return {
              ...x,
              all_attribute_values: x.attribute_values,
              sequence: 0,
            };
          });
          // this.setAssociatedAttribute();
          loader.hide();
        })
        .catch((error) => {
          console.log(error);
          loader.hide();
        });
    },
    async generateCombination(attr) {
      if (attr.attribute_values.length === attr.all_attribute_values.length) {
        const OrderData = await attr.attribute_values.map((item, index) => {
          return { attributevalue_id: item.id, sequence: index };
        });
        const data = [
          { product_id: this.id, attribute_id: attr.attribute_id },
          ...OrderData,
        ];
        let loader = this.$loading.show({
          loader: "dots",
        });
        this.$store.getters.client
          .post(`variant/attributevalue-sequence/`, data)
          .then(() => {
            loader.hide();
          })
          .catch((error) => {
            console.log(error);
            loader.hide();
          });
      }
    },
  },

  created() {
    if ("id" in this.$route.params) {
      this.isEdit = true;
      this.id = this.$route.params.id;
    }
  },
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="form-group" v-if="productAttributeData.length > 0">
            <div
              class="row"
              v-for="(item, index) of productAttributeData"
              :key="index"
            >
              <div class="col-2">
                <label>Sequence</label>
                <b-form-input v-model="item.sequence"></b-form-input>
              </div>

              <div class="col">
                <label>Attribute</label>
                <b-form-input
                  v-model="item.attribute_name"
                  disabled
                ></b-form-input>
              </div>

              <div class="col">
                <label>Attribute Value</label>

                <multiselect
                  v-validate="'required'"
                  name="category_value"
                  :value.sync="item.attribute_values"
                  :multiple="true"
                  :options="item.all_attribute_values"
                  label="name"
                  v-model="item.attribute_values"
                  track-by="id"
                  :hide-selected="true"
                  @input="generateCombination(item)"
                ></multiselect>
              </div>
            </div>
          </div>
          <button
            @click="$router.push('/products')"
            type="button"
            class="btn btn-danger float-left"
          >
            Back
          </button>
          <button
            @click="arrOrder()"
            type="button"
            class="btn btn-success float-right"
          >
            Arrange Order
          </button>
        </div>
      </div>
    </div>
  </div>
</template>