<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      id: null,
      isEdit: false,
      base_url: "https://api.digitalpress.co.uk/",
      productData: [],
      enteredNameDesign: "",
      enteredPriceDesign: "",
      designService: [],
      convertedImage: null,
      files: [],
      artworkData: {
        pdf: null,
        psd: null,
        id: null,
        zip: null,
      },
      url_3d: '',
      // enteredtypeDelivery: "",
      enteredPriceDelivery: null,
      deliverySetting: {
        product_id: null,
        name: "",
        type: "",
        days: 1,
      },
      options: [
        { value: "", text: "Please select an option" },
        { value: "standard", text: "Standard" },
        { value: "value", text: "Value" },
        { value: "express", text: "Express" },
      ],
      deliverySettingItems: [],
      template: [
        {
          name: "Hints Tips & Guide",
          type: ".hint",
        },
        {
          name: "id",
          type: ".idml",
        },
        {
          name: "pdf",
          type: ".pdf",
        },
        {
          name: "psd",
          type: ".psd",
        },
      ],
    };
  },
  mounted() {
    if (this.isEdit === true) {
      this.getProductDetails();
    }
  },
  methods: {
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.convertToWebP(file);
      }
    },
    convertAndSend() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      if (this.convertedImage) {
        const formData = new FormData();
        formData.append('image', this.convertedImage);
        formData.append("product_id", this.id);
        this.$store.getters.client
          .post("/catalog-product/product-images/", formData)
          .then(() => {
            this.getProductDetails();
            loader.hide();
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            alert(error)
            loader.hide();
          });
      } else {
        alert('Please select an image first.')
        loader.hide();
      }
    },
    convertToWebP(file) {
      const FILE_NAME = file.name.split(".")
      const UPDATED_NAME = FILE_NAME[0]
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            const convertedFile = new File([blob], UPDATED_NAME + '.webp', { type: 'image/webp' });
            this.convertedImage = convertedFile;
          }, 'image/webp');
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeDesign(id) {
      this.$store.getters.client
        .delete(`/catalog-product/design-service/${id}/`)
        .then((res) => {
          if (res.status == 204) {
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "service deleted",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getProductDetails();
          }
        });
    },
    removeDeliverySetting(id) {
      this.$store.getters.client
        .delete(`/catalog-product/delivery-settings/${id}/`)
        .then((res) => {
          if (res.status == 204) {
            Swal.fire({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "service deleted",
              showConfirmButton: false,
              timer: 1500,
            });
            this.getProductDetails();
          }
        });
    },
    async getProductDetails() {
      await this.$store.getters.client
        .get(`/catalog-product/products/${this.id}`)
        .then((res) => {
          this.productData = res.data;
          // this.deliveryMethod = this.productData.product_delivery_methods;
          this.designService = this.productData.product_design_service;
          this.deliverySettingItems = this.productData.product_delivery_settings;
          let artworks = this.productData.artworks
          this.url_3d = this.productData.url_3d
          if (artworks) {
            this.artworkData.pdf = artworks.filter((x) => { return x.type == '.pdf' })
            this.artworkData.zip = artworks.filter((x) => { return x.type == '.zip' })
            this.artworkData.id = artworks.filter((x) => { return x.type == '.idml' })
            this.artworkData.psd = artworks.filter((x) => { return x.type == '.psd' })
          }
        });
    },
    async uploadImage(event) {
      let loader = this.$loading.show({
        loader: "dots",
      });
      let FILE = event.target.files[0];
      let formData = new FormData();
      formData.append("image", FILE, FILE.name);
      formData.append("type", FILE.type);
      formData.append("product_id", this.id);
      this.$store.getters.client
        .post("/catalog-product/product-images/", formData)
        .then(() => {
          this.getProductDetails();
          loader.hide();
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          loader.hide();
        });
    },
    deleteImage(id) {
      this.$store.getters.client
        .delete(`/catalog-product/product-images/${id}`)
        .then(() => {
          this.getProductDetails();
        });
    },
    deleteFile(id) {
      this.$store.getters.client
        .delete(`/catalog-product/artworks/${id}`)
        .then(() => {
          this.getProductDetails();
        });
    },
    addDesignService() {
      if (
        this.enteredNameDesign == null ||
        this.enteredNameDesign == "" ||
        this.enteredPriceDesign == null ||
        this.enteredPriceDesign == ""
      ) {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          title: "Please enter all the fields",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        let designService = {
          name: this.enteredNameDesign,
          price: this.enteredPriceDesign,
          product_id: parseInt(this.id),
        };
        this.enteredNameDesign = "";
        this.enteredPriceDesign = "";
        this.$store.getters.client
          .post("/catalog-product/design-service/", designService)
          .then((response) => {
            if (response.status == 201) {
              Swal.fire({
                toast: true,
                position: "top-end",
                icon: "success",
                title: "Design service added",
                showConfirmButton: false,
                timer: 1500,
              });
              this.getProductDetails();
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => { });
      }
    },
    addDeliverySetting() {
      if (
        this.deliverySetting.days == null ||
        this.deliverySetting.name == ""
      ) {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "warning",
          title: "Please enter all the fields",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.$store.getters.client
          .post("/catalog-product/delivery-settings/", this.deliverySetting)
          .then((response) => {
            if (response.status == 201) {
              Swal.fire({
                toast: true,
                position: "top-end",
                icon: "success",
                title: "Design service added",
                showConfirmButton: false,
                timer: 1500,
              });
              this.deliverySetting.days = null
              this.deliverySetting.name = ""
              this.getProductDetails();
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => { });
      }
    },
    handleFilesUpload(event, fileFormat) {
      this.files.push(event.target.files);
      let loader = this.$loading.show({
        loader: "dots",
      });
      let formData = new FormData();
      let file = [];
      let TYPE = fileFormat;
      formData.append("product_id", this.id);
      formData.append("type", TYPE);
      for (var i = 0; i < this.files.length; i++) {
        let fileExtension = this.files[i][0].name.lastIndexOf(".");
        let fileName = this.files[i][0].name.slice(fileExtension + 1);
        file.push(fileName);
        formData.append("file", this.files[i][0]);
      }
      this.$store.getters.client
        .post("/catalog-product/artworks/", formData)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.getProductDetails();
          loader.hide();
        });
    },

    add_3d() {
      this.$store.getters.client.patch(`/catalog-product/products/${this.id}/`, { url_3d: this.url_3d })
    },

    nextPage() {
      this.$emit("updatePage", 2);
    },
  },

  created() {
    if ("id" in this.$route.params) {
      this.isEdit = true;
      this.id = this.$route.params.id;
      this.deliverySetting.product_id = this.id;
    } else {
      this.id = localStorage.getItem("product_id");
      this.deliverySetting.product_id = this.id;
    }
  },
};
</script>
<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Product Images</h4>
        <br />
        <div class="border-img d-flex justify-content-between align-items-center">
          <input type="file" ref="fileInput" @change="handleFileChange" accept="image/*">
          <button @click="convertAndSend" class="btn btn-primary">Upload</button>
        </div>
        <br />
        <div class="d-flex">
          <div v-for="images in productData.product_images" :key="images.id" class="mr-3 position-relative">
            <img height="104px" width="101px" :src="images.image" />
            <span class="close-icon" @click="deleteImage(images.id)"><i class="mdi mdi-close text-danger"></i></span>
          </div>
        </div>
      </div>
    </div>
    <b-card title="3D URL">
      <b-form-group id="url_3d" label-cols-sm="2" label-cols-lg="2" label="3D URL" label-for="url_3d">
        <b-form-input for="url_3d" name="url_3d" v-model="url_3d" @blur="add_3d"></b-form-input>
        <p class="text-danger text-sm" v-show="errors.has('url_3d')">
          * {{ errors.first("url_3d") }}
        </p>
      </b-form-group>
    </b-card>
    <b-card title="Artworks">
      <b-row>
        <b-col cols="12" md="6" v-for="(item, index) in template" :key="index">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="card-title text-uppercase mt-3">{{ item.name }}</h4>
          </div>
          <div class="border-img d-flex justify-content-between align-items-center">
            <input type="file" v-on:change="handleFilesUpload($event, item.type)" />
          </div>
          <div class="d-flex file-icons">
            <div v-if="item.name == 'pdf'">
              <a :href="item.file" target="_blank" rel="" v-for="item in artworkData.pdf" :key="item.id"
                class="position-relative d-inline-block mr-3">
                <i style="font-size: 2rem" class="ri-file-pdf-line" :title="item.file"></i>
                <span class="close-icon" @click="deleteFile(item.id)"><i class="mdi mdi-close text-danger"></i></span>
              </a>
            </div>
            <div v-if="item.name == 'psd'">
              <a :href="item.file" target="_blank" rel="" v-for="item in artworkData.psd" :key="item.id"
                class="position-relative d-inline-block mr-3">
                <i style="font-size: 2rem" class="ri-pixelfed-line" :title="item.file"></i>
                <span class="close-icon" @click="deleteFile(item.id)"><i class="mdi mdi-close text-danger"></i></span>
              </a>
            </div>
            <div v-if="item.name == 'id'">
              <a :href="item.file" target="_blank" rel="" v-for="item in artworkData.id" :key="item.id"
                class="position-relative d-inline-block mr-3">
                <i style="font-size: 2rem" class="ri-advertisement-line" :title="item.file"></i>
                <span class="close-icon" @click="deleteFile(item.id)"><i class="mdi mdi-close text-danger"></i></span>
              </a>
            </div>
            <div v-if="item.name == 'zip'">
              <a :href="item.file" target="_blank" rel="" v-for="item in artworkData.zip" :key="item.id"
                class="position-relative d-inline-block mr-3">
                <i style="font-size: 2rem" class="ri-folder-zip-line" :title="item.file"></i>
                <span class="close-icon" @click="deleteFile(item.id)"><i class="mdi mdi-close text-danger"></i></span>
              </a>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Delivery Settings</h4>
        <br />
        <div class="input-group mb-3">
          <b-form-select v-model="deliverySetting.name">
            <b-form-select-option value="" disabled>Please select an option</b-form-select-option>
            <b-form-select-option value="express"
              :disabled="deliverySettingItems.length != 0">Express</b-form-select-option>
            <b-form-select-option value="standard"
              :disabled="deliverySettingItems.length !== 1">Standard</b-form-select-option>
            <b-form-select-option value="value" :disabled="deliverySettingItems.length !== 2">Value</b-form-select-option>
          </b-form-select>
          <input placeholder="Days" type="number" min="1" v-model="deliverySetting.days" class="form-control" />

          <div class="input-group-append">
            <button @click="addDeliverySetting" class="btn btn-outline-primary" type="button"
              :disabled="deliverySettingItems.length == 3">
              <span>Add new Delivery Setting</span>
            </button>
          </div>
        </div>

        <p v-if="deliverySettingItems.length === 0">
          No delivery Settings have been added yet - please start adding some!
        </p>
        <ul class="list-group" v-else>
          <li class="list-group-item" v-for="item in deliverySettingItems" :key="item.id">
            <b>Name : </b>{{ item.name }} | <b>Days : </b>{{ item.days }}
            <button type="button" @click="removeDeliverySetting(item.id)" class="btn btn-outline-danger float-right">
              Remove
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Design Service</h4>
        <br />
        <div class="input-group mb-3">
          <b-form-select v-model="enteredNameDesign">
            <b-form-select-option value="" disabled>Please select an option</b-form-select-option>
            <b-form-select-option value="proofing">Proofing</b-form-select-option>
            <b-form-select-option value="design">Design</b-form-select-option>
          </b-form-select>
          <input placeholder="Price" type="number" v-model="enteredPriceDesign" class="form-control"
            aria-label="Recipient's username" aria-describedby="basic-addon2" />
          <div class="input-group-append">
            <button @click="addDesignService" class="btn btn-outline-primary" type="button">
              <span>Add new Design Service</span>
            </button>
          </div>
        </div>

        <p v-if="designService.length === 0">
          No design service have been added yet - please start adding some!
        </p>
        <ul class="list-group" v-else>
          <li class="list-group-item" v-for="design in designService" :key="design.id">
            <b>Name : </b>{{ design.name }}, <b>Price : </b>{{ design.price }}
            <button type="button" @click="removeDesign(design.id)" class="btn btn-outline-danger float-right">
              Remove
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="text-center mt-4">
      <button type="button" @click="nextPage()" class="btn btn-primary float-right">
        Next
      </button>

      <button @click="$emit('previousPage', 0)" type="button" class="btn btn-danger float-left">
        Previous
      </button>
    </div>
  </div>
</template>

<style>
.border-img {
  border: 2px dashed #ced4da;
  border-radius: 6px;
  background: #fff;
  padding: 20px;
}

.close-icon {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 61, 96, 0.2);
  cursor: pointer;
  z-index: 9999;
  transition: all 0.3s ease;
}

.file-icons .close-icon:hover {
  transform: scale(2);
  transform-origin: top right;
}
</style>
