<script>
import Swal from "sweetalert2";
/**
 * Dashboard component
 */
export default {
  data() {
    return {
      supplierData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "code", sortable: true, label: "Coupon Code" },

        { key: "valid_from", sortable: true, label: "Start" },
        { key: "valid_to", sortable: true, label: "End" },
        { key: "discount_percentage", sortable: true, label: "OFF(in %)" },
        { key: "action" },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.supplierData.length;
    },
  },
  mounted() {
    this.getDiscountData();
    // Set the initial number of items
    this.totalRows = this.supplierData.length;
  },
  methods: {
    async confirm(coupon) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          let loader = this.$loading.show({
            loader: "dots",
          });
          this.$store.getters.client
            .delete(`/order/coupon/${coupon.item.id}/`)
            .then(() => {
              if (result.isConfirmed) {
                Swal.fire("Deleted!", "Your file has been deleted.", "success");
              }
              loader.hide();
              this.getDiscountData();
            });
        }
      });
    },
    editDiscount(coupon) {
      this.$router.push({ path: `edit-discount/${coupon.item.id}` });
    },
    goToCreateDiscount() {
      this.$router.push({ name: "Create Coupon" });
    },
    getDiscountData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client.get("/order/coupon/").then((response) => {
        this.supplierData = response.data.results;
        loader.hide();
      });
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <div>
    <div class="add-button">
      <a
        href="javascript:void(0);"
        class="btn btn-primary"
        @click="goToCreateDiscount"
      >
        + Add new discount
      </a>
    </div>
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <b-dropdown
            right
            toggle-class="arrow-none card-drop"
            class="float-right"
            variant="white"
          >
          </b-dropdown>

          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table
              :items="supplierData"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(action)="row">
                <a
                  href="javascript:void(0);"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  data-toggle="tooltip"
                  title="Edit"
                  @click="editDiscount(row)"
                >
                  <i class="mdi mdi-pencil font-size-18"></i>
                </a>
                <a
                  href="javascript:void(0);"
                  class="text-danger"
                  v-b-tooltip.hover
                  title="Delete"
                >
                  <i
                    @click="confirm(row)"
                    class="mdi mdi-trash-can font-size-18"
                  ></i>
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
