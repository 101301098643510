<script>
/**
 * Sales Analytics component
 */
export default {
  data() {
    return {
      analyticData:null,
      series: [],
      chartOptions: {
        chart: {
          height: 230,
          type: "donut"
        },
        labels: ["Total", "Accepted", "Regected",],
        plotOptions: {
          pie: {
            donut: {
              size: "75%"
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        colors: ["#5664d2", "#1cbb8c", "#eeb902"]
      }
    };
  },
  mounted() {
    this.getAnalyticsData()
  },
  methods: {
    async getAnalyticsData() {
      try {
        const response = await this.$store.getters.client.get(`/order/admin-dashboard/`);
        console.log(response)
        const margin = response.data
        this.series = [margin?.total_quotation, 0 , 0]
        this.analyticData = margin;
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title mb-4">Quotation Report</h4>
      <div id="donut-chart" class="apex-charts"></div>
      <apexchart
        class="apex-charts"
        height="230"
        dir="ltr"
        :series="series"
        :options="chartOptions"
      ></apexchart>
      <div class="row">
        <div class="col-4">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-primary font-size-10 mr-1"></i>Total Quotes
            </p>
            <h5>{{ analyticData?.total_quotation }}</h5>
          </div>
        </div>
        <div class="col-4">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-success font-size-10 mr-1"></i>Pending
            </p>
            <h5>{{ analyticData?.total_quotation }}</h5>
          </div>
        </div>
        <div class="col-4">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-warning font-size-10 mr-1"></i>Accepted
            </p>
            <h5>0</h5>
          </div>
        </div>
        <div class="col-4">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-info font-size-10 mr-1"></i>Rejected
            </p>
            <h5>0</h5>
          </div>
        </div>
        <div class="col-4">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-secondary font-size-10 mr-1"></i>In Progress
            </p>
            <h5>0</h5>
          </div>
        </div>
      </div>
    </div>
  </div>

  
</template>