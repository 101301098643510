import Attributes from "./index";
import CreateAttributes from "./Add-Attribute";
// import middlewares
export default [
  {
    path: "/attributes",
    component: Attributes,
    name: "Attributes",
  },
  {
    path: "/create-attributes",
    component: CreateAttributes,
    name: "Add-Attributes",
  },
  {
    path: "/edit-attributes/:id",
    component: CreateAttributes,
    name: "Add-Attributes",
  },
];
