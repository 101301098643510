<script>
import Multiselect from "vue-multiselect";
import Client from 'getaddress-api'
import { ADDRESS_API } from "@/api/global.env.js"
import Swal from "sweetalert2";
const api = new Client(ADDRESS_API);
// console.log(api)
export default {
  data() {
    return {
      customerData: [],
      customerModal: false,
      isEdit: false,
      id: null,
      selectedCustomer: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      searchCustomer: '',
      fields: [
        { key: "company_name", label: "Company" },
        { key: "name", sortable: true, label: "Name" },
        { key: "created_at", sortable: true, label: "Join Date" },
        { key: "email", label: "Email" },
        { key: "phone", label: "Phone" },
        { key: "customer_type", label: "Customer Type" },
        { key: "action" },
      ],
      customerTypes: [
        { text: 'Enduser', value: 'enduser' },
        { text: 'B2B', value: 'b2b' },
        { text: 'Reseller', value: 'reseller' }
      ],
      paymentMethods: [
        { text: 'At Dispatch', value: 'after_dispatch' },
        { text: 'After Artwork', value: 'after_artwork' },
        { text: 'On Order', value: 'with_order' }
      ],
      data: {
        first_name: "",
        last_name: '',
        email: "",
        phone: "",
        mobile: '',
        address1: "",
        address2: "",
        zip: "",
        city: "",
        country: "",
        country_code: "+44",
        company_name: '',
        customer_type: '',
        type: 'front_user',
        payment_method: 'with_order'
      },
      address: {
        billing_first_name: null,
        billing_last_name: null,
        billing_company: null,
        billing_mobile: null,
        billing_geocode: null,
        billing_street1: null,
        billing_street2: null,
        billing_city: null,
        billing_state: null,
        billing_country: null,
        billing_pobox: null,
        billing_county: null,
        billing_postal_code: null,
      },
      selectedDeliveryAddress: null,
      addressList: null,
    };
  },
  components: { Multiselect },
  mounted() {
    this.getCustomers(1)
  },
  methods: {
    async searchUser() {
      try {
        const resp = await this.$store.getters.client.get(`/order/quotation-customer/?search=${this.searchCustomer}`)
        this.customerData = resp.data.results
        this.totalRows = resp.data.total_count;
        this.perPage = resp.data.per_page;
        this.currentPage = resp.data.current_page;
      } catch (error) {
        this.$swal({
          position: "top-end",
          icon: "error",
          title: error,
          showConfirmButton: false,
        });
      }
    },
    async getCustomers(value) {
      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        const resp = await this.$store.getters.client.get(`/order/quotation-customer/?page=${value}`)
        this.customerData = resp.data.results
        this.totalRows = resp.data.total_count;
        this.perPage = resp.data.per_page;
        this.currentPage = resp.data.current_page;
      } catch (error) {
        this.$swal({
          position: "top-end",
          icon: "error",
          title: error,
          showConfirmButton: false,
        });
        // alert(error)
      } finally {
        loader.hide()
      }
    },

    resetData() {
      this.data.first_name = "",
        this.data.last_name = "",
        this.data.customer_type = "",
        this.data.company_name = '',
        this.data.mobile = "",
        this.data.email = "",
        this.data.phone = "",
        this.data.address1 = "",
        this.data.address2 = "",
        this.data.zip = "",
        this.data.city = "",
        this.data.country = "",
        this.data.country_code = "+44",
        this.data.payment_method = 'with_order'
    },

    async createCustomers() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        const result = await this.$store.getters.client.post(`/order/quotation-customer/`, this.data)
        // console.log(result)
        if (result.status === 201) {
          await this.newBillingAddress(result.data.id)
          this.customerModal = false;
          this.resetData()
          this.getCustomers(1)
        }
      } catch (error) {
        this.$swal({
          position: "top-end",
          icon: "error",
          title: error,
          showConfirmButton: false,
        });
      } finally {
        loader.hide()
      }
    },

    async findAddress() {
      const findResult = await api.autocomplete(this.address.shipping_postal_code);
      if (findResult.isSuccess) {
        const success = findResult.toSuccess();
        this.addressList = success.suggestions
      } else {
        const failed = findResult.toFailed();
        console.log(failed);
      }
    },
    async handleAddressSelected() {
      try {
        const address = await api.get(this.selectedDeliveryAddress.id);
        this.address.billing_street1 = address.address.line_1,
          this.address.billing_street2 = address.address.line_2,
          this.address.billing_city = address.address.town_or_city,
          this.address.billing_country = address.address.country,
          this.address.billing_county = address.address.county,
          this.address.billing_postal_code = address.address.postcode
      } catch (error) {
        this.$swal({
          position: "top-end",
          icon: "error",
          title: error,
          showConfirmButton: false,
        });
      }
    },
    async newBillingAddress(userID) {
      let formData = {
        first_name: this.data.first_name,
        last_name: this.data.last_name,
        company_name: this.data.company_name,
        phone: this.data.phone,
        street1: this.address.billing_street1,
        street2: this.address.billing_street2,
        city: this.address.billing_city,
        state: this.address.billing_state,
        county: this.address.billing_county,
        postal_code: this.address.billing_postal_code,
        customer: userID,
      }
      try {
        await this.$store.getters.client.post(`/order/billing-address/`, formData)
      } catch (error) {
        this.$swal({
          position: "top-end",
          icon: "error",
          title: error,
          showConfirmButton: false,
        });
      }
    },
    editCustomer(customer) {
      this.data = customer;
      this.isEdit = true;
      this.id = customer.id
      this.customerModal = true;
    },

    async updateCustomer() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        await this.$store.getters.client.patch(`/order/quotation-customer/${this.id}/`, this.data)
        this.getCustomers(1)
        this.customerModal = false;
        this.isEdit = false;
        this.resetData()
      } catch (error) {
        this.$swal({
          position: "top-end",
          icon: "error",
          title: error,
          showConfirmButton: false,
        });
      } finally {
        loader.hide()
      }
    },
    async deleteCustomer(id){
      Swal.fire({
        title: "Delete Customer",
        text: "The user will be deleted permanently",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete!",
        // eslint-disable-next-line no-unused-vars
      }).then((result) => {
        console.log(result, id)
        if (result.isConfirmed) {
          let loader = this.$loading.show({
            loader: "dots",
          });
          try {
            this.$store.getters.client.delete(`/order/quotation-customer/${id}/`)
            this.$swal({
              position: "top-end",
              icon: "success",
              title: "Customer deleted successfully",
              showConfirmButton: false,
            });
            this.getCustomers(1)
          } catch (error) {
            this.$swal({
              position: "top-end",
              icon: "error",
              title: error,
              showConfirmButton: false,
            });
          } finally {
            loader.hide()
          }
        }
      });
    }
  },
};
</script>

<template>
  <div>
    <div class="add-button">
      <a href="javascript:void(0);" class="btn btn-primary" @click="customerModal = true">
        + Add new customer
      </a>
    </div>
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="w-25">
            <b-form-group>
              <b-form-input placeholder="Search Customer" v-model="searchCustomer" @input="searchUser"></b-form-input>
            </b-form-group>
          </div>
          <div class="table-responsive">
            <b-table :items="customerData" :fields="fields" responsive="sm">
              <template v-slot:cell(name)="row">
                <span>{{ row.item.first_name }} {{ row.item.last_name }}</span>
              </template>
              <template v-slot:cell(created_at)="row">
                <span>{{ $moment(row.item.created_at).tz('Europe/London').format('DD MMM, YYYY') }}</span>
              </template>
              <template v-slot:cell(customer_type)="row">
                <span :class="row.item.customer_type === 'b2b' ? 'text-uppercase' : 'text-capitalize'">{{
                  row.item.customer_type }}</span>
              </template>
              <template v-slot:cell(action)="row">
                <div class="d-flex" style="gap: 1rem;">
                  <a href="javascript:void(0);" class="text-primary" v-b-tooltip.hover data-toggle="tooltip" title="Edit"
                    @click="editCustomer(row.item)">
                    <i class="mdi mdi-pencil font-size-18"></i>
                  </a>
                  <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover data-toggle="tooltip" title="Delete"
                    @click="deleteCustomer(row.item.id)">
                    <i class="mdi mdi-trash-can font-size-18"></i>
                  </a>
                </div>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" :per-page="perPage" :total-rows="totalRows"
                    @change="getCustomers"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal size="md" :title="isEdit ? 'Add customer' : 'Edit Customer'" v-model="customerModal" hide-footer centered>
      <form class="form-horizontal" role="form">
        <b-row>
          <b-col cols="6">
            <b-form-group id="company" label="Company name" label-for="company">
              <b-form-input for="company" v-model="data.company_name" name="company"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="payment_method" label="Payment Type" label-for="payment_method ">
              <b-form-select for="payment_method" :options="paymentMethods" v-model="data.payment_method"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="firstName" label="First Name" label-for="firstName">
              <b-form-input for="firstName" v-model="data.first_name" name="firstName"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="lastName" label="Last Name" label-for="lastName">
              <b-form-input for="lastName" v-model="data.last_name" name="lastName"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="email" label="Email" label-for="email">
              <b-form-input for="email" v-model="data.email" name="email"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="phone" label="Phone" label-for="phone">
              <b-form-input for="phone" v-model="data.phone" name="phone"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="mobile" label="Mobile Number" label-for="mobile">
              <b-form-input for="mobile" v-model="data.mobile" name="mobile"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="customer_type " label="Customer Type" label-for="customer_type ">
              <b-form-select for="customer_type" :options="customerTypes" v-model="data.customer_type"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div>
              <label for="date_of_birth">Address</label>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Find Address">
                    <b-input-group>
                      <b-form-input v-model="address.shipping_postal_code"></b-form-input>
                      <b-input-group-append>
                        <b-button variant="outline-success" @click="findAddress()">Search</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12" v-if="addressList" class="mb-3">
                  <multiselect @select="handleAddressSelected" track-by="id" label="address" name="address"
                    :options="addressList" v-model="selectedDeliveryAddress">
                  </multiselect>
                </b-col>
                <b-col cols="12" md="4" class="mb-3">
                  <b-form-input for="text" placeholder="Line 1" v-model="address.billing_street1"
                    name="address1"></b-form-input>
                </b-col>
                <b-col cols="12" md="4" class="mb-3">
                  <b-form-input for="text" placeholder="Line 2" v-model="address.billing_street2"
                    name="address2"></b-form-input>
                </b-col>
                <b-col cols="12" md="4" class="mb-3">
                  <b-form-input for="text" placeholder="City" v-model="address.billing_city" name="city"></b-form-input>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-input for="text" placeholder="County" v-model="address.billing_county"
                    name="county"></b-form-input>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-input for="text" placeholder="Postcode" v-model="address.billing_postal_code"
                    name="postcode"></b-form-input>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </form>
      <div class="media-body">
        <hr class="my-4" />
        <div class="float-right">
          <!-- <a href="">View all orders</a> -->
          <button class="btn btn-primary ml-3" @click="isEdit ? updateCustomer() : createCustomers()">
            {{ isEdit ? 'Updated Details' : "Create Customer" }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<style>
.add-button {
  margin-bottom: 27px;
  margin-left: 14px;
}
</style>
