import paperSize from "./index";
import CreatePaper from "./add-size";
import HomePage from './home-banners'
// import middlewares
export default [
  {
    path: "/paper-size",
    component: paperSize,
    name: "paperSize",
  },
  {
    path: "/add-paper-size",
    component: CreatePaper,
    name: "CreatePaper",
  },
  {
    path: "/home-page",
    component: HomePage,
    name: "HomePage",
  },
  {
    path: "/update-paper-size/:id",
    component: CreatePaper,
    name: "CreatePaper",
  },
];
