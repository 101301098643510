<script>
// import Multiselect from "vue-multiselect";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components: {
    ckeditor: CKEditor.component,
    // Multiselect,
  },
  data() {
    return {
      editorConfig: {
        heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
            ]
        },
      },
      FILE: null,
      BANNER: null,
      data: {
        title:'',
        name: "",
        slug: "",
        background_color: "",
        short_description: "",
        description: null,
        image:null,
        banner:null,
        page_title:'',
        engine_description:'',
        meta_keywords:'',
      },
      selectedPatent:[],
      editor: ClassicEditor,
    };
  },
  methods: {
    handleImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.ImageToWebP(file);
      }
    },
    handleMobileBannerChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.mobileBannerToWebP(file);
      }
    },
    handleBannerChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.bannerToWebP(file);
      }
    },
    ImageToWebP(file) {
      const FILE_NAME = file.name.split(".")
      const UPDATED_NAME = FILE_NAME[0]
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            const convertedFile = new File([blob], UPDATED_NAME + '.webp', { type: 'image/webp' });
            this.FILE = convertedFile;
          }, 'image/webp');
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    },
    bannerToWebP(file) {
      const FILE_NAME = file.name.split(".")
      const UPDATED_NAME = FILE_NAME[0]
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            const convertedFile = new File([blob], UPDATED_NAME + '.webp', { type: 'image/webp' });
            this.BANNER = convertedFile;
          }, 'image/webp');
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    },
    uploadImage(event) {
      this.FILE = event.target.files[0];
    },
    uploadBanner(event) {
      this.BANNER = event.target.files[0];
    },
    createCategory() {
      let formData = new FormData();
      if (this.FILE) {
        formData.append("image", this.FILE, this.FILE.name);
      }
      if (this.BANNER) {
        formData.append("banner", this.BANNER, this.BANNER.name);
      }
      if(this.selectedPatent.length !== 0){
        formData.append("parent_category", '');
      }
      formData.append("description", this.data.description);
      formData.append("name", this.data.name);
      formData.append("slug", this.data.slug);
      formData.append("title", this.data.title);
      formData.append("background_color", this.data.background_color);
      formData.append("short_description", this.data.short_description);
      formData.append("meta_keywords", this.data.meta_keywords);
      formData.append("page_title", this.data.page_title);
      formData.append("engine_description", this.data.engine_description);
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store.getters.client
            .post("/midocean/promotional-category/", formData)
            .then(() => {
              this.$swal({
                position: "top-end",
                icon: "success",
                title: "Category created successfully",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.push({ name: "Promotional Categories" });
            });
        }
      });
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <b-card title="Media">
        <b-row>
          <b-col cols="4">
            <div class="border-img">
              <label for="">Image</label> <br />
              <input type="file" v-on:change="handleImageChange($event)" />
            </div>
            <div v-if="data.category_image" class="mt-3 d-inline-block position-relative">
              <img :src="data.category_image" alt="" height="65px" />
            </div>
          </b-col>
          <b-col cols="4">
            <div class="border-img">
              <label for="">Banner</label> <br />
              <input type="file" v-on:change="handleBannerChange($event)" />
            </div>
            <div v-if="data.header_image" class="mt-3">
              <img :src="data.header_image" alt="" height="65" />
            </div>
          </b-col>
        </b-row>
      </b-card>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <form class="form-horizontal" role="form">
                <b-form-group id="title" label-cols-sm="2" label-cols-lg="2" label="Category Title">
                  <b-form-input for="title" name="title"  v-validate="'required'" v-model="data.title"></b-form-input>
                  <p class="text-danger text-sm" v-show="errors.has('title')">
                    * {{ errors.first("title") }}
                  </p>
                </b-form-group>
                <b-form-group id="name" label-cols-sm="2" label-cols-lg="2" label="Category name (same as list)">
                  <b-form-input for="name" name="name" v-model="data.name" v-validate="'required'"></b-form-input>
                  <p class="text-danger text-sm" v-show="errors.has('name')">
                    * {{ errors.first("name") }}
                  </p>
                </b-form-group>
                <b-form-group id="slug" label-cols-sm="2" label-cols-lg="2" label="Slug" label-for="text">
                  <b-form-input for="text" name="slug" v-model="data.slug" v-validate="'required'"></b-form-input>
                  <p class="text-danger text-sm" v-show="errors.has('slug')">
                    * {{ errors.first("slug") }}
                  </p>
                </b-form-group>
                <b-form-group id="short_description" label-cols-sm="2" label-cols-lg="2" label="Short Description"
                  label-for="short_description">
                  <div class="custom-file">
                    <ckeditor class="border rounded"  :config="editorConfig" v-model="data.short_description" name="short_description" :editor="editor">
                    </ckeditor>
                  </div>
                </b-form-group>
                <b-form-group id="description" label-cols-sm="2" label-cols-lg="2" label="Description"
                  label-for="description">
                  <div class="custom-file">
                    <ckeditor class="border rounded"  :config="editorConfig" v-model="data.description" v-validate="'required'"

                      name="description" :editor="editor"></ckeditor>
                  </div>
                  <p class="text-danger text-sm" v-show="errors.has('description')">
                    * {{ errors.first("description") }}
                  </p>
                </b-form-group>
                <b-form-group id="background_color" label-cols-sm="2" label-cols-lg="2" label="Banner Background"
                  label-for="background_color">
                  <b-form-input type="color" for="background_color" name="background_color"
                    v-model="data.background_color"></b-form-input>
                </b-form-group>
              </form>
            </div>
          </div>
        </div>
      </div>
      <b-card title="Seo Details">
        <b-form-group label-cols-sm="2" label-cols-lg="2" label="Meta Title">
          <b-form-input v-model="data.page_title"></b-form-input>
        </b-form-group>
        <b-form-group label-cols-sm="2" label-cols-lg="2" label="Meta Description">
          <b-form-textarea v-model="data.engine_description"></b-form-textarea>
        </b-form-group>
        <b-form-group label-cols-sm="2" label-cols-lg="2" label="Meta Keywords" label-for="text">
          <b-form-input v-model="data.meta_keywords"></b-form-input>
        </b-form-group>
      </b-card>
      <div class="text-right mb-3">
        <button @click="createCategory" type="button" class="btn btn-primary">
          Submit
        </button>
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
</template>
