<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      isEdit: false,
      formTitle: "",
      id: null,
      FILE: null,
      BANNER: null,
      blogTypes: ['news', 'help-and-tips', 'inspiration'],
      data: {
        title: "",
        description: "",
        attachment: null,
        blog_type: '',
        background_color: '',
        seo_title: null,
        seo_keywords: null,
        seo_description: null,
        is_active: true,
        header_image: null,
        show_on_front: false,
        slug: '',
      },
      editor: ClassicEditor,
    };
  },
  mounted() {
    if (this.isEdit == true) {
      this.getBlogById();
    }
  },
  methods: {
    getBlogById() {
      this.$store.getters.client.get(`/blog/${this.id}`).then((response) => {
        this.data = response.data;
      });
    },

    uploadImage(event) {
      const FILE = event.target.files[0];
      if (FILE) {
        this.ImageToWebP(FILE);
      }
    },

    ImageToWebP(file) {
      const FILE_NAME = file.name.split(".")
      const UPDATED_NAME = FILE_NAME[0]
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            const convertedFile = new File([blob], UPDATED_NAME + '.webp', { type: 'image/webp' });
            this.FILE = convertedFile;
          }, 'image/webp');
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    },

    createBlog() {
      let formData = new FormData();
      if (this.FILE) {
        formData.append("attachment", this.FILE, this.FILE.name);
      }
      formData.append("is_active", this.data.is_active);
      formData.append("description", this.data.description);
      formData.append("title", this.data.title);
      formData.append("slug", this.data.slug);
      formData.append("show_on_front", this.data.show_on_front);
      formData.append("background_color", this.data.background_color);
      formData.append("seo_title", this.data.seo_title);
      formData.append("seo_keywords", this.data.seo_keywords);
      formData.append("seo_description", this.data.seo_description);
      formData.append("blog_type", this.data.blog_type);
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store.getters.client
            .post("/blog/", formData)
            .then(() => {
              this.$swal({
                position: "top-end",
                icon: "success",
                title: "Category created successfully",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.push({ name: "Blogs" });
            });
        }
      });
    },

    updateCategory() {
      let formData = new FormData();
      if (this.FILE) {
        formData.append("attachment", this.FILE, this.FILE.name);
      }
      formData.append("is_active", this.data.is_active);
      formData.append("description", this.data.description);
      formData.append("title", this.data.title);
      formData.append("slug", this.data.slug);
      formData.append("show_on_front", this.data.show_on_front);
      formData.append("background_color", this.data.background_color);
      formData.append("seo_title", this.data.seo_title);
      formData.append("seo_keywords", this.data.seo_keywords);
      formData.append("seo_description", this.data.seo_description);
      formData.append("blog_type", this.data.blog_type);
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store.getters.client
            .put("/blog/" + this.$route.params.id + "/", formData)
            .then(() => {
              this.$swal({
                position: "top-end",
                icon: "success",
                title: "Category updated successfully",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.push({ name: "Blogs" });
            });
        }
      });
    },
  },
  created() {
    if ("id" in this.$route.params) {
      this.isEdit = true;
      this.id = this.$route.params.id;
      this.formTitle = "Edit Document";
    } else {
      this.formTitle = "Create Document";
    }
  },
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <b-card title="Media">
        <b-row>
          <b-col cols="6">
            <div class="border-img">
              <label for="">Banner</label> <br />
              <input type="file" v-on:change="uploadImage($event)" />
            </div>
          </b-col>
          <b-col cols="6" v-if="data.attachment">
            <img :src="data.attachment" :alt="data.title" height="100" />
          </b-col>
        </b-row>
      </b-card>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <form class="form-horizontal" role="form">
                <b-form-group label-cols-sm="2" label-cols-lg="2" label="Banner heading">
                  <b-form-input for="text" name="Blog name" v-validate="'required'" v-model="data.title"></b-form-input>
                </b-form-group>
                <b-form-group label-cols-sm="2" label-cols-lg="2" label="Slug">
                  <b-form-input for="text" name="slug" v-validate="'required'" v-model="data.slug"></b-form-input>
                  <small v-if="data.slug?.length > 50"  class="text-danger">Max 50 character is allowed</small>
                </b-form-group>
                <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Description" label-for="text">
                  <div class="custom-file">
                    <ckeditor class="border rounded" v-model="data.description" v-validate="'required'" name="description"
                      :editor="editor"></ckeditor>
                  </div>
                  <p class="text-danger text-sm" v-show="errors.has('description')">
                    * {{ errors.first("description") }}
                  </p>
                </b-form-group>
                <b-form-group label-cols-sm="2" label-cols-lg="2" label="Category">
                  <b-form-select :options="blogTypes" name="type"
                    v-model="data.blog_type"></b-form-select>
                </b-form-group>
                <b-form-group label-cols-sm="2" label-cols-lg="2" label="Background Color">
                  <b-form-input type="color" name="bg_color" v-model="data.background_color"></b-form-input>
                  {{ data.background_color }}
                </b-form-group>
                <b-form-group label-cols-sm="2" label-cols-lg="2" label="List Title">
                  <b-form-input type="text" name="seo_title" v-model="data.seo_title"></b-form-input>
                </b-form-group>
                <b-form-group label-cols-sm="2" label-cols-lg="2" label="SEO Tag">
                  <b-form-input type="text" name="seo_keywords" v-model="data.seo_keywords"></b-form-input>
                </b-form-group>
                <b-form-group label-cols-sm="2" label-cols-lg="2" label="Short Desc">
                  <b-form-input type="text" name="seo_description" v-model="data.seo_description"></b-form-input>
                </b-form-group>
                <div class="form-check mb-3">
                  <input class="form-check-input" type="checkbox" value v-model="data.is_active" id="defaultCheck1" />
                  <label class="form-check-label" for="defaultCheck1">Active</label>
                </div>
                <div class="form-check mb-3">
                  <input class="form-check-input" type="checkbox" value v-model="data.show_on_front" id="show_on_front" />
                  <label class="form-check-label" for="show_on_front">Add to home page</label>
                </div>
                <div class="text-right mt-3">
                  <button v-if="!isEdit" @click="createBlog" type="button" class="btn btn-primary">
                    Submit
                  </button>
                  <button v-if="isEdit" @click="updateCategory" type="button" class="btn btn-primary">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
</template>
