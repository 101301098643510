import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import VueMask from "v-mask";
import VueYoutube from "vue-youtube";
import VeeValidate from "vee-validate";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import globalMixin from "@/mixins";
import vco from "v-click-outside";
import router from "./router";
import store from "@/state/store";
import i18n from "./i18n";
import vSelect from "vue-select";
import VueLazyload from "vue-lazyload";
import "vue-select/dist/vue-select.css";
import "@/assets/scss/app.scss";
import VueRouter from "vue-router";
import VueObserveVisibility from 'vue-observe-visibility';

Vue.config.productionTip = false;
export const EventBus = new Vue();

import moment from 'moment-timezone';
import VueMoment from 'vue-moment';

require('moment-timezone');

Vue.use(VueMoment, {
  moment,
});
Vue.use(VueRouter);
Vue.config.productionTip = false;
Vue.use(Loading);
Vue.use(VueYoutube);
Vue.use(BootstrapVue);
Vue.use(VueObserveVisibility)
Vue.use(VeeValidate, { inject: true, fieldsBagName: "veeFields" });
Vue.use(vco);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueMask);
Vue.use(VueLazyload);
Vue.use(require("vue-chartist"));
Vue.mixin(globalMixin);
const routes = [
  { path: "/dashboard", component: App },
];

Vue.component("apexchart", VueApexCharts);
Vue.component("v-select", vSelect);
new Vue({
  router,
  routes,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
