import RE_SELLER from "./index";
import RE_SELLER_DISCOUNT from "./discount.vue";

// import middlewares
export default [
  {
    path: "/re-sellers",
    component: RE_SELLER,
    name: "RE_SELLER",
  },
  {
    path: "/re-sellers/:id/manage-discount",
    component: RE_SELLER_DISCOUNT,
    name: "Reseller Discount",
  },
];
