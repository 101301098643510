<template>
    <div>
        <div class="text-right mb-3">
            <b-button variant="primary" size="sm" @click="AddFileModal = true">Add Banner</b-button>
        </div>

        <b-card title="Banners">
            <b-row>
                <b-col cols="6" class="mb-3" v-for="(item, index) in banners.banner" :key="index">
                    <img :src="item" class="w-100" alt="">
                </b-col>
            </b-row>
        </b-card>
        <b-card title="Mobile Banners">
            <b-row >
                <b-col cols="3" v-for="(item, index) in banners.mobile" :key="index">
                    <img :src="item" class="w-100" alt="">
                </b-col>
            </b-row>
        </b-card>
        <b-card title="Partners">
            <b-row>
                <b-col class="mb-4" cols="2" v-for="(item, index) in banners.partrners" :key="index">
                    <img :src="item" class="w-50 mx-auto" alt="">
                </b-col>
            </b-row>
        </b-card>
        <b-modal id="modal-prevent-closing" ref="modal" title="Add Banner" v-model="AddFileModal" @ok="addBanner()">
            <form>
                <div class="border-img">
                    <label for="">File</label> <br />
                    <input type="file" v-on:change="handleImageChange($event)" />
                </div>
                <div class="mt-3">
                    <select name="" id="type" class="form-control" v-model="types">
                        <option value="banner">Banner</option>
                        <option value="mobile">Mobile Banner</option>
                        <option value="partrners">Partner</option>
                    </select>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import { URL } from "@/api/global.env";
export default {
    data() {
        return {
            BASE_URL: URL + `media/`,
            types: '',
            FILE: null,
            AddFileModal: false,
            banners: {}
        }
    },
    mounted() {
        this.getBanners()
    },
    methods: {
        async getBanners() {
            this.$store.getters.client
                .get("/categories/home/")
                .then((response) => {
                    this.banners = response.data.home_banner
                });
        },
        handleImageChange(event) {
            const file = event.target.files[0];
            if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
                this.ImageToWebP(file);
            } else {
                this.FILE = file
            }
        },
        ImageToWebP(file) {
            const FILE_NAME = file.name.split(".")
            const UPDATED_NAME = FILE_NAME[0]
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);
                    canvas.toBlob((blob) => {
                        const convertedFile = new File([blob], UPDATED_NAME + '.webp', { type: 'image/webp' });
                        this.FILE = convertedFile;
                    }, 'image/webp');
                };
                img.src = event.target.result;
            };
            reader.readAsDataURL(file);
        },
        addBanner() {
            let formData = new FormData();
            if (this.FILE) {
                formData.append("file", this.FILE, this.FILE.name);
            }
            formData.append("types", this.types);
            this.$store.getters.client
                .post("/order/image-home/", formData)
                .then(() => {
                    this.AddFileModal = false
                });
        },
    }
}
</script>

<style></style>