import CustomProducts from "./index";
// import NewsLetter from "./newsletter.vue"
export default [
  {
    path: "/custom-products",
    component: CustomProducts,
    name: "Custom Products",
  },
//   {
//     path: "/news-letter",
//     component: NewsLetter,
//     name: "NewsLetter",
//   },
];
