import Team from "./index";
import CreateTeam from "./add-team";

// import middlewares
export default [
  {
    path: "/team-members",
    component: Team,
    name: "Team",
  },
  {
    path: "/add-member",
    component: CreateTeam,
    name: "CreateTeam",
  },
  {
    path: "/update-member/:id",
    component: CreateTeam,
    name: "CreateTeam",
  },
];
