<template>
    <div>
        <div class="add-button">
            <a href="javascript:void(0);" class="btn btn-primary" @click="productModal = true">
                + Add New Product
            </a>
        </div>
        <b-card title="User Emails">
            <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
            </b-dropdown>
            <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                            Show&nbsp;
                            <b-form-select disabled size="sm"></b-form-select>&nbsp;entries
                        </label>
                    </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                        <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input v-model="search" @keypress.enter="searchProduct" type="search"
                                class="form-control form-control-sm ml-2"></b-form-input>
                        </label>
                    </div>
                </div>
                <!-- End search -->
            </div>
            <div class="table-responsive">
                <b-table :items="products" responsive="sm" :fields="fields">
                    <template v-slot:cell(description)="row">
                        <div class="one-line">{{ row.item.description }}</div>
                    </template>
                    <template v-slot:cell(image)="row">
                        <img :src="row.item.image" alt="Image" width="60" class="rounded">
                    </template>
                    <template v-slot:cell(action)="row">
                        <div class="d-flex align-items-center" style="gap: 0.5rem;">
                            <a href="javascript:void(0);" class="text-primary" v-b-tooltip.hover title="Edit"
                                @click="editProduct(row.item)">
                                <i class="mdi mdi-pencil font-size-18"></i>
                            </a>
                            <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete"
                                @click="deleteproduct(row.item.id)">
                                <i class="mdi mdi-trash-can font-size-18"></i>
                            </a>
                        </div>
                    </template>
                </b-table>
            </div>
            <div class="row">
                <div class="col">
                    <div class=" dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                            <b-pagination v-model="current_page" :total-rows="totalRows" :per-page="per_page"
                                @change="getCustomProducts">
                            </b-pagination>
                        </ul>
                    </div>
                </div>
            </div>
        </b-card>
        <b-modal title="Create new product" v-model="productModal" hide-footer>
            <b-row>
                <b-col>
                    <b-form ref="productForm">
                        <div class="border-img mb-2">
                            <label for="">Product Image</label> <br />
                            <input type="file" v-on:change="uploadImage($event)" />
                        </div>
                        <b-form-group label="Image URL">
                            <b-form-input size="sm" type="text" v-model="customProduct.files"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Product Name">
                            <b-form-input size="sm" type="text" v-model="customProduct.name" required></b-form-input>
                        </b-form-group>
                        <b-form-group label="Description">
                            <ckeditor class="border rounded" :config="editorConfig" v-model="customProduct.description"
                                :editor="editor">
                            </ckeditor>
                            <!-- <b-form-textarea v-model="customProduct.description" required></b-form-textarea> -->
                        </b-form-group>
                        <!-- <pre>
                            {{ customProduct.supplier }}
                        </pre> -->
                        <b-form-group label="Supplier">
                            <multiselect track-by="id" :multiple="true" :hide-selected="true" label="company_name"
                                value="id" name="product" :options="supplierList" v-model="customProduct.supplier">
                                <template slot="afterList">
                                    <div v-observe-visibility="reachedEndOfCategory" />
                                </template>
                            </multiselect>
                            <!-- <b-form-select size="sm" type="text" :options="supplierList" text-field="company_name"
                                value-field="id" v-model="customProduct.supplier"></b-form-select> -->
                        </b-form-group>
                        <b-form-group>
                            <b-form-checkbox v-model="customProduct.is_vat_applicable" :unchecked-value="false" switch
                                class="mb-1">
                                <label>
                                    VAT
                                </label>
                            </b-form-checkbox>
                        </b-form-group>
                        <hr>
                        <b-row>
                            <b-col><b-button variant="secondary" class="w-100" type="button"
                                    @click="resetProductForm">Cancel</b-button></b-col>
                            <b-col><b-button variant="success" class="w-100" type="button"
                                    @click="isEdit ? updateProduct() : addNewProduct()">
                                    {{ isEdit ? 'Update Product' : 'Add Product' }}</b-button></b-col>
                        </b-row>
                    </b-form>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
  
<script>
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

export default {
    components: { ckeditor: CKEditor.component, Multiselect },
    data() {
        return {
            totalRows: 1,
            current_page: 1,
            per_page: 5,
            filter: null,
            products: [],
            sortDesc: false,
            search: '',
            productModal: false,
            FILE: null,
            editItemId: null,
            customProduct: {
                image: null,
                name: "",
                description: "",
                files: null,
                is_vat_applicable: true,
                supplier: null,
            },
            supplierList: [],
            isEdit: false,
            fields: [
                { key: "image", label: "Image" },
                { key: "name", label: "Name" },
                { key: "description", sortable: true, label: "Description", thStyle: { width: "50%" } },
                { key: "is_active", label: "Active" },
                { key: 'action', label: "Action" },
            ],
            editorConfig: {
                toolbar: [
                    'paragraph', 'heading', 'bold', 'italic', '|', 'bulletedList', 'numberedList'
                ]
            },
            editor: ClassicEditor,
            supplierTotalPage:0,
            supplierCurrentPage:0,
        };
    },
    mounted() {
        this.getCustomProducts(1);
        this.getSupplier()
    },
    methods: {
        getCustomProducts(value) {
            this.$store.getters.client.get(`/order/customer-product/?page=${value}`).then((response) => {
                this.products = response.data.results;
                this.totalRows = response.data.total_count;
                this.per_page = response.data.per_page;
            });
        },
        reachedEndOfCategory(reached) {
            if (reached && this.supplierCurrentPage < this.supplierTotalPage) {
                this.getSupplier();
            }
        },
        getSupplier() {
            this.$store.getters.client.get(`/supplier/supplier/?page=${this.supplierCurrentPage + 1}`).then((response) => {
                // this.supplierList = response.data.results;
                this.supplierList = this.supplierList.concat(response.data.results);
                this.supplierTotalPage = response.data.total_pages;
                this.supplierCurrentPage = response.data.current_page;
            });
        },
        searchProduct() {
            if (this.search) {
                let loader = this.$loading.show({
                    loader: "dots",
                });
                this.$store.getters.client
                    .get(`/order/customer-product/?search=${this.search}`)
                    .then((response) => {
                        this.products = response.data.results;
                        this.totalRows = response.data.total_count;
                        this.per_page = response.data.per_page;
                        loader.hide();
                    }).catch((err) => {
                        loader.hide();
                        alert(err)
                    });
            } else {
                this.getCustomProducts(1);
            }
        },
        async addNewProduct() {
            const supplier = this.customProduct.supplier.map(x => x.id)
            let loader = this.$loading.show({
                loader: "dots",
            });
            let formData = new FormData();
            if (this.FILE) {
                formData.append("image", this.FILE, this.FILE.name);
            }
            formData.append("name", this.customProduct.name);
            formData.append("description", this.customProduct.description);
            formData.append("is_vat_applicable", this.customProduct.is_vat_applicable);
            formData.append("supplier", supplier);
            try {
                await this.$store.getters.client.post(`/order/customer-product/`, formData)
                this.productModal = false
                this.resetProductForm()
            } catch (error) {
                alert(error)
            } finally {
                this.getCustomProducts(1)
                loader.hide()
            }
        },
        async updateProduct() {

            const supplier = this.customProduct.supplier.map(x => x.id)
            // console.log('dfd', supplier)
            let loader = this.$loading.show({
                loader: "dots",
            });
            let formData = new FormData();
            if (this.FILE) {
                formData.append("image", this.FILE, this.FILE.name);
            }
            formData.append("name", this.customProduct.name);
            formData.append("description", this.customProduct.description);
            formData.append("is_vat_applicable", this.customProduct.is_vat_applicable);
            formData.append("supplier", supplier);

            try {
                await this.$store.getters.client.patch(`/order/customer-product/${this.editItemId}/`, formData)
                this.productModal = false
                this.resetProductForm()
            } catch (error) {
                alert(error)
            } finally {
                this.getCustomProducts(1)
                loader.hide()
            }
        },
        resetProductForm() {
            this.customProduct.image = null,
                this.customProduct.name = "",
                this.customProduct.description = "",
                this.customProduct.files = null,
                this.customProduct.is_vat_applicable = true,
                this.customProduct.supplier = null
        },
        deleteproduct(id) {
            Swal.fire({
                title: "Move to trash",
                text: "You can delete it from trash",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
                // eslint-disable-next-line no-unused-vars
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let loader = this.$loading.show({
                        loader: "dots",
                    });
                    await this.$store.getters.client.delete(`/order/customer-product/${id}/`);
                    this.getCustomProducts(1)
                    loader.hide()
                }
            });
        },
        editProduct(item) {
            this.customProduct = item;
            this.isEdit = true;
            this.editItemId = item.id;
            this.productModal = true;
        },

        uploadImage(event) {
            const FILE = event.target.files[0];
            if (FILE) {
                this.ImageToWebP(FILE);
            }
        },

        ImageToWebP(file) {
            const FILE_NAME = file.name.split(".")
            const UPDATED_NAME = FILE_NAME[0]
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);
                    canvas.toBlob((blob) => {
                        const convertedFile = new File([blob], UPDATED_NAME + '.webp', { type: 'image/webp' });
                        this.FILE = convertedFile;
                    }, 'image/webp');
                };
                img.src = event.target.result;
            };
            reader.readAsDataURL(file);
        },
    },
};
</script>
  