import Quotation from "./index";
import QuotationDetails from "./quotation-details"
import NewQuotation from "./new-quotation.vue"
// import middlewares
export default [
  {
    path: "/quotation",
    component: Quotation,
    name: "Quotation",
  },
  {
    path: "/quotation-details/:id",
    component: QuotationDetails,
    name: "QuotationDetails",
  },
  {
    path: "/customer/:id/new-quotation",
    component: NewQuotation,
    name: "NewQuotation",
  },
  {
    path: "/add-new-quotation",
    component: NewQuotation,
    name: "NewQuotation",
  },
];
