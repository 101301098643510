<script>
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      id:null,
      supplierList: [],
      selectedSupplier: [],
    };
  },
  components: {
    Multiselect,
  },
  mounted() {
    this.$store.getters.client.get(`/supplier/supplier/`).then((response) => {
      this.supplierList = response.data.results;
    });
  },

  methods: {
    onComplete() {
      this.$emit("updatePage", 4);
      this.selectedSupplier.forEach((item) => {
        const data = {
          is_active: item.is_active,
          base_price: item.base_price,
          lead_time: item.lead_time,
          product_id: this.id,
          supplier: item.id,
        };

        this.$store.getters.client
          .post(`/catalog-product/supplier-product/`, data)
          .then(() => {
            this.$swal({
              position: "top-end",
              icon: "success",
              title: "Your work has been saved",
              showConfirmButton: false,
              timer: 1500,
            });
            
          });
      });
    },

    remove(index) {
      this.selectedSupplier.splice(index, 1);
    },
  },

  created() {
    if ("id" in this.$route.params) {
      this.isEdit = true;
      this.id = this.$route.params.id;
    } else {
      this.id = localStorage.getItem("product_id");
    }
  },
};
</script>
 <template>
  <div>
    <b-card-text>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Supplier Details</h4>

            <form>
              <div class="forn-group">
                <label>Supplier</label>
                <multiselect
                  name="supplier"
                  v-model="selectedSupplier"
                  :options="supplierList"
                  label="company_name"
                  :multiple="true"
                  track-by="company_name"
                  :value.sync="selectedSupplier"
                ></multiselect>
              </div>
            </form>

            <div class="mt-5">
              <div
                class="row align-items-center justify-content-between mt-3"
                v-for="(supplier, i) in selectedSupplier"
                :key="i"
              >
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="name">Supplier Name</label>
                    <p>
                      <b>{{ supplier.company_name }}</b>
                    </p>
                  </div>
                </div>
                <div class="col-md-3">
                  <label for="name" class="d-block">Base Price</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">&#163;</div>
                    </div>
                    <input
                      type="number"
                      class="form-control"
                      min="0"
                      v-model.number="supplier.base_price"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <label for="name" class="d-block">Lead Time</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">Days</div>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="supplier.lead_time"
                    />
                  </div>
                </div>
                <div class="col-md-2">
                  <span class="cursor_pointer text-danger" @click="remove(i)"
                    ><i class="fa fa-trash"></i
                  ></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center mt-4">
          <button
            type="button"
            @click="onComplete()"
            class="btn btn-primary float-right"
          >
            Next
          </button>

          <button
            @click="$emit('previousPage', 2)"
            type="button"
            class="btn btn-danger float-left"
          >
            Previous
          </button>
        </div>
      </div>
    </b-card-text>
  </div>
</template>

<style>
</style>