<template>
    <ul style="list-style-type: none; padding-left: 0%; " class="mb-3">
        <li style="font-weight: 600;">Bill to:</li>
        <li>{{ quoteData?.users?.first_name }} {{ quoteData?.users?.last_name }}</li>
        <li>{{ quoteData?.company_details }}</li>
        <li> {{ quoteData?.billing_address[0]?.street1 }} </li>
        <li> {{ quoteData?.billing_address[0]?.street2 }} </li>
        <li> {{ quoteData?.billing_address[0]?.city }} </li>
        <li> {{ quoteData?.billing_address[0]?.county }}, {{ quoteData?.billing_address[0]?.postal_code }}</li>
    </ul>
</template>

<script>
export default {
    props: {
        quoteData: {
            type: Object,
            required: false,
        },
    },
}
</script>

<style></style>