import Studio from "./index";
import Manage from "./manage-studio.vue";

// import middlewares
export default [
  {
    path: "/studio",
    component: Studio,
    name: "Studio",
  },
  {
    path: "/add-studio/",
    component: Manage,
    name: "createStudio",
  },
  {
    path: "/edit-studio/:id",
    component: Manage,
    name: "editStudio",
  }
];
