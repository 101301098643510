<template>
  <b-card :title="title">
    <form-wizard color="#556ee6" @on-change="onChange" @on-complete="complete">
      <tab-content
        icon="mdi mdi-account-circle"
        :before-change="() => beforeTabSwitch()"
      >
        <form class="form-horizontal" role="form" ref="firtStep">
          <b-form-group
            id="example text"
            label-cols-sm="2"
            label-cols-lg="2"
            label="Title"
            label-for="text"
          >
            <b-form-input
              for="text"
              v-validate="'required'"
               v-on:change="isCharAndDigits(attribute.name)"
              name="name"
              v-model="attribute.name"
            ></b-form-input>
            <p class="text-danger text-sm" v-show="errors.has('name')">
              * {{ errors.first("name") }}
            </p>
             <p class="text-danger text-sm" v-if="isCharAndDigits(attribute.name) == false">
              * This field can contain  only characters
            </p>
          </b-form-group>

          <b-form-group
            id="example text"
            label-cols-sm="2"
            label-cols-lg="2"
            label="Description"
            label-for="text"
          >
            <div class="custom-file">
              <ckeditor
                class="border rounded"
                v-model="attribute.description"
                v-validate="'required'"
                name="description"
                :editor="editor"
              ></ckeditor>
            </div>
            <p class="text-danger text-sm" v-show="errors.has('description')">
              * {{ errors.first("description") }}
            </p>
          </b-form-group>
          <div class="form-check mb-3">
            <input
              v-model="attribute.is_active"
              class="form-check-input"
              type="checkbox"
              value
              id="defaultCheck1"
            />
            <label class="form-check-label" for="defaultCheck1">Status</label>
          </div>
        </form>
        <!-- end row -->
      </tab-content>
      <tab-content icon="mdi mdi-face-profile">
        <b-button v-b-modal.modal-lg variant="primary">
          Add Attribute Value
        </b-button>
        <b-modal
          @ok="createValue()"
          id="modal-lg"
          size="lg"
          title="Add Attribute Value"
          title-class="font-18"
        >
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <form class="form-horizontal" role="form">
                    <label for="">Name</label>
                    <b-form-input
                      for="text"
                      v-validate="'required'"
                      name="name"
                       v-on:change="isCharAndDigits(data.name)"
                      v-model="data.name"
                    ></b-form-input>
                    <p class="text-danger text-sm" v-show="errors.has('name')">
                      * {{ errors.first("name") }}
                    </p>

  <p class="text-danger text-sm" v-if="isCharAndDigits(data.name) == false">
                      * This field can contain only characters
                    </p>

                    <label>Description</label>
                    <div class="custom-file">
                      <ckeditor
                        class="border rounded"
                        v-model="data.description"
                        v-validate="'required'"
                        name="description"
                        :editor="editor"
                      ></ckeditor>
                    </div>
                    <p
                      class="text-danger text-sm"
                      v-show="errors.has('description')"
                    >
                      * {{ errors.first("description") }}
                    </p>

                    <div class="form-check mb-3">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value
                        v-model="data.is_active"
                        id="defaultCheck1"
                      />
                      <label class="form-check-label" for="defaultCheck1"
                        >Status</label
                      >
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <div class="row mt-4">
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div
              id="tickets-table_filter"
              class="dataTables_filter"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  v-model="filter"
                  type="search"
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>
        <div class="col-12">
          <div class="table-responsive mt-3">
            <b-table
              class="table-centered"
              :items="attributeData"
              :fields="attValuesList"
              responsive="sm"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter-included-fields="filterOn"
              :filter="filter"
            >
              <template v-slot:cell(description)="row">
                <p v-html="row.item.description"></p>
              </template>

              <template v-slot:cell(action)="row">
                <a
                  href="javascript:void(0);"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  title="Edit"
                  v-b-modal.modal-lg
                  @click="editAttributeValue(row.item)"
                >
                  <i class="mdi mdi-pencil font-size-18"></i>
                </a>
                <a
                  href="javascript:void(0);"
                  class="text-danger"
                  v-b-tooltip.hover
                  title="Delete"
                  @click="confirm(row.item)"
                >
                  <i class="mdi mdi-trash-can font-size-18"></i>
                </a>
              </template>
            </b-table>
          </div>
        </div>
      </tab-content>
    </form-wizard>
  </b-card>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FormWizard, TabContent } from "vue-form-wizard";
import Swal from "sweetalert2";
export default {
  name: "Add-Attributes",
  components: { FormWizard, TabContent, ckeditor: CKEditor.component },
  data() {
    return {
      title: "Add Attribute",
      tab: 1,
      id: null,
      attValueId: null,
      isEdit: Boolean,
      attValueEdit: false,
      attributeData: [],
      attribute: {
        name: "",
        description: "",
        is_active: true,
      },
      data: {
        name: "",
        description: "",
        attribute: null,
        is_active: true,
      },
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      attValuesList: [
        { key: "name", sortable: true, label: "Name" },
        { key: "description", label: "Description" },
        { key: "is_active", label: "Status" },
        { key: "action" },
      ],
      editor: ClassicEditor,
    };
  },

  beforeMount() {
    if (this.isEdit == true) {
      this.getAttribute();
    }
  },
  mounted() {
    if (this.isEdit == true) {
      this.getAttributeValue();
    }
  },
  methods: {
    async getAttribute() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client
        .get(`/attributes/${this.id}`)
        .then((response) => {
          this.attribute = response.data;
        });
      loader.hide();
    },

    beforeTabSwitch() {
      if (this.attribute.name == "" || this.attribute.description == "") {
        return false;
      } else {
        return true;
      }
    },

    async onChange(prevIndex, nextIndex) {
      if (nextIndex === 1) {
        let loader = this.$loading.show({
          loader: "dots",
        });
        if (this.isEdit == true) {
          this.$store.getters.client
            .put(`/attributes/${this.id}/`, this.attribute)
            .then((response) => {
              this.data.attribute = response.data.id;
              this.id = response.data.id;
              this.$swal({
                position: "top-end",
                icon: "success",
                title: "Attribute updated successfully",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        } else {
          this.$store.getters.client
            .post("/attributes/", this.attribute)
            .then((response) => {
              this.data.attribute = response.data.id;
              this.id = response.data.id;
              if(response.status === 201){
                this.$swal({
                  position: "top-end",
                  icon: "success",
                  title: "Attribute added successfully",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }else{
                this.$swal({
                  position: "top-end",
                  icon: "error",
                  title: "attributes with this name already exists",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error) => {
              this.$swal({
                  position: "top-end",
                  icon: "error",
                  title: "attributes with this name already exists",
                  showConfirmButton: false,
                  timer: 1500,
                });
            })
        }
        loader.hide();
      }
    },

     isCharAndDigits(value) {
      if (!/[^a-zA-Z]/.test(value)) {
        return true;
      }
      return false
    },

    complete() {
      this.$router.push("/attributes");
    },
    getAttributeValue() {
      if (this.id !== null) {
        let loader = this.$loading.show({
          loader: "dots",
        });
        this.$store.getters.client
          .get(`/attributes/${this.id}`)
          .then((response) => {
            this.attributeData = response.data.attribute_values;
            loader.hide();
          });
      }
    },
    editAttributeValue(item) {
      this.attValueEdit = true;
      this.data.name = item.name;
      this.data.description = item.description;
      this.data.attribute = item.attribute;
      this.data.is_active = item.is_active;
      this.attValueId = item.id;
    },
    async confirm(attributes) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          let loader = this.$loading.show({
            loader: "dots",
          });
          this.$store.getters.client
            .delete(`/attribute-values/${attributes.id}/`)
            .then(() => {
              if (result.isConfirmed) {
                Swal.fire("Deleted!", "Your file has been deleted.", "success");
              }
              loader.hide();
              this.getAttributeValue();
            });
        }
      });
    },
    createValue() {
      let loader = this.$loading.show({
        loader: "dots",
      });

      if (this.attValueEdit == true) {
        this.$store.getters.client
          .put(`/attribute-values/ ${this.attValueId}/`, this.data)
          .then(() => {
            this.data.name = "";
            this.data.description = "";
            this.data.is_active = false;
            this.data.attribute = "";
            this.attValueEdit = false;
            this.getAttributeValue();
          });
      } else {
        this.data.attribute = this.id;
        this.$store.getters.client
          .post("/attribute-values/", this.data)
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            this.data.name = "";
            this.data.description = "";
            this.data.is_active = false;
            this.getAttributeValue();
          });
      }
      loader.hide();
    },
  },

  created() {
    if ("id" in this.$route.params) {
      this.isEdit = true;
      this.id = this.$route.params.id;
      this.title = "Edit Attributes";
    } else {
      this.tilte = "Create Attributes";
    }
  },
};
</script>