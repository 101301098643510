<script>
import RevenueAnalytics from "./revenue";
import SalesAnalytics from "./sales-analytics";
import QuotationAnalytics from "./quotation-analytics.vue";
export default {
  components: {
    // Stat,
    RevenueAnalytics,
    SalesAnalytics,
    QuotationAnalytics,
  },
  data() {
    return {
      title: "Dashboard",
      // areaLineChart: areaLineChart,
      items: [
        { text: "Nazox", },
        { text: "Dashboard", active: true, },
      ],
      analyticData: null,
    };
  },
  mounted() {
    this.getAnalyticsData()
  },
  methods: {
    async getAnalyticsData() {
      try {
        const response = await this.$store.getters.client.get(`/order/admin-dashboard/`);
        console.log(response)
        this.analyticData = response.data;
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>

<template>
  <div>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-xl-12">
        <div class="row">
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="media">
                  <div class="media-body overflow-hidden">
                    <h4 class="mb-0"> Sales Analytic</h4>
                  </div>
                  <div class="text-primary">
                    <i class="ri-stack-line font-size-24"></i>
                  </div>
                </div>
              </div>

              <div class="card-body border-top py-3">
                <div class="text-truncate d-flex align-items-center justify-content-between">
                  <div>Total Sales</div>
                  <div>{{ analyticData?.total_no_of_orders }}</div>
                </div>
              </div>
              <div class="card-body border-top py-3">
                <div class="text-truncate d-flex align-items-center justify-content-between">
                  <div>Weekly Sales</div>
                  <div>{{ analyticData?.weekly_sale[analyticData?.weekly_sale.length - 1].count }}</div>
                </div>
              </div>
              <div class="card-body border-top py-3">
                <div class="text-truncate d-flex align-items-center justify-content-between">
                  <div>Monthly Sales</div>
                  <div>{{ analyticData?.monthly_sale[analyticData?.monthly_sale.length - 1].count }}</div>
                </div>
              </div>
              <div class="card-body border-top py-3">
                <div class="text-truncate d-flex align-items-center justify-content-between">
                  <div>Yearly Sales</div>
                  <div>{{ analyticData?.yearly_sale[analyticData?.yearly_sale.length - 1].count }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="media">
                  <div class="media-body overflow-hidden">
                    <h4 class="mb-0"> Sales Revenue</h4>
                  </div>
                  <div class="text-primary">
                    <i class="ri-store-2-line font-size-24"></i>
                  </div>
                </div>
              </div>

              <div class="card-body border-top py-3">
                <div class="text-truncate d-flex align-items-center justify-content-between">
                  <div>Total Sales</div>
                  <div>{{ analyticData?.total_no_of_orders }}</div>
                </div>
              </div>
              <div class="card-body border-top py-3">
                <div class="text-truncate d-flex align-items-center justify-content-between">
                  <div>Weekly Sales</div>
                  <div>&#163;{{ analyticData?.weekly_sale[analyticData?.weekly_sale.length - 1].sum }}</div>
                </div>
              </div>
              <div class="card-body border-top py-3">
                <div class="text-truncate d-flex align-items-center justify-content-between">
                  <div>Monthly Sales</div>
                  <div>&#163;{{ analyticData?.monthly_sale[analyticData?.monthly_sale.length - 1].sum }}</div>
                </div>
              </div>
              <div class="card-body border-top py-3">
                <div class="text-truncate d-flex align-items-center justify-content-between">
                  <div>Yearly Sales</div>
                  <div>&#163;{{ analyticData?.yearly_sale[analyticData?.yearly_sale.length - 1].sum }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-body">
                <div class="media">
                  <div class="media-body overflow-hidden">
                    <p class="text-truncate font-size-14 mb-2">Average Price</p>
                    <h4 class="mb-0">&#163;{{ analyticData?.average_order }}</h4>
                  </div>
                  <div class="text-primary">
                    <i class="ri-briefcase-4-line font-size-24"></i>
                  </div>
                </div>
              </div>

              <div class="card-body border-top py-3">
                <div class="text-truncate">
                  <span class="badge badge-soft-success font-size-11">
                    <!-- <i class="mdi mdi-menu-up"></i> -->
                    &#163;{{ analyticData?.average_order }}
                  </span>
                  <span class="text-muted ml-2">From previous period</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RevenueAnalytics />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <SalesAnalytics />
      </div>
      <div class="col-lg-4">
        <QuotationAnalytics/>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-3">Popular Products</h4>

            <div>
              <div class="table-responsive mt-4" style="max-width: 100%; max-height: 315px; overflow: auto;">
                <table class="table table-hover mb-0 table-centered">
                  <tbody>
                    <tr v-for="(product, index) in analyticData?.popular_product" :key="index" style="max-width: 100%;">
                      <td style="width: 60px;">
                        <div class="avatar-xs">
                          <div class="avatar-title rounded-circle bg-light">
                          </div>
                        </div>
                      </td>

                      <td>
                        <h5 class="font-size-14 mb-0">{{product.product__name}}</h5>
                      </td>
                      <td>
                        <p class="text-muted mb-0">{{product.count}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-3">Artwork services</h4>

            <div>
              <div class="table-responsive mt-4" style="max-width: 100%; max-height: 315px; overflow: auto;">
                <table class="table table-hover mb-0 table-centered">
                  <thead>
                    <tr>
                      <th>Service Amount</th>
                      <th>Counts</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(product, index) in analyticData?.artwork_service" :key="index" style="max-width: 100%;">

                      <td>
                        <h5 class="font-size-14 mb-0">&#163;{{product.design_service_price || 0}}</h5>
                      </td>
                      <td>
                        <p class="text-muted mb-0">{{product.count}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>