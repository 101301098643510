<template>
    <div>
        <div class="d-flex justify-content-end">
            <b-button variant="outline-success" @click="mailModal = true" class="mb-3">Send Promotional mail</b-button>
        </div>
        <b-card title="User Emails">
            <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
            </b-dropdown>
            <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                            Show&nbsp;
                            <b-form-select v-model="perPage" size="sm" disabled></b-form-select>&nbsp;entries
                        </label>
                    </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                        <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input v-model="filter" type="search"
                                class="form-control form-control-sm ml-2"></b-form-input>
                        </label>
                    </div>
                </div>
                <!-- End search -->
            </div>
            <div class="table-responsive">
                <b-table :items="emails" :fields="fields" responsive="sm" @row-clicked="myRowClickHandler">
                    <template v-slot:cell(created_at)="row">
                        {{ $moment(row.item.created_at).tz('Europe/London').format("DD MMM, YYYY") }}
                    </template>
                </b-table>
            </div>
            <div class="d-flex justify-content-end">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                    @change="getEmails"></b-pagination>
            </div>
        </b-card>

        <b-modal title="Send Promotional Mail" v-model="mailModal" hide-footer>
            <div class="border-img mb-3">
                <label for="">CSV File</label> <br />
                <input type="file" v-on:change="handleFileChange($event)" />
            </div>
            <b-form-group label="Title">
                <b-form-input v-model="emailData.name"></b-form-input>
            </b-form-group>
            <b-form-group label="description">
                <b-form-textarea v-model="emailData.description" rows="6"></b-form-textarea>
            </b-form-group>
            <b-button variant="success" class="w-100 mt-3" size="sm" @click="sendMails">{{ loading ? 'loading...' : 'Send'
            }}</b-button>
        </b-modal>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            mailModal: false,
            loading: false,
            FILE: null,
            emails: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 15,
            filter: null,
            sortDesc: false,
            fields: [
                { key: "created_at", sortable: true, label: "Date" },
                { key: "name", label: "Name" },
                { key: "description", label: "Description" },
            ],
            emailData: {
                name: '',
                description: ''
            }
        };
    },
    mounted() {
        this.getEmails(1);
    },
    methods: {
        myRowClickHandler(event) {
            this.$router.push({ name: "Marketing email details", params: { id: event.id } });
        },
        getEmails(value) {
            this.$store.getters.client.get(`/variant/csv-email/?page=${value}`).then((response) => {
                this.emails = response.data.results;
                this.totalRows = response.data.total_count;
                this.currentPage = response.data.current_page;
                this.perPage = response.data.per_page
            });
        },
        handleFileChange(event) {
            this.FILE = event.target.files[0]
        },
        async sendMails() {
            this.loading = true;
            let formData = new FormData()
            formData.append("csv_files", this.FILE, this.FILE.name)
            formData.append("name", this.emailData.name)
            formData.append("description", this.emailData.description)
            try {
                const result = await this.$store.getters.client.post("/variant/csv-email/", formData)
                this.$swal({
                    position: "top-end",
                    icon: "success",
                    title: result.data.msg,
                    showConfirmButton: false,
                    timer: 1500,
                });
            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false;
                this.mailModal = false;
            }
        }
    },
};
</script>
  