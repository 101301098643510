<template>
    <div>
        <!-- {{ selectedUsers }} -->
        <b-row>
            <b-col cols="4">
                <b-card title="Customer Details">
                    <div class="position-relative">
                        <b-form-group>
                            <b-form-input placeholder="Search Customer" v-model="searchCustomer"
                                @input="searchUser"></b-form-input>
                        </b-form-group>
                        <div class="customer-list form-control h-auto" v-show="isOpen">
                            <div class="customer" role="button" @click="selectCustomer(customer)"
                                v-for="customer in customerData" :key="customer.id">
                                <div><small><b>{{ customer.first_name }} {{ customer.last_name }}</b></small> : {{
                                    customer.company_name }}</div>
                                <small>{{ customer.email }}</small>
                            </div>
                        </div>
                    </div>
                </b-card>
            </b-col>
            <b-col cols="8">
                <b-card title="Create Quotation">
                    <b-form>
                        <b-row>
                            <b-col cols="6">
                                <b-form-group id="company" label="Company name" label-for="company">
                                    <b-form-input for="company" v-model="data.company_details" name="company"
                                        disabled></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group id="contact_type " label="Contact Type" label-for="contact_type ">
                                    <b-form-select for="contact_type" :options="contactTypes"
                                        v-model="quotation_type"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group id="firstName" label="First Name" label-for="firstName">
                                    <b-form-input disabled for="firstName" v-model="data.first_name"
                                        name="firstName"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group id="lastName" label="Last Name" label-for="lastName">
                                    <b-form-input disabled for="lastName" v-model="data.last_name"
                                        name="lastName"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group id="email" label="Email" label-for="email">
                                    <b-form-input disabled for="email" v-model="data.email" name="email"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group id="phone" label="Phone" label-for="phone">
                                    <b-form-input disabled for="phone" v-model="data.phone" name="phone"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group id="mobile" label="Mobile Number" label-for="mobile">
                                    <b-form-input disabled for="mobile" v-model="data.mobile" name="mobile"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group id="customer_type " label="Customer Type" label-for="customer_type ">
                                    <b-form-select for="customer_type" :options="customerTypes"
                                        v-model="data.customer_type"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group label="Expected Delivery Days">
                                    <!-- <b-form-datepicker today-button reset-button close-button id="expected_lead_time"
                                        v-model="data.expected_lead_time" class="mb-2"
                                        :min="new Date()"></b-form-datepicker> -->
                                    <b-form-input type="number" for="expected_lead_days" v-model="expected_lead_days"
                                        name="expected_lead_days"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group id="payment_method" label="Payment Method" label-for="payment_method">
                                    <b-form-select for="payment_method" :options="paymentMethods"
                                        v-model="data.payment_method"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-form-group id="message" label="Quotation Requirements" label-for="message">
                                    <b-form-textarea rows="5" for="message" v-model="message"
                                        name="message"></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                    <div class="float-right">
                        <button class="btn btn-primary" @click="createQuote()">
                            Create Quotation
                        </button>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// import Multiselect from "vue-multiselect";
export default {
    // components: {
    //     Multiselect
    // },
    data() {
        return {
            id: null,
            contactTypes: [
                { text: 'Website', value: 'website' },
                { text: 'Phone', value: 'telephone' },
                { text: 'Chat', value: 'chat' },
                { text: 'Email', value: 'email' }
            ],
            customerTypes: [
                { text: 'Enduser', value: 'enduser' },
                { text: 'B2B', value: 'b2b' },
                { text: 'Reseller', value: 'reseller' }
            ],
            paymentMethods: [
                { text: 'At Dispatch', value: 'after_dispatch' },
                { text: 'After Artwork', value: 'after_artwork' },
                { text: 'On Order', value: 'with_order' }
            ],
            isOpen: false,
            searchCustomer: '',
            customerData: [],
            selectedUsers: null,
            data: {
                first_name: "",
                last_name: '',
                email: "",
                phone: "",
                mobile: '',
                company_details: '',
                customer_type: '',
                payment_method: 'with_order',
            },
            expected_lead_days: null,
            expected_lead_time: '',
            message: '',
            quotation_type: 'website',
            created_by: null,
        }
    },
    created() {
        this.id = this.$route.params.id
        const user = localStorage.getItem('userId')
        this.created_by = user
    },
    mounted() {
        if (this.id) {
            this.getCustomers(this.id)
        }
    },
    methods: {
        async getCustomers(value) {
            let loader = this.$loading.show({
                loader: "dots",
            });
            try {
                const resp = await this.$store.getters.client.get(`/order/quotation-customer/${value}/`)
                this.data = resp.data
            } catch (error) {
                alert(error)
            } finally {
                loader.hide()
            }
        },
        selectCustomer(customer) {
            this.selectedUsers = customer
            this.data = customer
            this.data.company_details = customer.company_name
            this.isOpen = false
        },

        async createQuote() {
            // this.calculateExpectedDate(new Date(), this.expected_lead_days)
            this.data.message = this.message
            this.data.quotation_type = this.quotation_type
            this.data.customer = this.selectedUsers.id
            this.data.created_by = this.created_by
            this.data.expected_lead_days = this.expected_lead_days
            // this.data.expected_lead_time = this.expected_lead_time
            if (this.quotation_type !== '' && this.message !== '') {
                let loader = this.$loading.show({
                    loader: "dots",
                });
                try {
                    console.log(this.data)
                    await this.$store.getters.client.post(`/order/quotation/`, this.data)
                    this.$router.push('/quotation')
                } catch (error) {
                    alert(error)
                } finally {
                    loader.hide()
                }
            } else {
                alert('Contact type and message is required')
            }
        },
        async searchUser() {
            try {
                const resp = await this.$store.getters.client.get(`/order/quotation-customer/?search=${this.searchCustomer}`)
                this.customerData = resp.data.results
            } catch (error) {
                alert(error)
            } finally {
                this.isOpen = true
            }
        },
    }
}
</script>

<style>
.customer:hover {
    background: #F1F1F1;
    padding: 0 4px;

}
</style>
