<template>
    <div>
        <div class="d-flex justify-content-end">
            <b-button variant="outline-success" @click="mailModal = true" class="mb-3">Send Promotional mail</b-button>
        </div>
        <b-card title="User Emails">
            <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
            </b-dropdown>
            <div class="row mt-4" v-if="emailDetails">
                <div class="col-12 col-md-6">
                    <p><Strong>Email Title:</Strong>  {{ emailDetails.name || 'NA'}}</p>
                    <p><Strong>Email Description:</Strong>  {{ emailDetails.description || 'NA'}}</p>
                </div>
                <div class="col-12 col-md-6">
                    <b-row>
                        <b-col cols="6">
                            <p><Strong>Total Open:</Strong>  {{ emailDetails.analytics_data.total_open_count}}</p>
                            <p><Strong>Total Unsubscribed:</Strong>  <span class="text-warning">{{ emailDetails.analytics_data.total_unsubscribed}}</span></p>
                        </b-col>
                        <b-col cols="6">
                            <p><Strong>Total Open Sum:</Strong>  {{ emailDetails.analytics_data.sum_total_open }}</p>
                            <p><Strong>Total Bounced:</Strong>  <span class="text-danger">{{ emailDetails.analytics_data.total_bounced_count }}</span></p>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div class="table-responsive">
                <b-table :items="emails" :fields="fields" responsive="sm">
                    <template v-slot:cell(created_at)="row">
                        {{ $moment(row.item.created_at).tz('Europe/London').format("DD MMM, YYYY") }}
                    </template>
                    <template v-slot:cell(open)="row">
                        {{row.item.open?'Yes':'No'}}
                    </template>
                    <template v-slot:cell(bounced)="row">
                        {{row.item.bounced?'Yes':'No'}}
                    </template>
                    <template v-slot:cell(unsubscribed)="row">
                        {{row.item.unsubscribed?'Yes':'No'}}
                    </template>
                    <template v-slot:cell(clicked)="row">
                        {{row.item.clicked?'Yes':'No'}}
                    </template>
                </b-table>
            </div>
        </b-card>

        <b-modal title="Send Promotional Mail" v-model="mailModal" hide-footer>
            <div class="border-img mb-3">
                <label for="">CSV File</label> <br />
                <input type="file" v-on:change="handleFileChange($event)" />
            </div>
            <b-form-group label="Title">
                <b-form-input v-model="emailData.name"></b-form-input>
            </b-form-group>
            <b-form-group label="description">
                <b-form-textarea v-model="emailData.description" rows="6"></b-form-textarea>
            </b-form-group>
            <b-button variant="success" class="w-100 mt-3" size="sm" @click="sendMails">{{ loading ? 'loading...' : 'Send'
            }}</b-button>
        </b-modal>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            id:null,
            mailModal: false,
            loading: false,
            FILE: null,
            emails: [],
            filter: null,
            sortDesc: false,
            emailDetails:null,
            fields: [
                { key: "number", label: "ID" },
                { key: "email", label: "Email" },
                { key: "open", label: "Open" },
                { key: "clicked", label: "Clicked" },
                { key: "unsubscribed", label: "Unsubscribed" },
                { key: "bounced", label: "Bounced" },
                { key: "total_open", label: "Total Open" },
            ],
            emailData: {
                name: '',
                description: ''
            }
        };
    },
    created(){
        this.id = this.$route.params.id
    },
    mounted() {
        this.getEmails();
    },
    methods: {
        getEmails() {
            this.$store.getters.client.get(`/variant/csv-email/${this.id}/`).then((response) => {
                this.emailDetails = response.data
                this.emails = response.data.email_send;
            });
        },
        handleFileChange(event) {
            this.FILE = event.target.files[0]
        },
        async sendMails() {
            this.loading = true;
            let formData = new FormData()
            formData.append("csv_files", this.FILE, this.FILE.name)
            formData.append("name", this.emailData.name)
            formData.append("description", this.emailData.description)
            try {
                const result = await this.$store.getters.client.post("/variant/csv-email/", formData)
                this.$swal({
                    position: "top-end",
                    icon: "success",
                    title: result.data.msg,
                    showConfirmButton: false,
                    timer: 1500,
                });
            } catch (error) {
                console.log(error)
            } finally {
                this.loading = false;
                this.mailModal = false;
            }
        }
    },
};
</script>
  