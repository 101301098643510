<script>
import createAttributes from "./variant/components/create-variant.vue";
import editAttributes from "./variant/components/edit-variant.vue";
import Artworks from "./components/artworks.vue";
import ProductDetails from "./components/product-details.vue";
import Supplier from "./components/supplier.vue";
import Faq from "./components/faqs";
/**
 * File Uploads component
 */
export default {
  components: {
    ProductDetails,
    Artworks,
    createAttributes,
    editAttributes,
    Supplier,
    Faq,
  },
  data() {
    return {
      // tabIndex: 2,
      tabIndex: Number(localStorage.getItem("activeTab")),
      enteredFeature: "",
      feature: [],
      isEdit: false,
      formTitle: "",
      id: null,
      data: {
        name: "",
        description: "",
      },
    };
  },

  methods: {
    updatePage() {
      this.tabIndex++;
      localStorage.setItem("activeTab", this.tabIndex);
    },

    previousPage() {
      this.tabIndex--;
      localStorage.setItem("activeTab", this.tabIndex);
    },
  },

  created() {
    if ("id" in this.$route.params) {
      this.isEdit = true;
      this.id = this.$route.params.id;
      this.formTitle = "Edit Document";
    } else {
      this.formTitle = "Create Document";
    }
  },

  beforeDestroy() {
    localStorage.removeItem("product_id");
  },
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <div class="card bg-light product_tabs">
        <div class="card-body">
          <h4 class="card-title">Product Details</h4>
          <b-tabs
            v-model="tabIndex"
            pills
            vertical
            nav-class="p-0"
            nav-wrapper-class="col-sm-3 "
            content-class="pt-0 px-2 text-muted"
          >
            <b-tab title="Basic details" title-item-class="disabled_tab">
              <ProductDetails @updatePage="updatePage" />
            </b-tab>

            <b-tab title="Artworks" title-item-class="disabled_tab" >
              <Artworks @updatePage="updatePage" @previousPage="previousPage" v-if="tabIndex == 1" />
            </b-tab>

            <b-tab
              title="Attributes and variants"
              title-item-class="disabled_tab"
            >
              <createAttributes
                v-if="tabIndex == 2 && isEdit === false"
                @updatePage="updatePage"
                @previousPage="previousPage"
              />
              <editAttributes
                v-if="tabIndex == 2 && isEdit === true"
                @updatePage="updatePage"
                @previousPage="previousPage"
              />
            </b-tab>

            <b-tab title="Supplier" title-item-class="disabled_tab" >
              <Supplier
              v-if="tabIndex == 3"
                @updatePage="updatePage"
                @previousPage="previousPage"
              ></Supplier>
              <!-- <b-card-text></b-card-text> -->
            </b-tab>

            <b-tab title="FAQs" title-item-class="disabled_tab">
              <Faq
                @updatePage="updatePage"
                @previousPage="previousPage"
              ></Faq>
              <!-- <b-card-text></b-card-text> -->
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <!-- end col -->
  </div>
</template>

<style>
.disabled_tab {
  pointer-events: none !important;
}
</style>