<template>
  <div class="quote-container" style="font-size: 12px;">
    <h2 class="page-title mb-4" style="color: #0096ae; margin-top: 0; font-weight: 600;">QUOTATION</h2>
    <div style="display: flex; gap: 1.5rem;">
      <div style="width: 50%;">
        <ul style="list-style-type: none; padding-left: 0%; margin-bottom: 1rem;">
          <li>Quotation number: {{ quoteData.number }}</li>
          <li>Quotation date: {{ $moment(quoteData.updated_at).tz('Europe/London').format('DD MMM, YYYY') }}</li>
          <!-- <li>Required By: {{ quoteData.updated_at | moment('DD MMM, YYYY') }}</li> -->
        </ul>
        <BillingAddress :quoteData="quoteData"/>
      </div>
      <div style="width: 50%;">
        <ul style="list-style-type: none; padding-left: 0%; ">
          <li>Digital Press</li>
          <li>
            Unit B, 23 <br> Lancaster Street. Leicester LE5 4GD
          </li>
        </ul>
        <ul style="list-style-type: none; padding-left: 0%; ">
          <li><a href="https://www.digitalpress.co.uk">www.digitalpress.co.uk</a></li>
          <li>sales@digitalpress.co.uk</li>
          <li>0800 756 3711</li>
          <li>Company Number: 15228142</li>
          <li>VAT number: 453 4365 91</li>
        </ul>
      </div>
    </div>

    <div>
      <p style="font-size: 1rem; font-weight: 600;">QUOTATION DETAILS</p>
      <table style="width: 100%;" align="center" role="presentation" cellspacing="0" cellpadding="0" border="0"
        width="100%">
        <thead style="border-top: 1px solid #000; background-color: #e9f0ff; padding: 8px 15px;">
          <tr>
            <th style="text-align: start; padding: 8px 15px; width: 60%;">Item Details</th>
            <th style="text-align: center; width: 125px; padding: 8px 15px;">Quantity</th>
            <th style="white-space: nowrap; text-align: center; width: 125px; padding: 8px 15px;">Unit price</th>
            <th style="text-align: end; width: 125px; padding: 8px 15px;">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr class="product-detail" v-for="(item, index) in quoteData.products_pricing" :key="index">
            <td style="padding: 15px 0; vertical-align: top; width: 60%;">
              <table class="w-100">
                <tr>
                  <td style="width: 150px; padding: 0 15px; vertical-align: top;">
                    <img :src="item.image" class="rounded w-100" alt="">
                  </td>
                  <td>
                    <div style="font-weight: 600;">{{ item.name }}</div>
                    <div class="quoatation-description" v-html="item.description"></div>
                  </td>
                </tr>
              </table>
            </td>
            <td colspan="3" style="padding: 8px 15px; vertical-align: top;">
              <table style="width: 100%;">
                <tbody>
                  <tr v-for="(price, j) in item.quotation_product_price" :key="j">
                    <td style="width: 125px; text-align: center;">{{ price.quantity }}</td>
                    <td style="width: 125px; text-align: center; ">&#163;{{
                      (price.base_price / price.quantity).toFixed(2) }}</td>
                    <td style="width: 125px; text-align: end;">&#163;{{ price.base_price }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr class="product-detail">
            <td style="padding: 8px 15px; vertical-align: top; text-align: end;" colspan="4">
              <table style="width: 100%;">
                <tbody>
                  <tr>
                    <th style="text-align: start; line-height: 2;">
                      Artwork Charges : <span style="font-weight: 400;">{{ quoteData.artwork_service }}</span>
                    </th>
                    <td>&#163;{{ quoteData.artwork_charges || '0.00' }}</td>
                  </tr>
                  <tr>
                    <th style="text-align: start; line-height: 2;">
                      Delivery Charges : <span style="font-weight: 400;">{{ quoteData.delivery_service }}</span>
                    </th>
                    <td>&#163;{{ quoteData.shipping_charges || '0.00' }}</td>
                  </tr>
                  <tr>
                    <th style="text-align: start; line-height: 2;">
                      Additional Cost : <span style="font-weight: 400;">{{ quoteData.additional_service }}</span>
                    </th>
                    <td>&#163;{{ quoteData.additional_charges || '0.00' }}</td>
                  </tr>
                  <tr>
                    <th style="text-align: start; line-height: 2;">
                      Discount : <span style="font-weight: 400;">{{ quoteData.discount_service }}</span>
                    </th>
                    <td>- &#163;{{ quoteData.discount_amount || '0.00' }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr class="product-detail">
            <td colspan="2"></td>
            <td style="padding: 8px 15px; vertical-align: top; text-align: end;" colspan="2">
              <table style="width: 100%;">
                <tbody>
                  <tr>
                    <th style="text-align: end;">
                      Subtotal:
                    </th>
                    <td>&#163;{{ quoteData.subtotal }}</td>
                  </tr>
                  <tr>
                    <th style="text-align: end;">
                      VAT:
                    </th>
                    <td>&#163;{{ quoteData.vat }}</td>
                  </tr>
                  <tr>
                    <th style="text-align: end;">
                      Total:
                    </th>
                    <td>&#163;{{ quoteData.total }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="4"
              style="font-size: 1.5rem; font-weight: 600; text-align: center; color: #0096ae; padding-top: 2rem;">
              This Quotation is only valid for 14 days.</td>
          </tr>
        </tbody>
      </table>
      <div class="mt-3 bg-light p-3 rounded">
            <strong>Payments to be made to the following bank details:</strong>
            <div class="row mt-2">
              <div class="col-6">
                <strong>Account Name : </strong> Tuli Print & Technologies Limited
              </div>
              <div class="col-6">
                <strong>BIC/Swift : </strong> BUKBGB22
              </div>
              <div class="col-6">
                <strong>Account Number : </strong> 60172871
              </div>
              <div class="col-6">
                <strong>Sort Code : </strong> 20-49-17
              </div>
              <div class="col-6">
                <strong>IBAN Number : </strong> GB41 BUKB 2049 1760 1728 71
              </div>
              <div class="col-12 mt-3">
                <strong>
                  To make payment by Debit or Credit Card, please call our Accounts Team on: 0800 756 3711
   ALL REMITTANCES NEED TO BE SENT TO sales@digitalpress.co.uk <br> *If you have any issues or discrepancies, please notify us within 48 hours of receiving this documentation* <br>
  DIGITAL PRESS IS A TRADING DIVISION OF THE TULI PRINT & TECHNOLOGIES LIMITED (15228142)
                </strong>

              </div>
            </div>
          </div>
    </div>
  </div>
</template>
<script>
import BillingAddress from './BillingAddress.vue';

export default {
    props: {
        quoteData: {
            type: Object,
            default: () => {
                return {};
            },
        }
    },
    components: { BillingAddress }
};
</script> 
    
<style>
.quote-container {
  /* margin: auto;  */
  padding: 2rem;
  box-shadow: 0 0 20px #DDDDDD;
}

.product-detail:nth-child(even) {
  background-color: #e9f0ff;
}

.vue-html2pdf {
  display: flex;
  justify-content: center;
}

.vue-html2pdf>>>.layout-container.show-layout {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1) !important;
}

.action-container {
  position: sticky;
  top: 60px;
}
.quoatation-description ul{
  padding-left: 10px;
}
</style>
    