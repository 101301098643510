import Orders from "./index";

import OrderDetails from "./_id";
export default [
    {
      path: "/orders",
      component: Orders,
      name: "Orders",
    },
    {
      path: "/order/:id/",
      component: OrderDetails,
      name: "OrderDetails",
    },
  ];