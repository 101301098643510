<script>
export default {
  data() {
    return {
      isEdit: false,
      title: "",
      id: null,
      data: {
        customer_name: "",
        company_name: "",
        description: "",
      },
    };
  },
  mounted() {
    if (this.isEdit == true) {
      this.getReviewById();
    }
  },
  methods: {
    getReviewById() {
      this.$store.getters.client.get(`/reviews/${this.id}/`).then((response) => {
        this.data = response.data;
      });
    },
    addReview() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store.getters.client
            .post("/reviews/", this.data)
            .then(() => {
              this.$swal({
                position: "top-end",
                icon: "success",
                title: "Reviews created successfully",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.push("/reviews");
            });
        }
      });
    },

    updateDetails() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$store.getters.client
            .put("/reviews/" + this.$route.params.id + "/", this.data)
            .then(() => {
              this.$swal({
                position: "top-end",
                icon: "success",
                title: "Reviews updated successfully",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.push("/reviews");
            });
        }
      });
    },
  },
  created() {
    if ("id" in this.$route.params) {
      this.isEdit = true;
      this.id = this.$route.params.id;
      this.title = "Edit Review";
    } else {
      this.title = "Add Review";
    }
  },
};
</script>
<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <form class="form-horizontal" role="form">
                <b-form-group
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label="Name"
                >
                  <b-form-input type="text" required v-model="data.customer_name"></b-form-input>
                </b-form-group>
                <b-form-group
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label="Company"
                >
                  <b-form-input type="text" required v-model="data.company_name"></b-form-input>
                </b-form-group>
                <b-form-group
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label="Description"
                >
                  <b-form-textarea
                    for="text"
                    rows="6"
                    v-model="data.description"
                  ></b-form-textarea>
                </b-form-group>
                
                <div class="text-right mt-3">
                  <button
                    v-if="!isEdit"
                    @click="addReview"
                    type="button"
                    class="btn btn-primary"
                  >
                    Submit
                  </button>
                  <button
                    v-if="isEdit"
                    @click="updateDetails"
                    type="button"
                    class="btn btn-primary"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
</template>
