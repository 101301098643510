<script>
import Swal from "sweetalert2";
/**
 * Dashboard component
 */
export default {
  data() {
    return {
      studioData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "first_name", sortable: true, label: "Full Name" },
        // { key: "last_name", sortable: true, label: "Last Name" },
        { key: "email", sortable: true, label: "Email" },
        { key: "phone", sortable: true, label: "Phone" },
        // { key: "verified_email", sortable: true, label: "Verification" },
        // { key: "gender", sortable: true, label: "Gender" },
        // { key: "date_of_birth", sortable: true, label: "DOB" },
        { key: "action" },
      ],
    };
  },
  mounted() {
    this.getstudioData();
  },
  methods: {
    async confirm(studio) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          let loader = this.$loading.show({
            loader: "dots",
          });
          this.$store.getters.client
            .delete(`/studio/studio/${studio.item.id}`)
            .then(() => {
              if (result.isConfirmed) {
                Swal.fire("Deleted!", "Your file has been deleted.", "success");
              }
              loader.hide();
              this.getstudioData();
            })
            .catch((err) => {
              console.log(err)
              loader.hide();
            });
        }
      });
    },
    editstudio(studio) {
      this.$router.push({ path: `edit-studio/${studio.item.id}` });
    },
    goToCreatestudio() {
      this.$router.push({ name: "createStudio" });
    },
    async getstudioData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        const resp = await this.$store.getters.client.get("/studio/studio/")
        this.studioData = resp.data.results
      } catch (error) {
        alert(error)
      } finally {
        loader.hide()
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="add-button">
      <a href="javascript:void(0);" class="btn btn-primary" @click="goToCreatestudio">
        + Add new studio
      </a>
    </div>
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
          </b-dropdown>

          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2"></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table :items="studioData" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage">
              <template v-slot:cell(action)="row">
                <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover data-toggle="tooltip"
                  title="Edit" @click="editstudio(row)">
                  <i class="mdi mdi-pencil font-size-18"></i>
                </a>
                <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">
                  <i @click="confirm(row)" class="mdi mdi-trash-can font-size-18"></i>
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" :total-rows="studioData.length" :per-page="perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
