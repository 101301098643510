import Purchase from "./index";
import PurchaseDetails from "./_id";

// import middlewares
export default [
  {
    path: "/purchase",
    component: Purchase,
    name: "Purchase",
  },
  {
    path: "/purchase/:id/",
    component: PurchaseDetails,
    name: "Purchase Details",
  },
];

