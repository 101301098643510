<script>
import Swal from "sweetalert2";
/**
 * Dashboard component
 */
export default {
  data() {
    return {
      supplierData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "company_name", sortable: true, label: "Company Name" },

        { key: "first_name", sortable: true, label: "First Name" },
        { key: "last_name", sortable: true, label: "Last Name" },
        { key: "email", sortable: true, label: "Email" },
        { key: "phone", sortable: true, label: "Phone" },

        { key: "verified_email", sortable: true, label: "Verification" },
        { key: "gender", sortable: true, label: "Gender" },
        { key: "date_of_birth", sortable: true, label: "DOB" },
        { key: "action" },
      ],
    };
  },
  mounted() {
    this.getSupplierData(1);
  },
  methods: {
    async confirm(supplier) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          let loader = this.$loading.show({
            loader: "dots",
          });
          this.$store.getters.client
            .delete(`/supplier/supplier/${supplier.item.id}`)
            .then(() => {
              if (result.isConfirmed) {
                Swal.fire("Deleted!", "Your file has been deleted.", "success");
              }
              loader.hide();
              this.getSupplierData();
            }).catch(error => {
              loader.hide()
              return error
            });
        }
      });
    },
    editSupplier(supplier) {
      this.$router.push({ path: `update-supplier/${supplier.item.id}` });
    },
    goToCreateSupplier() {
      this.$router.push({ name: "CreateSupplier" });
    },
    getSupplierData(value) {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client.get(`/supplier/supplier/?page=${value}`).then((response) => {
        let supplierData = response.data.results;
        this.supplierData = this.supplierData.concat(supplierData)
        this.currentPage = response.data.current_page;
        this.totalRows = response.data.total_count
        loader.hide();
      }).catch(error => {
        loader.hide()
        return error
      });
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <div>
    <div class="add-button">
      <a href="javascript:void(0);" class="btn btn-primary" @click="goToCreateSupplier">
        + Add new supplier
      </a>
    </div>
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">

          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select v-model="perPage" disabled size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2"></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table :items="supplierData" :fields="fields" responsive="sm" :per-page="perPage"
              :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
              :filter-included-fields="filterOn" @filtered="onFiltered">
              <template v-slot:cell(action)="row">
                <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover data-toggle="tooltip"
                  title="Edit" @click="editSupplier(row)">
                  <i class="mdi mdi-pencil font-size-18"></i>
                </a>
                <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">
                  <i @click="confirm(row)" class="mdi mdi-trash-can font-size-18"></i>
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                    @change="getSupplierData"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
