import Blogs from "./index";
import CreateBlogs from "./create-blog.vue";

// import middlewares
export default [
  {
    path: "/blogs",
    component: Blogs,
    name: "Blogs",
  },
  {
    path: "/create-blog",
    component: CreateBlogs,
    name: "CreateBlogs",
  },
  {
    path: "/edit-blog/:id",
    component: CreateBlogs,
    name: "CreateBlogs",
  },
];
