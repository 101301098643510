import Vue from "vue";
import Router from "vue-router";

// routes
import Login from "@/views/pages/account/routes.account/";
import Orders from "@/views/pages/orders/routes.orders/";
import Supplier from "@/views/pages/supplier/routes.supplier/";
import Studio from "@/views/pages/studio/routes.studio/";
import Sales from "@/views/pages/sales/routes.sales/";
import ReSeller from "@/views/pages/re-seller/routes.reseller/";
import Blogs from "@/views/pages/blogs/routes.blogs/";
import Customers from "@/views/pages/customers/routes.customers/";
import Analytics from "@/views/pages/analytics/routes.analytics/";
import Dashboard from "@/views/pages/dashboard/routes.dashboard/";
import Categories from "@/views/pages/categories/routes.categories/";
import Products from "@/views/pages/products/routes.products/";
import Attributes from "@/views/pages/attributes/routes.attributes/";
import Transactions from "@/views/pages/transactions/routes.transactions/";
import Notifications from "@/views/pages/notification/routes.notification/";
import Quotation from "@/views/pages/quotation/routes.quotation/";
import CustomProducts from "@/views/pages/custom-products/routes.customproducts.js";
import Purchase from "@/views/pages/purchase/routes.purchase/";
import Discount from "@/views/pages/discount/routes.discount/";
import Settings from "@/views/pages/settings/routes.settings/";
import routesTeam from "../views/pages/team/routes.team";
import routesPaperSize from "../views/pages/paper-size/routes.paperSize";
import routesReviews from "../views/pages/reviews/routes.reviews";
import calendar from "../views/pages/calendar/routers.calendar.js";

Vue.use(Router);

const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,

  scrollBehavior() {
    return { x: 0, y: 0 };
  },

  routes: [
    ...Login,
    {
      path: "/dashboard",
      component: () => import("@/views/layouts/main/"),
      children: [
        ...Dashboard,
        ...Categories,
        ...Attributes,
        ...Products,
        ...CustomProducts,
        ...Orders,
        ...Supplier,
        ...Transactions,
        ...Customers,
        ...Analytics,
        ...Quotation,
        ...Purchase,
        ...Discount,
        ...Settings,
        ...Studio,
        ...Sales,
        ...ReSeller,
        ...Blogs,
        ...routesTeam,
        ...routesReviews,
        ...Notifications,
        ...routesPaperSize,
        ...calendar,
      
      ],

    },
    {
      path: "/not-found",
      component: () => import(/* webpackChunkName: "404" */ "@/views/Error404"),
    },
    { path: "*", redirect: "/not-found" },
  ],
});

router.afterEach(() => {
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
