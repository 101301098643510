import Supplier from "./index";
import CreateSupplier from "./add-supplier";
import UpdateSupplier from "./update-supplier";

// import middlewares
export default [
  {
    path: "/supplier",
    component: Supplier,
    name: "Supplier",
  },
  {
    path: "/add-supplier",
    component: CreateSupplier,
    name: "CreateSupplier",
  },
  {
    path: "/update-supplier/:id",
    component: UpdateSupplier,
    name: "Edit Supplier",
  },
];
