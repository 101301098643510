import Products from "./index";
import CreateProducts from "./create-products";
import ManageProduct from "./manage-product.vue";
import SortProductsVarient from "./sorting-attribute.vue";
import ManageSupplier from "./manage-supplier.vue";
import UpdatePrice from "./update-price.vue";
import ProductPriceManuall from './variant/components/create-variant.vue';
import PromotionalProducts from "./promotional-list.vue";
import PromotionalProductsDetals from "./product-details.vue";

export default [
  {
    path: "/products",
    component: Products,
    name: "Products",
  },
  {
    path: "/create-products",
    component: CreateProducts,
    name: "CreateProducts",
  },
  {
    path: "/edit-product/:id",
    component: CreateProducts,
    name: "CreateProducts", 
  },
  {
    path: "/product/:id/update-price/",
    component: UpdatePrice,
    name: "Price Update", 
  },
  {
    path: "/manage-product",
    component: ManageProduct,
    name: "ManageProduct", 
  },
  {
    path: "/sorting/:id",
    component: SortProductsVarient,
    name: "SortProductsVarient", 
  },
  {
    path: "/product/:id/variant-price",
    component: ProductPriceManuall,
    name: "ProductPriceManuall", 
  },
  {
    path: "/product/:id/manage-supplier",
    component: ManageSupplier,
    name: "ManageSupplier", 
  },
  {
    path: "/promotional-products",
    component: PromotionalProducts,
    name: "Promotional Products", 
  },
  {
    path: "/promotional-products/:id",
    component: PromotionalProductsDetals,
    name: "Product Details", 
  },
];
