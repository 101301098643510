<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      studioData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "first_name", sortable: true, label: "Name" },
        { key: "company_name", sortable: true, label: "Company" },
        { key: "email", sortable: true, label: "Email" },
        { key: "phone", sortable: true, label: "Phone" },
        { key: "is_active", sortable: true, label: "Varified" },
        { key: "discount", sortable: true, label: "Discount(%)" },
        // { key: "date_of_birth", sortable: true, label: "DOB" },
        { key: "action" },
      ],
    };
  },
  computed: {
    rows() {
      return this.studioData.length;
    },
  },
  mounted() {
    this.getResellerData();
    this.totalRows = this.studioData.length;
  },
  methods: {
    async confirm(studio) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          let loader = this.$loading.show({
            loader: "dots",
          });
          this.$store.getters.client
            .delete(`/studio/studio/${studio.item.id}`)
            .then(() => {
              if (result.isConfirmed) {
                Swal.fire("Deleted!", "Your file has been deleted.", "success");
              }
              loader.hide();
              this.getResellerData();
            });
        }
      });
    },
    editstudio(studio) {
      this.$router.push({ path: `edit-studio/${studio.item.id}` });
    },
    goToCreatestudio() {
      this.$router.push({ name: "createStudio" });
    },
    getResellerData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client.get("/user/print-reseller/").then((response) => {
        this.studioData = response.data.results;
        loader.hide();
      });
    },
    changeStatus(item) {
      this.$store.getters.client.patch(
        `/user/print-reseller/${item.id}/`,
        { is_active: item.is_active }
      );
    },
    ApplyDiscount(item){
      this.$store.getters.client.patch(
        `/user/print-reseller/${item.id}/`,
        { discount: item.discount }
      );
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <div>
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
          </b-dropdown>

          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2"></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table :items="studioData" :fields="fields" responsive="sm" :per-page="perPage" :current-page="currentPage"
              :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
              @filtered="onFiltered">
              <template v-slot:cell(is_active)="row">
                <div class="d-flex align-items-center">
                  <b-form-checkbox v-model="row.item.is_active" switch @change="changeStatus(row.item)" class="mb-1">
                    <label><span v-if="row.item.is_active">Active</span>
                      <span v-else>InActive</span></label>
                  </b-form-checkbox>
                </div>
              </template>
              <template v-slot:cell(discount)="row">
                <b-form-group>
                  <b-form-input style="width: 100px;" size="sm" type="text" v-model="row.item.discount" @blur="ApplyDiscount(row.item)"></b-form-input>
                </b-form-group>
              </template>
              <template v-slot:cell(action)="row">
                <div><router-link class="d-block" :to="`/re-sellers/${row.item.id}/manage-discount`">Discounts</router-link> </div>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
