<template>
  <b-row>
    <b-col cols="12" class="mb-3">
        <b-button variant="success" class="float-right" @click="addMember()">Add Member</b-button>
    </b-col>
    <b-col cols="6" md="3" v-for="member in teamList" :key="member.id">
        <b-card :title="member.name">
            <img :src="member.profile ? `https://api.digitalpress.co.uk/media/${member.profile}` : ''" alt="Profile-pic" class="w-100">
            <p>{{member.description}}</p>
        </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
    data(){
        return{
            teamList:[]
        }
    },
    mounted(){
        this.getTeamData()
    },
    methods:{
        async getTeamData(){
            await this.$store.getters.client.get('/team').then((res)=>{
                this.teamList = res.data.data
            })
        },
        addMember(){
            this.$router.push('/add-member')
        }
    }
}
</script>

<style>

</style>