<script>
import Swal from "sweetalert2";
export default {
  data() {
    return {
      attributeData: [],
      trashData: [],
      totalRows: 1,
      current_page: 1,
      trash_current_page: 1,
      trash_per_page: 15,
      per_page: 15,
      pageOptions: [5, 10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      attDataTable: [
        { key: "attachment", label: "Image" },
        { key: "title", sortable: true, label: "Title", thStyle: { width: "50%" } },
        { key: "display_order", label: "Order" },
        { key: "is_active", label: "status" },
        { key: "show_on_front", label: "Featured" },
        { key: "action" },
      ],
      trashDataTable: [
        { key: "name", sortable: true, label: "Name" },
        { key: "description", label: "Description" },
        { key: "action" },
      ],
    };
  },
  computed: {
    rows() {
      return this.attributeData.length;
    },
  },
  mounted() {
    this.getBlog(1);
    this.getTtashData();
    // this.totalRows = this.attributeData.length;
  },
  methods: {
    editAttributes(attributes) {
      this.$router.push({ path: `edit-blog/${attributes.item.slug}` });
    },
    confirm(attributes) {
      Swal.fire({
        title: "Move to trash",
        text: "You can delete it from trash",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        // eslint-disable-next-line no-unused-vars
      }).then((result) => {
        this.$store.getters.client.patch(`/blog/${attributes.item.slug}/`, { 'is_trash': true })
        this.attributeData = this.attributeData.filter((x) => {
          return x.id != attributes.item.slug
        })
      });
    },
    restore(attributes) {
      Swal.fire({
        title: "Restore Attribute?",
        text: "It will save to all attributes",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Restore",
        // eslint-disable-next-line no-unused-vars
      }).then((result) => {
        this.$store.getters.client.patch(`/blog/${attributes}/`, { 'is_trash': false })
        this.trashData = this.trashData.filter((x) => {
          return x.id != attributes
        })
      });
    },
    deleteBlog(attributes) {
      Swal.fire({
        title: "Delete permanently",
        text: "You won't be able to restore again",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
        // eslint-disable-next-line no-unused-vars
      }).then((result) => {
        this.$store.getters.client.delete(`/blog/${attributes.item.slug}/`)
        this.getBlog(1)
      });
    },
    goToCreateAttributes() {
      this.$router.push({ name: "CreateBlogs" });
    },
    getBlog(value) {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client.get(`/blog/?page=${value}`).then((response) => {
        this.attributeData = response.data.results;
        this.totalRows = response.data.total_count;
        this.per_page = response.data.per_page;
        loader.hide();
      });
    },
    displayAtHome(blog) {
      this.$store.getters.client.patch(`/blog/${blog.slug}/`, { show_on_front: blog.show_on_front })
    },
    changeStatus(blog) {
      this.$store.getters.client.patch(`/blog/${blog.slug}/`, { is_active: blog.is_active })
    },
    manageOrder(blog) {
      this.$store.getters.client.patch(`/blog/${blog.slug}/`, { display_order: blog.display_order })
    },
    getTtashData() {
      this.$store.getters.client.get("/blog/?is_trash=true").then((response) => {
        this.trashData = response.data.results;
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<template>
  <div>
    <div class="add-button">
      <a href="javascript:void(0);" class="btn btn-primary" @click="goToCreateAttributes">
        + Add New Blog
      </a>
    </div>
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body pt-0">
          <b-tabs nav-class="nav-tabs-custom">
            <b-tab title-link-class="p-3">
              <template v-slot:title>
                <a class="font-weight-bold active">All</a>
              </template>
              <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
              </b-dropdown>

              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select size="sm" disabled :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input v-model="filter" type="search"
                        class="form-control form-control-sm ml-2"></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table :items="attributeData" :fields="attDataTable" responsive="sm">
                  <template v-slot:cell(attachment)="row">
                    <img :src="row.item.attachment" width="100" alt="">
                  </template>
                  <template v-slot:cell(is_active)="row">
                    <div class="d-flex align-items-center">
                      <b-form-checkbox v-model="row.item.is_active" switch @change="changeStatus(row.item)" class="mb-1">
                        <label><span v-if="row.item.is_active">Active</span>
                          <span v-else>Draft</span></label>
                      </b-form-checkbox>
                    </div>
                  </template>
                  <template v-slot:cell(display_order)="row">
                    <div class="d-flex align-items-center">
                      <b-form-input v-model="row.item.display_order" @blur="manageOrder(row.item)">
                      </b-form-input>
                    </div>
                  </template>
                  <template v-slot:cell(show_on_front)="row">
                    <div class="d-flex align-items-center">
                      <b-form-checkbox v-model="row.item.show_on_front" switch @change="displayAtHome(row.item)"
                        class="mb-1">
                        <label><span>Show on Home</span></label>
                      </b-form-checkbox>
                    </div>
                  </template>
                  <template v-slot:cell(action)="row">
                    <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover data-toggle="tooltip"
                      title="Edit" @click="editAttributes(row)">
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">
                      <i @click="confirm(row)" class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="current_page" :total-rows="totalRows" :per-page="per_page"
                        @change="getBlog"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>

            </b-tab>
            <b-tab title-link-class="p-3">
              <template v-slot:title>
                <a class="font-weight-bold active">Trash</a>
              </template>
              <div class="table-responsive">
                <b-table :items="trashData" :fields="trashDataTable" responsive="sm" :per-page="trash_per_page"
                  :current-page="trash_current_page" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                  :filter-included-fields="filterOn" @filtered="onFiltered">

                  <template v-slot:cell(description)="row">
                    <p v-html="row.item.description"></p>
                    <!-- {{row.item.description}} -->
                  </template>
                  <template v-slot:cell(action)="row">
                    <a href="javascript:void(0);" class="mr-3 text-primary" v-b-tooltip.hover data-toggle="tooltip"
                      title="Restore">
                      <i @click="restore(row.item.slug)" class="mdi mdi-refresh font-size-18"></i>
                    </a>
                    <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Delete">
                      <i @click="deleteBlog(row)" class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="trash_current_page" :total-rows="trashData.length"
                        :per-page="trash_per_page"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
